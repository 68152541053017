import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { MessageService } from 'src/app/core/services/messages/message.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})

export class MessagesComponent implements OnInit {

  messages: Array<Message> | any;
  pageNo: number| any = 0;
  pageLimit: number | any = 10;
  constructor(public messageService: MessageService,
    public merchantService: MerchantService,
    private titleService: Title,
    private toastr: ToastrService,
    private router: Router,
    private cookieService: CookieService
    ) { }

  ngOnInit(): void {
    let username = this.cookieService.get("username");
    if(username == "")
    {
      this.router.navigateByUrl("/");
    }
    if(!this.merchantService.getMerchantData())
    {
      this.merchantService.getMechangeDetails();
    }
    
    this.titleService.setTitle("View Enquiries | CMS ");
    this.messageService.getMessages(this.pageNo, this.pageLimit);
    this.messageService.getMessagesCount();
  }

  getNext(event: any): void{
    if(((this.pageNo+1)*(this.pageLimit)) < this.messageService.totalCount)
    {
      this.pageNo = this.pageNo + 1;
      if(this.messageService.totalCount < (this.pageNo * this.pageLimit))
      {
          if(this.messageService.totalCount > ((this.pageNo-1) * this.pageLimit)){
            this.toastr.info("No more messages");
  
          }
          else{
            this.messageService.getMessages(this.pageNo, this.pageLimit);
          }
      }
      else{
        this.messageService.getMessages(this.pageNo, this.pageLimit);
      }
    }
    
  }

  getPrevious(event:  any): void{
    if(this.pageNo != 0)
    {
      this.pageNo = this.pageNo - 1;
      if(this.messageService.totalCount < (this.pageNo * this.pageLimit))
      {
          if(this.messageService.totalCount > ((this.pageNo-1) * this.pageLimit)){
            this.toastr.info("No more messages");
          }
          else{
            this.messageService.getMessages(this.pageNo, this.pageLimit);
          }
      }
      else{
        this.messageService.getMessages(this.pageNo, this.pageLimit);
      } 
    }
    
  }



 

}
