import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { testimonial, updateTestimonyRequest } from 'src/app/core/models/customer/testimonials.models';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { TestimonyService } from 'src/app/core/services/testimonials/testimony.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-testimony',
  templateUrl: './update-testimony.component.html',
  styleUrls: ['./update-testimony.component.css']
})
export class UpdateTestimonyComponent implements OnInit {

  public uploadTestimonyImage = environment.apiUrl + "/api/Testimonys/image";
  public testimonyImage = "";

  testimonial : testimonial | any;
  testimonials: Array<testimonial> = [];

  loaderEnabled = true;
  public title = "";
  public description = "";
  public name = "";
  public rating = 5;
  constructor(private cookieService: CookieService,
    private merchantService: MerchantService,
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private testimonialService: TestimonyService,
    private titleService: Title) { }

  async ngOnInit(): Promise<void> {

    const windowObj = window as any;
    windowObj.$('.dropify').dropify({});
    let username = this.cookieService.get("username");
    if (username == "") {
      this.router.navigateByUrl("/");
    }
    if (!this.merchantService.getMerchantData()) {
      this.merchantService.getMechangeDetails();
    }

    const routeParams = this.route.snapshot.paramMap;
    let id = String(routeParams.get('id'));

    this.titleService.setTitle("Update Customer Testimonial | Content Management Portal");
    this.testimonials = await this.testimonialService.getAllTestimonials();
    if(this.testimonials != null)
    {
      let testItem = this.testimonials.find(s=> s._id == id);
      this.testimonial = testItem;
      this.loaderEnabled = false;
    }
  }

  async updateTestimony(){
   this.loaderEnabled = true;
   if(this.name == undefined || this.name == null || this.name == "")
   {
     this.name = this.testimonial?.name;
   }

   if(this.description == undefined || this.description == null || this.description == "")
   {
     this.description = this.testimonial?.description;
   }


   if(this.name == undefined || this.name == null || this.name == "")
   {
     this.loaderEnabled = false;
     this.toastr.error("Name cannot be empty");
   }
   else if(this.description == undefined || this.description == null || this.description == "")
   {
    this.loaderEnabled = false;
    this.toastr.error("Description cannot be empty");
   }
   else{

    
     let test: updateTestimonyRequest = {
      name: this.name,
      description: this.description,
      imageUrl: this.testimonyImage,
      username: this.cookieService.get("username")
     };

     await this.testimonialService.updateTestimonial(test, this.testimonial?._id).then((data) => {
      this.toastr.success("Testimonial updated successfully");
      this.loaderEnabled = false;
  })
  .catch((error) => {
    console.log(error);
    this.loaderEnabled = false;
  });

    window.location.reload();
   }

  }

  testimonyImageChange(data: any): void {
    let file = data.target.files[0];
    let reader = new FileReader();
    const me = this;
    this.loaderEnabled = true;
    reader.readAsDataURL(file);
    reader.onload = async function () {
      const imagedata = reader.result?.toString().split(',')[1];
      try {
        let imageUrl = await me.uploadTestimonyImages(imagedata);
        me.toastr.success("Image uploaded successfully");
        me.testimonyImage = imageUrl;
        me.loaderEnabled = false;
      } catch (error) {
        me.toastr.error("something went wrong");
      }
    };
    reader.onerror = function (error) {
      me.toastr.error("something went wrong " + error);
    };
  }


  uploadTestimonyImages(data: any): Promise<any> {
    const headers = new HttpHeaders().set("Content-Length", data.length.toString())

    return this.http.put(this.uploadTestimonyImage + "/" + this.testimonial?._id,
      { "image": data },
      { headers, responseType: 'text' }).toPromise();

  }

  async archiveTestimony(){

    await this.testimonialService.archiveTestimony(this.testimonial?._id).then((data : any) => 
    {
      this.toastr.success("Testimonial archived successfully");
      this.loaderEnabled = false;
    })
  .catch((error: any) => {
    console.log(error);
    this.loaderEnabled = false;
  });

  }

}
