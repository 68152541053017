import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { JwtService } from '../jwt/jwt.service';
import { concatMap, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { MerchantService } from '../merchant/merchant.service';

@Injectable({
  providedIn: 'root'
})
export class AuthguardService {

  constructor(private jwtHelper: JwtService, 
    private cookieService: CookieService,
    private merchantService: MerchantService,
    private router: Router) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!this.jwtHelper.isJwtValid()) {
      
       return this.jwtHelper.authenticateObserver()
        .pipe(
          tap((data: any) => {
            this.jwtHelper.setToken(data);
            this.routeHelper(route);
          })
        )
      
    }
    else {
      return of(true);
    }
  }


  merchantServiceHelper(data: any, route: ActivatedRouteSnapshot): Observable<any> {
    this.merchantService.setMerchantData(data);
    const urlPath: string[] = [];
    let redirectPath = '/';
    if (route.url.length > 0) {
      route.url.forEach((e: any) => urlPath.push(e));
      redirectPath = urlPath.join('/');
    }
    else {
      this.router.navigateByUrl('/');
    }
    this.router.navigateByUrl(redirectPath);
    return of(true);
  }

  routeHelper(route: ActivatedRouteSnapshot): Observable<any> {
    
    const urlPath: string[] = [];
    let redirectPath = '/';
    if (route.url.length > 0) {
      route.url.forEach((e: any) => urlPath.push(e));
      redirectPath = urlPath.join('/');
    }
    else {
      this.router.navigateByUrl('/');
    }
    this.router.navigateByUrl(redirectPath);
    return of(true);
  }
}
