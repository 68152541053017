import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { merchantProperties } from 'src/app/core/models/merchant/merchant.models';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';

@Component({
  selector: 'app-theme-color',
  templateUrl: './theme-color.component.html',
  styleUrls: ['./theme-color.component.css']
})
export class ThemeColorComponent implements OnInit {

  public primaryColor: string | any;
  public secondaryColor: string | any;
  merchantProperties: merchantProperties | any;
  buttonEnabled : boolean = true;
  loaderEnabled = false;

  constructor(private cookieService: CookieService,
    private titleService: Title,
    private toastr: ToastrService,
    private merchantService: MerchantService,
    private router: Router) { 

     
    }

  async ngOnInit(): Promise<void> {
    let username = this.cookieService.get("username");
    if(username == "")
    {
      this.router.navigateByUrl("/");
    }
    if(!this.merchantService.getMerchantData())
    {
      this.merchantService.getMechangeDetails();
    }
    this.titleService.setTitle("Customize theme | Content Management Portal ");
    await this.merchantService.getMerchantProperties().then((data) => {
        this.merchantProperties = data;
        console.log(data);
    })
    .catch((error) => {
      if(error.status == 200)
      {
      }
    });
  }

 
  async changeColor(){
    this.loaderEnabled = true;

    if(this.primaryColor == undefined)
    {
      this.primaryColor = this.merchantProperties?.primaryColor;
    }
    if(this.secondaryColor == undefined)
    {
      this.secondaryColor = this.merchantProperties?.secondaryColor;
    }

    if(this.primaryColor == this.merchantProperties?.primaryColor && this.secondaryColor == this.merchantProperties?.secondaryColor)
    {
        this.toastr.error("No changes detected");
        this.loaderEnabled = false;
    }
    else{
     
      let props = this.merchantProperties;
      props.primaryColor = this.primaryColor;
      props.secondaryColor = this.secondaryColor;

      await this.merchantService.updateMerchantProperties(props).then((data) => {
        this.toastr.success("Changes are saved successfully");
        this.loaderEnabled = false;
    })
    .catch((error) => {
      this.loaderEnabled = false;
    });

     
    }
  }
  

}
