<div class="page">
  <!-- main-sidebar -->
  <div class="app-sidebar__overlay z0" data-toggle="sidebar"></div>
  <app-sidebar></app-sidebar>
  <!-- main-sidebar -->
  <!-- main-content -->
  <div class="main-content app-content">
    <!-- main-header -->
    <app-header></app-header>
    <!-- /main-header -->
    <!-- container -->
    <div class="container-fluid topabsolute">
      <!-- breadcrumb -->
      <div class="breadcrumb-header justify-content-between">
        <div class="my-auto">
          <div class="d-flex">
            <h4 class="content-title mb-0 my-auto">Home</h4>
            <span class="text-muted mt-1 tx-13 ml-2 mb-0">/ Blog</span>
          </div>
        </div>
      </div>
      <!-- breadcrumb -->
      <div class="row row-sm">

        <div class="col-xl-10 col-lg-9 col-md-12">

          <div class="row row-sm">
            <div class="col-md-6 col-lg-6 col-xl-4  col-sm-6" *ngFor="let prodItem of blogService?.Posts">
              <div class="card custom-card">
                <img class="card-img-top w-100 bg-primary-transparent" *ngIf="!prodItem.imageModel?.small"
                  src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5fff3d958d5dbc10304de1bb.png"
                  alt="{{prodItem.title}}">
                <img class="card-img-top w-100 bg-primary-transparent" *ngIf="prodItem.imageModel?.small"
                  src="{{prodItem.imageModel?.small}}" alt="{{prodItem.title}}">
                <div class="card-body">
                  <h4 class="card-title">{{prodItem?.title}}</h4>
                  <p class="card-text text-primary">Category: {{prodItem?.category}}</p>
                  <p class="card-text" innerHtml="{{prodItem?.description | slice:0:60}}..."></p>
                  <a routerLink="/blog/edit/{{prodItem._id}}" title="Update {{prodItem.title}}"><span
                      class="font-weight-bold text-primary">EDIT</span><i class="fe fe-arrow-right ml-1"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3"> </div>

            <div class="col-md-3">

              <ul class="pagination product-pagination ml-auto float-right">
                <li class="page-item page-prev" style="float: left;">
                  <a class="page-link" title="Previous" routerLink="{{blogService?.prevLink}}">Prev</a>
                </li>

                <li class="page-item page-next" style="float: right;">
                  <a class="page-link" title="Next" routerLink="{{blogService?.nextLink}}">Next</a>
                </li>
              </ul>

            </div>
            <div class="col-md-3"> </div>
            <div class="col-md-3"> </div>

          </div>
        </div>
      </div>
      <!-- row -->
      <!-- row closed -->
    </div>
    <!-- Container closed -->
  </div>
  <!-- main-content closed -->
  <!-- Footer opened -->

  <!-- Footer closed -->
</div>