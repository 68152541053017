import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { category } from 'src/app/core/models/category/category.models';
import { serviceRequest } from 'src/app/core/models/service/service.models';
import { CategoryService } from 'src/app/core/services/categories/category.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { ProductService } from 'src/app/core/services/products/product.service';
import { ServiceService } from 'src/app/core/services/services/service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent implements OnInit {

  
  public loaderEnabled = false;
  public submit = false;
  public apiPath = environment.apiUrl + "/api/Images/custom/";

  public uploadProductImage = environment.apiUrl + "/api/Products/image";
  public productImageUrl = "";

  public allCategories: category[] = [];
  public categories: category[] = [];
  public description = "";
  public keywords = "";
  public category = "";
  public name = "";
  public price: number | any = 0;
  public discount: number | any = 0;
  public quantity: number | any = 0;
  public bookOnline = "";
  public warranty = "";
  public shortBio = "";
  public brand = "";


  public categoryString : string | any;
  public categoryId : string | any;


  public cofigObj = {
    "uploader": {
      "insertImageAsBase64URI": true
    },
    "minHeight": 400,
    "minWidth": 400
  }

  constructor(private titleService: Title,
    private router: Router,
    private merchantService: MerchantService,
    private productService: ProductService,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private categoryService: CategoryService,
    private http: HttpClient) { }

  async ngOnInit(): Promise<void> {
    const windowObj = window as any;
    windowObj.$('.dropify').dropify({});

    let username = this.cookieService.get("username");
    if (username == "") {
      this.router.navigateByUrl("/");
    }
    if (!this.merchantService.getMerchantData()) {
      this.merchantService.getMechangeDetails();
    }

    this.categories = await this.categoryService.getCategoriesObservable();
    this.allCategories = await this.categoryService.getBusinessCategories();
    this.titleService.setTitle("Add New Products | Content Management Portal");
  }


  updateDescription(wysiwygdata: string) {
    this.description = wysiwygdata;
    if (this.description == null || this.description == undefined || this.description == "") {
      this.toastr.error("Description cannot be empty");
      this.loaderEnabled = false;
    }
    else if (this.name == null || this.name == undefined || this.name == "") {
      this.toastr.error("Name cannot be empty");
      this.loaderEnabled = false;
    }
    else if (this.category == null || this.category == undefined || this.category == "") {
      this.toastr.error("Category cannot be empty");
      this.loaderEnabled = false;
    }
    else {

      let keywordArray: string[] = [];

      if (this.keywords != null) {
       
        keywordArray = this.keywords.split(",");
      }

     

      if(this.allCategories != null && this.category != "")
      {
        let catItem =  this.allCategories.find(s=> s._id == this.category);
        this.categoryString= catItem?.name;
        this.categoryId = catItem?._id;
      }

      let postRequest: serviceRequest = {
        name: this.name,
        description: this.description,
        category: this.categoryString,
        keywords: keywordArray,
        imageUrl: this.productImageUrl,
        username: this.cookieService.get('username'),
        categoryId: this.categoryId,
        price: Number(this.price),
        discount: Number(this.discount),
        stock: Number(this.quantity),
        buyOnlineLink: this.bookOnline,
        shortBio: this.shortBio,
        warranty: this.warranty,
        brand: this.brand
      }

      this.productService.addProductInfo(postRequest).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((d: any) => {
        if (d == null) {
          this.toastr.success("Added Product successfully");
          this.router.navigateByUrl("/products/view/1");

        }
        else if (d.err) {
          this.toastr.error("Unable to add the Products, please try again")
        }

        this.loaderEnabled = false;
      });

    }
  }

  productImageChange(data: any): void {
    let file = data.target.files[0];
    let reader = new FileReader();
    const me = this;
    this.loaderEnabled = true;
    reader.readAsDataURL(file);
    reader.onload = async function () {
      const imagedata = reader.result?.toString().split(',')[1];
      try {
        let imageUrl = await me.updateProductImages(imagedata);
        me.productImageUrl = imageUrl;
        me.loaderEnabled = false;
      } catch (error) {
        me.toastr.error("something went wrong");
      }
    };
    reader.onerror = function (error) {
      me.toastr.error("something went wrong " + error);
      console.log('Error: ', error);
    };
  }


  updateProductImages(data: any): Promise<any> {
    const headers = new HttpHeaders().set("Content-Length", data.length.toString());

    return this.http.put(this.uploadProductImage,
      { "image": data },
      { headers, responseType: 'text' }).toPromise();

  }
  
  addProduct() {
    this.submit = true;
    this.loaderEnabled = true;

  }

}
