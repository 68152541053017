import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { blogPost, PostRequest } from '../../models/blog/blog.models';

@Injectable({
  providedIn: 'root'
})
export class PostsService {

  count: number | any;
  nextLink: string | any;
  prevLink: string | any;
  Posts: blogPost[] | any;
  Article: blogPost | any;
  totalCount: number | any;
  constructor(private http: HttpClient, private cookieService: CookieService) { }


  getPosts(skip: number, limit: number) {
    let username = this.cookieService.get("username");
    if (username && username !== 'undefined') {
      const blogPostListEndPoint = environment.apiUrl + '/api/blogPosts/list/' + username + "?skip=" + skip + "&limit=" + limit;
      this.http.get<blogPost[]>(blogPostListEndPoint).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((data: blogPost[] | any) => {
        this.Posts = [...data];
        this.count = this.Posts.length;
        this.pagination(skip + 1);
      });
    }


  }

  getTotalPosts(){
      let username = this.cookieService.get("username");
      if(username && username !== 'undefined')
      {
        const totalProductsEndPoint =  environment.apiUrl + '/api/blogPosts/list/'+ username +"/count";
        this.http.get<number>(totalProductsEndPoint).pipe(
          take(1),
          catchError(err => {
            return of({ err: true, message: err.error.error_description })
          })
        ).subscribe((data : number |any) => {
          this.totalCount = data;
        });
      }
    }

  getPostInfo(index: number) {
    const username = this.cookieService.get("username");
    if (username && username !== 'undefined') {
      const blogPostDetailsEndPoint = environment.apiUrl + '/api/blogPosts/' + username + "/" + index;
      this.http.get<blogPost[]>(blogPostDetailsEndPoint).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((data: blogPost | any) => {
        this.Article = data;
      });
    }

    


  }

  pagination(index: number) {
    this.nextLink = "/blog/view/" + index;
    this.prevLink = "/blog/view/" + (index - 1);
    if (index <= 1) {
      this.prevLink = "/blog/view/1";
      if (this.count < 9) {
        this.nextLink = "/blog/view/1";
      }
      else {
        this.nextLink = "/blog/view/" + (index + 1);
      }
    }
    else {
      this.prevLink = "/blog/view/" + (index - 1);
      if (this.count < 9) {
        this.nextLink = "/blog/view/" + (index - 1);
      }
      else {
        this.nextLink = "/blog/view/" + (index + 1);
      }
    }

  }

  getBlogPostById(postId: string): Observable<blogPost>{
    let blogPostDetailsEndPoint = environment.apiUrl + '/api/blogPosts/'+ postId;
    return this.http.get<blogPost>(blogPostDetailsEndPoint);
  }

  updateBlogPost(blogPostRequest: PostRequest, postId: string): Observable<any>{
    let blogPostDetailsEndPoint = environment.apiUrl + '/api/blogPosts/'+ postId;
    return this.http.put<any>(blogPostDetailsEndPoint, blogPostRequest, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    });
  }

  addBlogPost(blogPostRequest: PostRequest): Observable<any>{
    let addBlogPostEndPoint = environment.apiUrl + '/api/blogPosts';
    return this.http.post<any>(addBlogPostEndPoint, blogPostRequest, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    });
  }

  archiveBlogPost(postId: string): Observable<any>{
    let blogPostDetailsEndPoint = environment.apiUrl + '/api/blogPosts/'+ postId;
    return this.http.delete<any>(blogPostDetailsEndPoint);
  }
}