  <!-- main-header -->
  <div class="main-header sticky side-header nav nav-item">
    <div class="container-fluid">
        <div class="main-header-left ">
            <div class="responsive-logo">
                <img src="https://ewns.in/assets/img/ewns-logo3.png" class="logo-1" alt="logo">
            </div>
            <div class="app-sidebar__toggle" data-toggle="sidebar" id="sidebar_btn">
                <div class="Fpen-toggle CP" (click)="toggleSideNave()"><i class="header-icon fe fe-align-left" ></i></div>
            </div>
            <div class="main-header-center ml-3 d-sm-none d-md-none d-lg-block">
                <input class="form-control" placeholder="Search for anything..." type="search"> <button class="btn"><i class="fas fa-search d-none d-md-block"></i></button>
            </div>
        </div>
        <div class="main-header-right">

            <div class="nav nav-item  navbar-nav-right ml-auto">
                <div class="nav-link" id="bs-example-navbar-collapse-1">
                    <form class="navbar-form" role="search">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Search">
                            <span class="input-group-btn">
                                <button type="reset" class="btn btn-default">
                                    <i class="fas fa-times"></i>
                                </button>
                                <button type="submit" class="btn btn-default nav-link resp-btn">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="header-icon-svgs" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                </button>
                            </span>
                        </div>
                    </form>
                </div>
                <div class="dropdown main-profile-menu nav nav-item nav-link">
                    <a routerLink="/logout" title="Logout"><i class="fas fa-sign-out-alt text-info"></i></a>

                </div>

            </div>
        </div>
    </div>
</div>
<!-- /main-header -->
