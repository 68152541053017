import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { forgotPasswordReq, LoginRequest } from 'src/app/core/models/merchant/authentication.models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  public passwordForm: FormGroup;
  constructor(private cookieService: CookieService,
    private http: HttpClient,
    private toastr: ToastrService,
    private router: Router) { 
      this.passwordForm = new FormGroup({
        username: new FormControl(null, Validators.required)
      });
    }

  ngOnInit(): void {
    let usernameDetails = this.cookieService.get("username");
    if(usernameDetails != "")
    {
      this.router.navigateByUrl("/dashboard");
    }
  }

  forgotPassword(): void{
    const {username} = this.passwordForm.value;

    if(username == "" || username == null)
    {
      this.toastr.error("Username cannot be empty", "Forgot Password");
    }
    else{
      this.forgotPasswordService(username);
    }
  }

  forgotPasswordService(username: string): void{
   
 
     const loginEndPoint = environment.apiUrl + "/api/Merchants/"+username + "/password/forgot";

     this.http.post(loginEndPoint, {
       headers: new HttpHeaders()
         .set('Content-Type', 'application/json')
     }).pipe(
       take(1),
       catchError(err => {
       
         if(err.status == 404)
         {
           this.toastr.error("Invalid Username", "Forgot Password");
         }
         return of({ err: true, message: err.error.error_description });
       })
     ).subscribe((d: any) => {
       if(d == null)
       {
          this.toastr.success("An email with instructions to reset password has been sent to your registered email", "Forgot Password");
       }
       else if (d.err) {
         this.toastr.error("Invalid username", "Forgot Password");
       }
     
     });
  }

}
