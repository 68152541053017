import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { AlbumService } from 'src/app/core/services/albums/album.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';

@Component({
  selector: 'app-add-album',
  templateUrl: './add-album.component.html',
  styleUrls: ['./add-album.component.css']
})
export class AddAlbumComponent implements OnInit {

  albumName = "";
  constructor(private titleService: Title,
    private merchantService: MerchantService,
    private albumService: AlbumService,
    private router: Router,
    private toastr: ToastrService,
    private cookieService: CookieService) { }

  ngOnInit(): void {
    let username = this.cookieService.get("username");
    if (username == "") {
      this.router.navigateByUrl("/");
    }
    if (!this.merchantService.getMerchantData()) {
      this.merchantService.getMechangeDetails();
    }

    this.titleService.setTitle("Add Albums | Content Management Portal");
  }

  async onAddClick(): Promise<void> {
    if(this.albumName?.length > 0)
    {
        await this.albumService.addAlbumObservable(this.albumName);
        this.toastr.success("Album " + this.albumName + " added successfully");
    }
    else{
      this.toastr.error("Album Name cannot be empty");
    }

    console.log(this.albumName);
  }

}
