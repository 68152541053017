import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { blogPost, PostRequest } from 'src/app/core/models/blog/blog.models';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { PostsService } from 'src/app/core/services/posts/posts.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-blog',
  templateUrl: './edit-blog.component.html',
  styleUrls: ['./edit-blog.component.css']
})
export class EditBlogComponent implements OnInit {

  article: blogPost | any;
  public loaderEnabled = false;
  public submit = false;
  public apiPath = environment.apiUrl + "/api/Images/custom/";
  public cofigObj = {
    "uploader": {
      "insertImageAsBase64URI": true
    },
    "minHeight": 400,
    "minWidth": 400
  }

  public description = "";
  public keyword = "";
  public category = "";

  constructor(private titleService: Title,
    private router: Router,
    private merchantService: MerchantService,
    private blogService: PostsService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private cookieService: CookieService) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    
     }

  ngOnInit(): void {
    let username = this.cookieService.get("username");
    if(username == "")
    {
      this.router.navigateByUrl("/");
    }
    if(!this.merchantService.getMerchantData())
    {
      this.merchantService.getMechangeDetails();
    }

    const routeParams = this.route.snapshot.paramMap;
    let index = String(routeParams.get('id'));

    this.blogService.getBlogPostById(index).pipe(
      take(1),
      catchError(err => {
        return of({ err: true, message: err.error.error_description })
      })
    ).subscribe((d: blogPost| any) => {
      if (d.err) {
        this.toastr.error("Invalid password")
      }
      else{
        this.article = d;
        this.titleService.setTitle(this.article.title);
      }
    })

    
  }


  updateDescription(wysiwygdata: string) {
    this.description = wysiwygdata;

    if(this.description == null || this.description == undefined || this.description == "")
    {
      this.toastr.error("Description cannot be empty");
      this.loaderEnabled = false;
    }
    else if(this.article?.description == this.description && this.category  == this.article?.category && this.keyword == this.article?.keywordList)
    {
      this.toastr.error("No changes detected");
      this.loaderEnabled = false;
    }
    else {

      let keywordArray: string[] = [];

      if (this.keyword != null) {
       
        keywordArray = this.keyword.split(",");
      }
      

        let postRequest: PostRequest ={
         title: this.article?.title,
         description: this.description,
         category: this.category,
         keywords: keywordArray,
         imageUrl: this.article?.imageUrl,
         username: this.cookieService.get('username')
        }

        this.blogService.updateBlogPost(postRequest, this.article?._id).pipe(
          take(1),
          catchError(err => {
            return of({ err: true, message: err.error.error_description })
          })
        ).subscribe((d: any) => {
          if(d == null)
          {
            this.toastr.success("Updated Blog Post successfully");
          }
          else if (d.err) {
            this.toastr.error("Unable to update the post, please try again")
          }
  
          this.loaderEnabled = false;
        });

    }
  }



  updateBlogPost(){
    this.loaderEnabled = true;
    this.submit = true;
  
  }

  archivePost(){
    this.loaderEnabled = true;
    this.blogService.archiveBlogPost(this.article?._id).pipe(
      take(1),
      catchError(err => {
        return of({ err: true, message: err.error.error_description })
      })
    ).subscribe((d: any) => {
      if(d == null)
      {
        this.router.navigateByUrl("/blog/view/1");
      }
      else if (d.err) {
        this.toastr.error("Unable to archive the post, please try again")
      }

      this.loaderEnabled = false;
    });
  }



}
