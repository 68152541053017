import { NestedTreeControl } from '@angular/cdk/tree';
import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { category } from 'src/app/core/models/category/category.models';
import { CategoryService } from 'src/app/core/services/categories/category.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  treeControl = new NestedTreeControl<category>(node => node.childCategories);
  dataSource = new MatTreeNestedDataSource<category>();

  nodes: any;
  constructor(public categoryService: CategoryService,
    private titleService: Title,
    private merchantService: MerchantService,
    private router: Router,
    private cookieService: CookieService

  ) { }

  async ngOnInit(): Promise<void> {
    let username = this.cookieService.get("username");
    if (username == "") {
      this.router.navigateByUrl("/");
    }
    if (!this.merchantService.getMerchantData()) {
      this.merchantService.getMechangeDetails();
    }

    this.titleService.setTitle("View Categories | Content Management Portal ");
    let categories = await this.categoryService.getCategoriesObservable();
    let formattedData = JSON.stringify(categories);
    formattedData = formattedData.split("childCategories").join("children");
    formattedData = JSON.parse(formattedData);
    this.categoryService.categories = [...categories];
    this.nodes = formattedData;
    this.dataSource.data = this.categoryService.categories;
  }
  onNodeClick(ev: any): void { 
    alert('node item clicked and evt contains on not related data')
  }
}
