import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { OrderService } from 'src/app/core/services/orders/order.service';
import { PostsService } from 'src/app/core/services/posts/posts.service';
import { ProductService } from 'src/app/core/services/products/product.service';
import { ServiceService } from 'src/app/core/services/services/service.service';
import { SubscriberService } from 'src/app/core/services/subscribers/subscriber.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  private FB = "facebook";
  private TWITTER = "twitter";
  constructor(public merchantService: MerchantService,
    private titleService: Title,
    private router: Router,
    private cookieService: CookieService,
    public subscriberService: SubscriberService,
    public productService: ProductService,
    public service: ServiceService,
    public blogService: PostsService,
    public orderService: OrderService
  ) {

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    let username = this.cookieService.get("username");
    if (username == "") {
      this.router.navigateByUrl("/");
    }
    if (!this.merchantService.getMerchantData()) {
      this.merchantService.getMechangeDetails();
    }

    this.titleService.setTitle("Profile | CMS ");

    this.productService.getTotalProducts();
    this.service.getTotalServices();
    this.blogService.getTotalPosts();
    this.subscriberService.getTotalSubscribers();


    this.orderService.getTotalOrders();

  }

  onSocialMediaConnect(name: string) {
    switch (name) {
      case this.FB:
        this.onFacebookConnect();
        break;
      case this.TWITTER:
        this.onTwitterConnect();
        break;

      default:
        break;
    }
  }
  onTwitterConnect() {
    alert("connecting twitter")
  }
  onFacebookConnect() {
    alert("conecting facebook")
  }
}
