<div class="page">
    <!-- main-sidebar -->
    <div class="app-sidebar__overlay z0" data-toggle="sidebar"></div>
    <app-sidebar></app-sidebar>
    <!-- main-sidebar -->
    <!-- main-content -->
    <div class="main-content app-content">
       <!-- main-header -->
       <app-header></app-header>
       <!-- /main-header -->
       <!-- container -->
       <div class="container-fluid topabsolute">
          <!-- breadcrumb -->
          <div class="breadcrumb-header justify-content-between">
             <div class="my-auto">
                <div class="d-flex">
                   <h4 class="content-title mb-0 my-auto">Home</h4>
                   <span class="text-muted mt-1 tx-13 ml-2 mb-0">/ Category</span>
                </div>
             </div>
          </div>
          <!-- breadcrumb -->
          <div class="row">
            <div class="col-md-12">
                <div class="card mg-b-20">
                    <div class="card-body">
                        <div class="main-content-label mg-b-5">
                            Categories
                            <br/>
                        </div>
                        <div class="row">
                            <!-- col -->
                            <div class="col-lg-4" *ngIf="categoryService?.categories?.length > 0">
                              
                                <tree-root (activate)="onNodeClick($event)" [nodes]="nodes">

                                </tree-root>
                            
                            </div>

                            <h4 *ngIf="categoryService?.categories?.length == 0">No categories added yet, you can add your business categories <a routerLink="/category/add" title="Add Category">here</a></h4>

                              
                            <!-- /col -->

                         
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
          <!-- row -->
          <!-- row closed -->
       </div>
       <!-- Container closed -->
    </div>
    <!-- main-content closed -->
    <!-- Footer opened -->
    <app-footer></app-footer>
    <!-- Footer closed -->
 </div>