import { NestedTreeControl } from '@angular/cdk/tree';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { blogPost } from 'src/app/core/models/blog/blog.models';
import { category } from 'src/app/core/models/category/category.models';
import { serviceModel } from 'src/app/core/models/service/service.models';
import { CategoryService } from 'src/app/core/services/categories/category.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { ServiceService } from 'src/app/core/services/services/service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-service',
  templateUrl: './edit-service.component.html',
  styleUrls: ['./edit-service.component.css']
})
export class EditServiceComponent implements OnInit {

  dataForCats: category[] = [];
  dataForSubCats: category[] = [];
  selectedCat: any;
  selectedSubCat: any;
  disabledSubCats = true;
  selectSubCatText = "Please select category first";
  keyword = 'name';

  public uploaServiceImage = environment.apiUrl + "/api/Services/image/";
  public serviceImageUrl = "";


  @ViewChild('subcats') subcatselm: any;
  selectEvent(item: any) {
    this.selectedSubCat = item.name;
  }


  public allCategories: category[] = [];
  public categories: category[] = [];
  public description = "";
  public keywords = "";
  public category = "";

  public price: number | any = 0;
  public discount: number | any = 0;
  public quantity: number | any = 0;
  public bookOnline = "";
  public warranty = "";
  public shortBio = "";
  public brand = "";

  treeControl = new NestedTreeControl<category>(node => node.childCategories);
  dataSource = new MatTreeNestedDataSource<category>();
  serviceItem: serviceModel | any;

  public loaderEnabled = false;
  public submit = false;
  public apiPath = environment.apiUrl + "/api/Images/custom/";
  public cofigObj = {
    "uploader": {
      "insertImageAsBase64URI": true
    },
    "minHeight": 400,
    "minWidth": 400
  }

  constructor(private titleService: Title,
    private router: Router,
    private merchantService: MerchantService,
    private service: ServiceService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private categoryService: CategoryService,
    private cookieService: CookieService,
    private http: HttpClient
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;


  }

  async ngOnInit(): Promise<void> {

    const windowObj = window as any;
    windowObj.$('.dropify').dropify({});

    let username = this.cookieService.get("username");
    if (username == "") {
      this.router.navigateByUrl("/");
    }
    if (!this.merchantService.getMerchantData()) {
      this.merchantService.getMechangeDetails();
    }

    const routeParams = this.route.snapshot.paramMap;
    let id = String(routeParams.get('id'));


    this.serviceItem = await this.service.getServiceInfoByIdPromise(id);
    this.titleService.setTitle(this.serviceItem?.name);

    this.categories = await this.categoryService.getCategoriesObservable();
    this.allCategories = await this.categoryService.getBusinessCategories();


  }

  async getParentCategories(): Promise<void> {
    let categories = await this.categoryService.getCategoriesObservable();
    this.dataForCats = categories;
  }
  getSubcategories(key: string): Array<category> {
    let item = this.dataForCats.find(s => s._id == key)?.childCategories;
    this.dataForSubCats = item;
    return item;
  }

  onSelectParentCategory(evt: any): void {
    this.selectSubCatText = "Please Select SubCategory";
    this.disabledSubCats = false;
    let item = this.dataForCats.find(s => s._id == evt._id)?.childCategories;
    this.dataForSubCats = item;
    this.getSubcategories(evt._id);
  }

  onClearParent(): void {
    this.subcatselm.close();
    this.subcatselm.clear();
    this.selectSubCatText = "Please select category first";
    this.disabledSubCats = true;
    this.dataForSubCats = new Array();
  }

  updateDescription(wysiwygdata: string) {

    this.description = wysiwygdata;

    if (this.description == null || this.description == undefined || this.description == "") {
      this.description = this.serviceItem?.description;
    }

    if (this.description == null || this.description == undefined || this.description == "") {
      this.toastr.error("Description cannot be empty");
      this.loaderEnabled = false;
    }
    else {

      let keywordArray: string[] = [];

      if (this.keywords != null) {
        keywordArray = this.keywords.split(",");
      }

      let categoryString = this.serviceItem?.category;
      let categoryId = this.serviceItem?.categoryId;

      if(this.allCategories != null && this.category != "")
      {
        let catItem =  this.allCategories.find(s=> s._id == this.category);
        categoryString= catItem?.name;
        categoryId = catItem?._id;
      }
      

      let serviceReq = this.serviceItem;
      serviceReq.description = this.description;
      serviceReq.category = categoryString; 
      serviceReq.categoryId = categoryId; 
      serviceReq.warranty = this.warranty;
      serviceReq.price = Number(this.price);
      serviceReq.discount = Number(this.discount);
      serviceReq.shortBio = this.shortBio;
      serviceReq.brand = this.brand;
      serviceReq.buyOnlineLink = this.bookOnline;
      serviceReq.keywords = keywordArray;
      serviceReq.stock = Number(this.quantity);


      this.service.updateService(this.serviceItem?._id, serviceReq).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((d: any) => {
        if (d == null) {
          this.toastr.success("Successfully updated the service");
        }
        else if (d.err) {
          this.toastr.error("Unable to update the service, please try again")
        }

        this.loaderEnabled = false;
      });

    }


  }

  updateService() {
    this.loaderEnabled = true;
    this.submit = true;
  }

  archiveImage(item: any) {
    this.loaderEnabled = true;
    this.service.archiveServiceImage(this.serviceItem?._id, item.actual).pipe(
      take(1),
      catchError(err => {
        return of({ err: true, message: err.error.error_description })
      })
    ).subscribe((d: any) => {
      if (d == null) {
        this.router.navigateByUrl("/services/view/1");
      }
      else if (d.err) {
        this.toastr.error("Unable to archive the image, please try again")
      }

      this.loaderEnabled = false;
    });


  }

  archiveService() {

    this.loaderEnabled = true;
    this.service.archiveService(this.serviceItem?._id).pipe(
      take(1),
      catchError(err => {
        return of({ err: true, message: err.error.error_description })
      })
    ).subscribe((d: any) => {
      if (d == null) {
        this.router.navigateByUrl("/services/view/1");
      }
      else if (d.err) {
        this.toastr.error("Unable to archive the service, please try again")
      }

      this.loaderEnabled = false;
    });
  }


  serviceImageChange(data: any): void {
    let file = data.target.files[0];
    let reader = new FileReader();
    const me = this;
    this.loaderEnabled = true;
    reader.readAsDataURL(file);
    reader.onload = async function () {
      const imagedata = reader.result?.toString().split(',')[1];
      try {
        me.toastr.info("Please wait till image is uploaded");
        let imageUrl = await me.uploadServiceImages(imagedata);
        me.toastr.success("Image uploaded");
        me.serviceImageUrl = imageUrl;
        me.loaderEnabled = false;
      } catch (error) {
        me.toastr.error("something went wrong");
      }
    };
    reader.onerror = function (error) {
      me.toastr.error("something went wrong " + error);
      console.log('Error: ', error);
    };
  }


  uploadServiceImages(data: any): Promise<any> {
    const headers = new HttpHeaders().set("Content-Length", data.length.toString())
    let usernameDetails = this.cookieService.get("username");

    return this.http.put(this.uploaServiceImage + this.serviceItem?._id,
      { "image": data },
      { headers, responseType: 'text' }).toPromise();

  }

}
