<div class="page">
    <!-- main-sidebar -->
    <div class="app-sidebar__overlay z0" data-toggle="sidebar"></div>
    <app-sidebar></app-sidebar>
    <!-- main-sidebar -->
    <!-- main-content -->
    <div class="main-content app-content">
       <!-- main-header -->
       <app-header></app-header>
       <!-- /main-header -->
       <!-- container -->
       <div class="container-fluid topabsolute">
          <!-- breadcrumb -->
          <div class="breadcrumb-header justify-content-between">
             <div class="my-auto">
                <div class="d-flex">
                   <h4 class="content-title mb-0 my-auto">Home</h4>
                   <span class="text-muted mt-1 tx-13 ml-2 mb-0">/ Messages</span>
                </div>
             </div>
          </div>
          <!-- breadcrumb -->

          <div class="row">
            <div class="col-md-10">

               <div class="row row-sm">
                  <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="main-content-body main-content-body-mail card-body">
                                <div class="main-mail-header">
                                   <div>
                                      <h4 class="main-content-title mg-b-5">Business Enquiries</h4>
                                   </div>
                                   <div>
                                      <span>{{pageNo*pageLimit}}-{{((pageNo+1) * pageLimit) > messageService.totalCount? messageService.totalCount: ((pageNo+1) * pageLimit) }} of {{messageService.totalCount}}</span>
                                      <div class="btn-group" role="group">
                                           <button *ngIf="pageNo == 0" class="btn btn-outline-secondary disabled" type="button" (click)="getPrevious($event)">
                                               <i class="fas fa-chevron-left"></i>
                                            </button>
                                            <button *ngIf="pageNo > 0" class="btn btn-outline-secondary" type="button" (click)="getPrevious($event)">
                                              <i class="fas fa-chevron-left"></i>
                                           </button>

                                           <button  *ngIf="((pageNo+1)*(pageLimit)) < messageService.totalCount" class="btn btn-outline-secondary" type="button" (click)="getNext($event)">
                                               <i class="fas fa-chevron-right"></i>
                                            </button>

                                            <button *ngIf="((pageNo+1)*(pageLimit)) > messageService.totalCount" class="btn btn-outline-secondary disabled" type="button" (click)="getNext($event)">
                                              <i class="fas fa-chevron-right"></i>
                                           </button>
                                        </div>
                                   </div>
                                </div>
                                <!-- main-mail-list-header -->
                                <div class="main-mail-options">
                                   <label class="ckbox">Messages</label>

                                </div>
                                <!-- main-mail-options -->
                                <div class="main-mail-list" *ngFor="let mgItem of messageService?.allMessages">
                                   <div class="main-mail-item unread">
                                      <div class="main-mail-checkbox">  </div>
                                      <div class="main-mail-star"> <i class="fas fa-envelope fa-lg"></i> </div>
                                      <div class="main-img-user"><i class="fas fa-user-circle fa-2x"></i></div>
                                      <div class="main-mail-body">
                                         <div class="main-mail-from"><strong style = "text-transform:uppercase;color: rgba(1, 82, 168, 1)">{{mgItem.contactName}}</strong>   </div>
                                         <span *ngIf="mgItem.email"> Email: {{mgItem.email}}                <br/></span>
                                         <span *ngIf="mgItem.from.split(':').length > 1"> Email: {{mgItem.from.split(':')[0]}}                <br/></span>

                                         <span *ngIf="mgItem.from.split(':').length > 1"> Contact Number: {{mgItem.from.split(':')[1]}}</span>
                                         <span *ngIf="mgItem.from.split(':').length == 1"> Contact Number: {{mgItem.from.split(':')[0]}}</span>
                                         <br/><br/>
                                          <span style="color: #8991a5 !important; text-align: justify;" >{{mgItem.message}}</span>
                                      </div>

                                      <div class="main-mail-date"> {{mgItem.createdOn | dateFormat}} </div>
                                   </div>


                                </div>
                                <div class="mg-lg-b-30"></div>
                             </div>
                        </div>
                     </div>
                  </div>

              </div>


            </div>

         </div>


          <!-- row -->
          <!-- row closed -->
       </div>
       <!-- Container closed -->
    </div>
    <!-- main-content closed -->
    <!-- Footer opened -->
    <app-footer></app-footer>
    <!-- Footer closed -->
 </div>
