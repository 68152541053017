<div id="global-loader" *ngIf="loaderEnabled">
    <img src="https://ewnsassets.s3.ap-south-1.amazonaws.com/cms/assets/img/loader.svg" class="loader-img" alt="Loader">
  </div>
<div class="page">
    <!-- main-sidebar -->
    <div class="app-sidebar__overlay" data-toggle="sidebar"></div>
    <app-sidebar></app-sidebar>
    <!-- main-sidebar -->
    <!-- main-content -->
    <div class="main-content app-content">
       <!-- main-header -->
       <app-header></app-header>
       <!-- /main-header -->
       <!-- container -->
       <div class="container-fluid topabsolute">
          <!-- breadcrumb -->
          <div class="breadcrumb-header justify-content-between">
             <div class="my-auto">
                <div class="d-flex">
                   <h4 class="content-title mb-0 my-auto">Home</h4>
                   <span class="text-muted mt-1 tx-13 ml-2 mb-0">/ Testimony</span>
                </div>
             </div>
          </div>
          <!-- breadcrumb -->
          <div class="row">
            <div class="col-md-10">
                <div class="card mg-b-20">
                    <div class="card-body">
                        <div class="main-content-label mg-b-5">
                            Customer Testimonials
                            <br/><br/><br/>
                        </div>
                        <div class="row row-sm">
                            <!-- col -->
                            <div class="col-xl-12 col-lg-9 col-md-12" *ngFor="let testItem of testimonials">
                                <div class="media d-block d-sm-flex">
                                    <img *ngIf="!testItem?.image?.small" alt="{{testItem.title}}" class="main-img-user avatar-lg mg-sm-r-20 mg-b-20 mg-sm-b-0" src="https://ewnsassets.s3.ap-south-1.amazonaws.com/cms/assets/img/faces/4.jpg">
                                    <img *ngIf="testItem?.image?.small" alt="{{testItem.title}}" class="main-img-user avatar-lg mg-sm-r-20 mg-b-20 mg-sm-b-0" src="{{testItem?.image?.small}}">
                                    <div class="media-body">
                                        <h5 class="mg-b-5 tx-inverse tx-15">{{testItem?.title}}</h5> - By {{testItem?.name}}   <a href="/testimony/edit/{{testItem._id}}" style="float: right;" title="{{testItem.title}}">EDIT</a>
                                        <div class="rating mb-1">
                                            <div class="stars">
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star text-muted"></span>
                                            </div>
                                        </div>
                                        {{testItem?.description}} <br/><br/>
                                       
                                      
                                    </div>
                                </div>
                            </div>
                         
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
          <!-- row -->
          <!-- row closed -->
       </div>
       <!-- Container closed -->
    </div>
    <!-- main-content closed -->
    <!-- Footer opened -->
 
    <!-- Footer closed -->
 </div>