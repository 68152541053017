<div class="page">
  <!-- main-sidebar -->
  <div class="app-sidebar__overlay z0" data-toggle="sidebar"></div>
  <app-sidebar></app-sidebar>
  <!-- main-sidebar -->
  <!-- main-content -->
  <div class="main-content app-content">
    <!-- main-header -->
    <app-header></app-header>
    <!-- /main-header -->
    <!-- container -->
    <div class="container-fluid topabsolute">
      <!-- breadcrumb -->
      <div class="breadcrumb-header justify-content-between">
        <div class="my-auto">
          <div class="d-flex">
            <h4 class="content-title mb-0 my-auto">Blog</h4>
            <span class="text-muted mt-1 tx-13 ml-2 mb-0">/ Update</span>
          </div>
        </div>
      </div>
      <!-- breadcrumb -->
      <div class="row">
        <div class="col-md-10">
          <div class="card mg-b-20">
            <div class="card-body">
              <div class="row row-sm">
                <div class="col-md-12 col-sm-12">
                  <div class="card box-shadow-0">
                    <div class="card-header">
                      <h4 class="card-title mb-1">Update Blog</h4>
                    </div>
                    <div class="card-body pt-0">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label> Title </label>
                            <input
                              type="text"
                              class="form-control"
                              value="{{ this.article?.title }}"
                              readonly
                            />
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Description</label>
                            <app-wysiwyg
                              *ngIf="this.article.description"
                              [configObj]="cofigObj"
                              [uploadUrl]="apiPath"
                              [value]="this.article.description"
                              [pressedSubmit]="submit"
                              (getData)="updateDescription($event)"
                            ></app-wysiwyg>
                          </div>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputEmail1">Category</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Email"
                              value="{{ this.article?.category }}"
                              [(ngModel)]="category"
                            />
                          </div>
                        </div>
                        <div class="col-md-9">
                          <div class="form-group">
                            <label for="exampleInputEmail1">Keywords</label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Email"
                              value="{{ this.article?.keywordList }}"
                              [(ngModel)]="keyword"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="text-center mg-b-20" *ngIf="loaderEnabled">
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>

                      <div class="form-group mb-0 mt-3 justify-content-end">
                        <div>
                          <button
                            type="submit"
                            class="btn btn-primary"
                            style="float: right"
                            (click)="updateBlogPost()"
                          >
                            UPDATE
                          </button>
                          <button
                            type="submit"
                            class="btn btn-secondary"
                            style="float: left"
                            (click)="archivePost()"
                          >
                            ARCHIVE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- row -->
      <!-- row closed -->
    </div>
    <!-- Container closed -->
  </div>
  <!-- main-content closed -->
  <!-- Footer opened -->
  <app-footer></app-footer>
  <!-- Footer closed -->
</div>
