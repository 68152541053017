<div class="page">
    <!-- main-sidebar -->
    <div class="app-sidebar__overlay" data-toggle="sidebar"></div>
    <app-sidebar></app-sidebar>
    <!-- main-sidebar -->
    <!-- main-content -->
    <div class="main-content app-content">
      <!-- main-header -->
      <app-header></app-header>
      <!-- /main-header -->
      <!-- container -->
      <div class="container-fluid topabsolute">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
          <div class="my-auto">
            <div class="d-flex">
              <h4 class="content-title mb-0 my-auto">Service</h4>
              <span class="text-muted mt-1 tx-13 ml-2 mb-0">/ Add</span>
            </div>
          </div>
        </div>
        <!-- breadcrumb -->
        <div class="row">
          <div class="col-md-10">
            <div class="card mg-b-20">
              <div class="card-body">
                <div class="row row-sm">
                  <div class="col-md-12 col-sm-12">
                    <div class="card box-shadow-0">
                      <div class="card-header">
                        <h4 class="card-title mb-1">Add Service</h4>
                      </div>
                      <div class="card-body pt-0">
                       
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <br/>
                              <label> Upload File </label>
                              <input
                                type="file"
                                (change)="serviceImageChange($event)"
                                class="dropify height-wrapper"
                                accept=".png, .jpg, .jpeg"
                                data-allowed-file-extensions="jpg png jpeg"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label> Name </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Name"
                                  [(ngModel)]="name"
                                />
                              </div>
                            </div>
  
                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Keywords</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Keywords (separate by ,)"
                                  [(ngModel)]="keywords"
                                />
                              </div>
                            </div>
  
                            <div class="col-md-6">
                              <div class="form-group">
                                <label> Short Description </label>
                                <textarea
                                  class="form-control"
                                  placeholder="Short Description"
                                  rows="6"
                                  [(ngModel)]="shortBio"
                                ></textarea>
                              </div>
                            </div>
  
                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Buy/Book Online</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Buy/Book Online"
                                  [(ngModel)]="bookOnline"
                                />
                              </div>
                            </div>
  
                            <div class="col-md-12">
                              <div class="form-group">
                                <label>Description</label>
                                <app-wysiwyg
                                  [configObj]="cofigObj"
                                  [uploadUrl]="apiPath"
                                  [pressedSubmit]="submit"
                                  (getData)="updateDescription($event)"
                                ></app-wysiwyg>
                              </div>
                            </div>
  
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Brand</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Brand"
                                  [(ngModel)]="brand"
                                />
                              </div>
                            </div>
  
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Category</label>
                                <select [(ngModel)]="category" class="form-control">
                                  <option *ngFor="let catItem of this.allCategories" ng-selected="this.serviceItem?.categoryId == catItem._id" [ngValue]="catItem._id">
                                    <span *ngIf="catItem.isParent">{{catItem.name}}</span>
                                    <span *ngIf="!catItem.isParent">{{catItem.parentCategory}} > {{catItem.name}}</span>
                                  </option>
                                </select>
                                
                              </div>
                            </div>
  
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Stock Quantity</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Stock Quantity Available"
                                  [(ngModel)]="quantity"
                                />
                              </div>
                            </div>
  
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Price</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Price per Item"
                                  [(ngModel)]="price"
                                />
                              </div>
                            </div>
  
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Discount (%)</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Discount in %"
                                  [(ngModel)]="discount"
                                />
                              </div>
                            </div>
  
                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Warranty</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  placeholder="Warranty"
                                  [(ngModel)]="warranty"
                                />
                              </div>
                            </div>
                          </div>
  
                          <div class="text-center mg-b-20" *ngIf="loaderEnabled">
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
  
                          <div class="form-group mb-0 mt-3 justify-content-end">
                            <div>
                              <button
                                type="submit"
                                class="btn btn-primary"
                                style="float: right"
                                (click)="addService()"
                              >
                                ADD SERVICE
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- row -->
          <!-- row closed -->
        </div>
        <!-- Container closed -->
      </div>
      <!-- main-content closed -->
      <!-- Footer opened -->
   
      <!-- Footer closed -->
    </div>
  </div>
  