import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private cookieService: CookieService,
    private router: Router,
    private merchantService: MerchantService,
    private titleService: Title) { 
    }

  ngOnInit(): void {
    let username = this.cookieService.get("username");
    if(username == "")
    {
      this.router.navigateByUrl("/");
    }
    this.merchantService.getMechangeDetails();
    this.titleService.setTitle("Dashboard | Content Management Portal ");



  }

}
