import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { subscriber } from '../../models/subscriber/subscriber.models';

@Injectable({
  providedIn: 'root'
})
export class SubscriberService {

  list: subscriber[] | any;
  totalCount: number | any;
  constructor(private http: HttpClient, private cookieService: CookieService) { }

  getTotalSubscribers(){
    let username = this.cookieService.get("username");
    if(username && username !== 'undefined')
    {
      const totalProductsEndPoint =  environment.apiUrl + '/api/Internal/subscribers/'+ username +"/count";
      this.http.get<number>(totalProductsEndPoint).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((data : number |any) => {
        this.totalCount = data;
      });
    }
  }

  getSubscribers() {
    let username = this.cookieService.get("username");
    if (username && username !== 'undefined') {
      const blogPostListEndPoint = environment.apiUrl + '/api/Internal/subscribers/' + username ;
      this.http.get<subscriber[]>(blogPostListEndPoint).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((data: subscriber[] | any) => {
        this.list = data;
      });
    }


  }

}