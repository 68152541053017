import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.css']
})
export class SignoutComponent implements OnInit {

  constructor(private cookieService: CookieService, private router: Router) { }

  async ngOnInit(): Promise<void> {

    await this.deleteCookies();
    this.router.navigateByUrl("/");
  }

  async deleteCookies(){
    this.cookieService.deleteAll();
  }

}
