import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { PostRequest } from 'src/app/core/models/blog/blog.models';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { PostsService } from 'src/app/core/services/posts/posts.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-blog',
  templateUrl: './add-blog.component.html',
  styleUrls: ['./add-blog.component.css']
})
export class AddBlogComponent implements OnInit {

  public loaderEnabled = false;
  public submit = false;
  backgroundImageApi = environment.apiUrl + '/api/Images/background/'
  public apiPath = environment.apiUrl + "/api/Images/custom/";

  public uploadBlogImage = environment.apiUrl + "/api/BlogPosts/image";
  public blogImageUrl = "";
  public title = "";
  public description = "";
  public keyword = "";
  public category = "";


  public cofigObj = {
    "uploader": {
      "insertImageAsBase64URI": true
    },
    "minHeight": 400,
    "minWidth": 400
  }
  
  constructor(private titleService: Title,
    private router: Router,
    private merchantService: MerchantService,
    private blogService: PostsService,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private http: HttpClient) {

  }

  ngOnInit(): void {
    const windowObj = window as any;
    windowObj.$('.dropify').dropify({});
    let username = this.cookieService.get("username");
    if (username == "") {
      this.router.navigateByUrl("/");
    }
    if (!this.merchantService.getMerchantData()) {
      this.merchantService.getMechangeDetails();
    }

    this.titleService.setTitle("Add Blog Post | Content Management Portal");
  }

  updateDescription(wysiwygdata: string) {
    this.description = wysiwygdata;
    if (this.description == null || this.description == undefined || this.description == "") {
      this.toastr.error("Description cannot be empty");
      this.loaderEnabled = false;
    }
    else if (this.title == null || this.title == undefined || this.title == "") {
      this.toastr.error("Title cannot be empty");
      this.loaderEnabled = false;
    }
    else {

      let keywordArray: string[] = [];

      if (this.keyword != null) {
       
        keywordArray = this.keyword.split(",");
      }


      let postRequest: PostRequest = {
        title: this.title,
        description: this.description,
        category: this.category,
        keywords: keywordArray,
        imageUrl: this.blogImageUrl,
        username: this.cookieService.get('username')
      }

      this.blogService.addBlogPost(postRequest).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((d: any) => {
        if (d == null) {
          this.toastr.success("Added Blog Post successfully");
          this.router.navigateByUrl("/blog/view/1");

        }
        else if (d.err) {
          this.toastr.error("Unable to add the post, please try again")
        }

        this.loaderEnabled = false;
      });

    }
  }

  backgroundImagechange(data: any): void {
    let file = data.target.files[0];
    let reader = new FileReader();
    const me = this;
    this.loaderEnabled = true;
    reader.readAsDataURL(file);
    reader.onload = async function () {
      const imagedata = reader.result?.toString().split(',')[1];
      try {
        let imageUrl = await me.uploadBackGroundImages(imagedata);
        me.blogImageUrl = imageUrl;
        me.loaderEnabled = false;
      } catch (error) {
        me.toastr.error("something went wrong");
      }
    };
    reader.onerror = function (error) {
      me.toastr.error("something went wrong " + error);
      console.log('Error: ', error);
    };
  }


  uploadBackGroundImages(data: any): Promise<any> {
    const headers = new HttpHeaders().set("Content-Length", data.length.toString())
    let usernameDetails = this.cookieService.get("username");

    return this.http.put(this.uploadBlogImage,
      { "image": data },
      { headers, responseType: 'text' }).toPromise();

  }
  addBlogPost() {
    this.submit = true;
    this.loaderEnabled = true;

  }



}
