import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { mapDomainRequest } from '../../models/domain/domain.models';

@Injectable({
  providedIn: 'root'
})
export class DomainServiceService {

  constructor(private http: HttpClient,
    private cookieService: CookieService,
    private toastr: ToastrService) {


  }

  getDNSInfo(): Promise<any> {
    let username = this.cookieService.get("username");
    let getDNSInfoEndPoint = environment.apiUrl + '/api/Domain/dnsinfo/' + username;
    return this.http.get<any>(getDNSInfoEndPoint).toPromise();
  }

  getDomainInfo(): Promise<any> {
    let username = this.cookieService.get("username");
    let getDomainNameEndPoint = environment.apiUrl + '/api/Domain/' + username;
    return this.http.get<any>(getDomainNameEndPoint).toPromise();
  }


  mapDomain(domainRequest: mapDomainRequest): Promise<any> {

    let mapDomainEndPoint = environment.apiUrl + '/api/Domain/map';
    return this.http.post<any>(mapDomainEndPoint, domainRequest, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise();

  }

  isDomainAvailable(username: string, domainName: string) {
    let domainCheckEndpoint = environment.apiUrl + '/api/Domain/isDomainAvailable';

    return this.http.get<any>(domainCheckEndpoint + "?userName=" + username + "&domainName=" + domainName, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise();
  }
}
