<!-- Page -->
<div class="signin-component">
  <div class="left-container">
    <div class="left-component">
      <div class="rocket-container">
        <img src="https://ewnsassets.s3.ap-south-1.amazonaws.com/cms/assets/img/media/roocket.svg" height="77px" width="77px" alt="rocket-logo">
        <div class="left-description">
          <p>Take your <span class="business-text">Business</span> <br>to the next level !!</p>
        </div>
      </div>
      <div class="left-comp">

        <div class="charecter">
          <img src="https://ewnsassets.s3.ap-south-1.amazonaws.com/cms/assets/img/media/charecter.svg" height="260px" width="275px" alt="rocket-logo">

        </div>
      </div>
    </div>

  </div>
  <div class="right-container">

  </div>

</div>
<!-- End Page -->
