import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { album } from 'src/app/core/models/albums/album.models';
import { AlbumService } from 'src/app/core/services/albums/album.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-album',
  templateUrl: './edit-album.component.html',
  styleUrls: ['./edit-album.component.css']
})
export class EditAlbumComponent implements OnInit {

  public uploadAlbumImage = environment.apiUrl + "/api/Albums/image/";
  public archiveAlbumEndPoint = environment.apiUrl + "/api/Albums/";
  albumItem: album | any;
  public albumName = "";
  public loaderEnabled = false;
  constructor(private titleService: Title,
    private router: Router,
    private merchantService: MerchantService,
    private albumService: AlbumService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private http: HttpClient) { 

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  async ngOnInit(): Promise<void> {

    const windowObj = window as any;
    windowObj.$('.dropify').dropify({});


    let username = this.cookieService.get("username");
    if (username == "") {
      this.router.navigateByUrl("/");
    }
    if (!this.merchantService.getMerchantData()) {
      this.merchantService.getMechangeDetails();

      
    }


    const routeParams = this.route.snapshot.paramMap;
    let id = String(routeParams.get('id'));

    this.albumItem = await this.albumService.getAlbumDetailsAsync(id);
    this.albumName = this.albumItem?.name;
    this.titleService.setTitle(this.albumItem?.name);
  }

  async archiveImage(data: any){
    console.log(data);
    let imageId = data?._id;

    await this.http.delete(this.archiveAlbumEndPoint + this.albumItem?._id + "/" + imageId).toPromise();
    window.location.reload();
  }

  albumImageChange(data: any): void {
    let file = data.target.files[0];
    let reader = new FileReader();
    const me = this;
    this.loaderEnabled = true;
    reader.readAsDataURL(file);
    reader.onload = async function () {
      const imagedata = reader.result?.toString().split(',')[1];
      try {
        me.toastr.info("Please wait till image is uploaded");
        let imageUrl = await me.uploadAlbumImages(imagedata);
        me.toastr.success("Image uploaded to album");
        me.loaderEnabled = false;
      } catch (error) {
        me.toastr.error("something went wrong");
      }
    };
    reader.onerror = function (error) {
      me.toastr.error("something went wrong " + error);
      console.log('Error: ', error);
    };
  }


  uploadAlbumImages(data: any): Promise<any> {
    const headers = new HttpHeaders().set("Content-Length", data.length.toString())
    let usernameDetails = this.cookieService.get("username");

    return this.http.put(this.uploadAlbumImage + this.albumItem?._id + "/"+ this.albumItem?.name,
      { "image": data },
      { headers, responseType: 'text' }).toPromise();

  }


  async updateAlbum()
  {
      if(this.albumName == undefined || this.albumName == "" || this.albumName == null)
      {
        this.toastr.error("Album name cannot be empty");
      }
      else if(this.albumName == this.albumItem?.name)
      {
        this.toastr.error("No change of Album name detected");
      }
      else{
        await this.albumService.updateAlbumName(this.albumName, this.albumItem?._id);
        this.toastr.success("Album name updated");
      }
  }

  async archiveAlbum(): Promise<any>
  {
    await this.http.delete(this.archiveAlbumEndPoint + this.albumItem?._id).toPromise();
    this.router.navigateByUrl("/albums");
  }

}
