import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { LoginRequest } from 'src/app/core/models/merchant/authentication.models';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class NewSigninComponent implements OnInit {

  public loginForm: FormGroup;
  constructor(private http: HttpClient,
    private toastr: ToastrService,
    private router: Router,
    private merchantService: MerchantService,
    private cookieService: CookieService) {

    this.loginForm = new FormGroup({
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required)
    });

  }

  ngOnInit(): void {

    let usernameDetails = this.cookieService.get("username");
    if (usernameDetails != "") {
      this.merchantService.getMechangeDetails();
      this.router.navigateByUrl("/dashboard");
    }
  }


  login(): void {

    const { username, password } = this.loginForm.value;
    if (username == null || username == "") {
      this.toastr.error("Username cannot be empty", "Login Failed");
    }
    else if (password == null || password == "") {
      this.toastr.error("Password cannot be empty", "Login Failed");
    }
    else {
      let loginModel: LoginRequest = {
        username: username,
        password: password
      };

      const loginEndPoint = environment.apiUrl + "/api/Merchants/account/login";

      this.http.post(loginEndPoint, loginModel, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((d: any) => {
        if (d == null) {
          this.cookieService.delete("username");
          this.cookieService.set("username", username);
          this.toastr.success("Welcome back", "Login Successful");
          this.router.navigateByUrl("/dashboard");
        }
        else if (d.err) {
          this.toastr.error("Either username or password is incorrect", "Login Failed");
        }

      });



    }

  }

}
