<div class="page">
  <!-- main-sidebar -->
  <div class="app-sidebar__overlay z0" data-toggle="sidebar"></div>
  <app-sidebar></app-sidebar>
  <!-- main-sidebar -->
  <!-- main-content -->
  <div class="main-content app-content">
    <!-- main-header -->
    <app-header></app-header>
    <!-- /main-header -->
    <!-- container -->
    <div class="container-fluid">
      <!-- breadcrumb -->
      <div class="breadcrumb-header justify-content-between">
        <div class="my-auto">
          <div class="d-flex">
            <h4 class="content-title mb-0 my-auto">Home</h4>
            <span class="text-muted mt-1 tx-13 ml-2 mb-0">/ Albums</span>
          </div>
        </div>
      </div>
      <!-- breadcrumb -->
      <div class="row">
        <div class="col-md-12">
          <div class="card mg-b-20">
            <div class="card-body">
              <div class="main-content-label mg-b-5">
                Add Albums
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- row -->
      <!-- row closed -->
      <div class="row p-3">


        <div class="col-md-6">
          <input class="form-control bs" placeholder="Add new Album" type="text" [(ngModel)]="albumName" />
        </div>

        <div class="col-md-6">
          <button class="btn btn-primary btn-block" [disabled]="albumName.length <= 0" (click)="onAddClick()">
            Add
          </button>
        </div>
      </div>
      <!-- Container closed -->
    </div>
    <!-- main-content closed -->
    <!-- Footer opened -->

    <!-- Footer closed -->
  </div>

  <app-footer></app-footer>
</div>