<div class="page">
    <!-- main-sidebar -->
    <div class="app-sidebar__overlay" data-toggle="sidebar"></div>
    <app-sidebar></app-sidebar>
    <!-- main-sidebar -->
    <!-- main-content -->
    <div class="main-content app-content">
      <!-- main-header -->
      <app-header></app-header>
      <!-- /main-header -->
      <!-- container -->
      <div class="container-fluid topabsolute">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
          <div class="my-auto">
            <div class="d-flex">
              <h4 class="content-title mb-0 my-auto">Settings</h4>
              <span class="text-muted mt-1 tx-13 ml-2 mb-0">/ Map Domain</span>
            </div>
          </div>
        </div>
        <!-- breadcrumb -->
        <div class="row row-sm">

            <div class="col-md-8">
                <div class="card  box-shadow-0 h-100">
                    <div class="card-header">
                        <h4 class="card-title mb-1">Map Domain</h4>
                    </div>
                    <!-- <h4 class="card-title mb-1 ml-5">
                      Check Domain Availability
                    </h4>

                    <div class="ml-5 mb-2">
                      <input type="text" (input)="onInputChange($event.target.value)"/>
                      <select [(ngModel)]="selectedTlds">
                        <option value=".com">.com</option>
                        <option value=".in">.in</option>
                        <option value=".org">.org</option>
                        <option value=".co.in">.co.in</option>
                        <option value=".info">.info</option>
                      </select>
                      <button type="button" class="btn-primary ml-1" (click)="checkAvailability()">Check</button>
                      <button type="button" [ngClass]="isDomainAvailable ? 'btn-primary ml-1' : 'btn-secondary m-1'" [disabled]="!isDomainAvailable" (click)="registerDomain()">Register Domain</button>
                    </div>
                      <div class="ml-5">
                        <h6>Note:</h6>
                        <p>1. Once domain registered, cannot be changed for 1 year tenure.</p>
                        <p>2. It will take 2 to 4 hrs to reflect changes to your new domain.</p>
                      </div> -->

                    <div class="card-body pt-0" *ngIf="!isDomainMapped">
                        <form [formGroup]="mapDomainForm" (ngSubmit)="mapDomain()" (keyup.enter)="mapDomain()">
                            <div class="">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Domain</label>
                                    <input formControlName="domainName" type="text" class="form-control" placeholder="e.g., ewns.in">
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Sub Domain</label>
                                    <input formControlName="subDomain" type="text" class="form-control" placeholder="e.g., democlinic, final url will be democlinic.ewns.in">
                                </div>
                            </div>
                            <button [disabled]="!buttonEnabled" type="submit" class="btn btn-primary mt-3 mb-0">Submit</button>
                        </form>


                    </div>

                    <div class="card-body" *ngIf="isDomainMapped">
                        <div class="listgroup-example ">
                            <ul class="list-group">
                                <li>1. Add <b>A or Alias </b>record as either of three values mentioned below
                                    <ul class="list-style-disc">
                                      <li>13.235.107.62 </li>
                                      <li>13.232.155.23</li>
                                      <li>65.2.0.173</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <p class="mg-b-20">2. If you want your www subdomain to point to your main website, you need to add domain forwarding to <b>https://{{this.domain}} </b>as mentioned here <a href="https://in.godaddy.com/help/forward-my-domain-12123" title="Domain Forwarding" target="_blank">https://in.godaddy.com/help/forward-my-domain-12123</a> </p>
                        <div class="embed-responsive embed-responsive-21by9">
                            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/ZRyhhDJgGwA"></iframe>
                          </div>
                        <br/>
                        <p class="mg-b-20">3. Drop an email to us at <a href="mailto:sales@ewns.in" title="Contact Us">sales@ewns.in</a> or call us at <a href="tel:+919032526393" title="Call Us">+91 90325 26393</a></p>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <!-- main-content closed -->
      <!-- Footer opened -->

      <!-- Footer closed -->
    </div>

  </div>



