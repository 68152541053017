import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { mapDomainRequest } from 'src/app/core/models/domain/domain.models';
import { DomainServiceService } from 'src/app/core/services/domain/domain-service.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';

@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.css']
})
export class DomainComponent implements OnInit {


  isDomainMapped = false;
  buttonEnabled = true;
  public domain = "";

  public mapDomainForm: FormGroup;
  public selectedTlds: string = ".com";
  public username: string = this.cookieService.get("username");
  public isDomainAvailable: boolean = false;

  constructor(private cookieService: CookieService,
    private router: Router,
    private merchantService: MerchantService,
    private titleService: Title,
    private toastr: ToastrService,
    private domainService: DomainServiceService) {

    this.mapDomainForm = new FormGroup({
      domainName: new FormControl(null, Validators.required),
      subDomain: new FormControl(null, Validators.required)
    });
  }

  async ngOnInit(): Promise<void> {

    let username = this.cookieService.get("username");
    if (username == "") {
      this.router.navigateByUrl("/");
    }
    let merchantData = null;
    if (!this.merchantService.getMerchantData()) {
      this.merchantService.getMechangeDetails();
      merchantData = this.merchantService.getMerchantData();
    }

    this.titleService.setTitle("Map domain | Content Management Portal ");

    this.domainService.getDomainInfo().then((data) => {
      this.isDomainMapped = true;
    })
      .catch((error) => {
        if (error.status == 200) {
          this.isDomainMapped = true;
          this.domain = error.error.text;
        }
      });

  }

  onInputChange(searchValue: string): void {
    this.isDomainAvailable = false;
    this.domain = searchValue;
    console.log(this.domain, this.selectedTlds)
  }

  async checkAvailability() {
    this.domainService.isDomainAvailable(this.username, this.domain + this.selectedTlds).then(data => {
      this.isDomainAvailable = data.iaAvailable;
    })
  }

  async registerDomain() {

  }


  mapDomain() {
    this.buttonEnabled = false;

    const { domainName, subDomain } = this.mapDomainForm.value;
    if (domainName == null || domainName == "" || domainName == undefined) {
      this.toastr.error("Domain name cannot be empty");
    }
    else {
      this.toastr.info("Please do not reload the page as this process might take sometime, we will notify when domain mapping is done", "Domain Mapping");

      let domainRequest: mapDomainRequest = {
        username: this.cookieService.get("username"),
        domainName: domainName,
        subDomainName: subDomain
      };
      this.domainService.mapDomain(domainRequest).then((data) => {
        this.buttonEnabled = true;
        this.isDomainMapped = true;
      })
        .catch((error) => {
          this.toastr.error(error.error);
          this.buttonEnabled = true;
        });;
    }
  }




}
