import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddAlbumComponent } from './components/albums/add-album/add-album.component';
import { AlbumsComponent } from './components/albums/albums.component';
import { EditAlbumComponent } from './components/albums/edit-album/edit-album.component';
import { ViewAlbumComponent } from './components/albums/view-album/view-album.component';
import { AddBlogComponent } from './components/blog/add-blog/add-blog.component';
import { BlogComponent } from './components/blog/blog.component';
import { EditBlogComponent } from './components/blog/edit-blog/edit-blog.component';
import { AddCategoryComponent } from './components/categories/add-category/add-category.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { EditCategoryComponent } from './components/categories/edit-category/edit-category.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DomainComponent } from './components/domain/domain.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { MessagesComponent } from './components/messages/messages.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AddProductComponent } from './components/products/add-product/add-product.component';
import { EditProductComponent } from './components/products/edit-product/edit-product.component';
import { ProductsComponent } from './components/products/products.component';
import { ViewProductsComponent } from './components/products/view-products/view-products.component';
import { EditProfileComponent } from './components/profile/edit-profile/edit-profile.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ThemeColorComponent } from './components/profile/theme-color/theme-color.component';
import { AddServiceComponent } from './components/services/add-service/add-service.component';
import { EditServiceComponent } from './components/services/edit-service/edit-service.component';
import { ServicesComponent } from './components/services/services.component';
import { ViewServicesComponent } from './components/services/view-services/view-services.component';
import { ChangePasswordComponent } from './components/settings/change-password/change-password.component';
import { SettingsComponent } from './components/settings/settings.component';
import { SigninComponent } from './components/signin/signin.component';
import { SignoutComponent } from './components/signout/signout.component';
import { SignupComponent } from './components/signup/signup.component';
import { AddTestimonyComponent } from './components/testimony/add-testimony/add-testimony.component';
import { TestimonyComponent } from './components/testimony/testimony.component';
import { UpdateTestimonyComponent } from './components/testimony/update-testimony/update-testimony.component';
import { AuthguardService } from './core/services/authguard/authguard.service';
import { NewSigninComponent } from './components/new/signin/signin.component';

const routes: Routes = [{
  path: '',
  canActivateChild: [AuthguardService],
  children:
    [
      { path: 'forgotPassword', component: ForgotPasswordComponent },
      { path: 'signup', component: SignupComponent },

      { path: 'dashboard', component: DashboardComponent },

      { path: 'logout', component: SignoutComponent },

      { path: 'settings/profile', component: SettingsComponent },
      { path: 'settings/changePassword', component: ChangePasswordComponent },


      { path: 'messages/view', component: MessagesComponent },

      { path: 'profile/view', component: ProfileComponent },
      { path: 'profile/edit', component: EditProfileComponent },
      { path: 'profile/theme', component: ThemeColorComponent },

      { path: 'products/view/:index', component: ViewProductsComponent },
      { path: 'products/edit/:id', component: EditProductComponent },
      { path: 'products/add', component: AddProductComponent },

      { path: 'testimony', component: TestimonyComponent },
      { path: 'testimony/edit/:id', component: UpdateTestimonyComponent },
      { path: 'testimony/add', component: AddTestimonyComponent },

      { path: 'services/view/:index', component: ViewServicesComponent },
      { path: 'services/edit/:id', component: EditServiceComponent },
      { path: 'services/add', component: AddServiceComponent },

      { path: 'blog/view/:index', component: BlogComponent },
      { path: 'blog/edit/:id', component: EditBlogComponent },
      { path: 'blog/add', component: AddBlogComponent },

      { path: 'categories/view', component: CategoriesComponent },
      { path: 'category/add', component: AddCategoryComponent },
      { path: 'category/edit/:id', component: EditCategoryComponent },

      { path: 'albums', component: AlbumsComponent },
      { path: 'albums/view/:id', component: ViewAlbumComponent },
      { path: 'albums/edit/:id', component: EditAlbumComponent },
      { path: 'albums/add', component: AddAlbumComponent },

      { path: 'settings/domain', component: DomainComponent },


      { path: '404', component: NotFoundComponent },
      { path: '', component: SigninComponent },
      //{ path: '', component: NewSigninComponent },
    ]
},
{
  path: '**', redirectTo: '/404'
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
