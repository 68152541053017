import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { imageUploadRequest } from '../../models/albums/album.models';
import { product } from '../../models/product/product.models';
import { serviceRequest } from '../../models/service/service.models';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  count: number | any;
  nextLink: string | any;
  prevLink: string | any;
  totalCount: number | any;
  products : Array<product> | any;
  productInfo : product | any;
  constructor(private http: HttpClient, private cookieService: CookieService) { }

  addProductInfo(productItem: serviceRequest): Observable<any>{
    let addProductEndPoint =  environment.apiUrl + '/api/Products';
    return this.http.post<any>(addProductEndPoint, productItem, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    });
  }

  getProductInfoByIdPromise(productId: string): Promise<product>{
    let productInfoEndPoint =  environment.apiUrl + '/api/Products/'+ productId;
    return this.http.get<product>(productInfoEndPoint).toPromise();
  }

  archiveProduct(productId: string): Observable<any>{
    let productInfoEndPoint =  environment.apiUrl + '/api/Products/'+ productId;
    return this.http.delete<any>(productInfoEndPoint);
  }

  updateProduct(productId: string, productItem: product): Observable<any>{
    let updateProductInfoEndPoint =  environment.apiUrl + '/api/Products/'+ productId;
    return this.http.put<any>(updateProductInfoEndPoint, productItem, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    });
  }

  archiveProductImage(serviceId: string, imageUrl: string): Observable<any>{
    let productInfoEndPoint =  environment.apiUrl + '/api/Products/image/archive/'+ serviceId;
  
    let requestBody: imageUploadRequest = {
      image : imageUrl
    };

    return this.http.put<any>(productInfoEndPoint, requestBody, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    });
  }

  getProducts(skip: number, limit: number){
    let username = this.cookieService.get("username");
    if(username && username !== 'undefined')
    {
      const productsListEndPoint =  environment.apiUrl + '/api/Products/'+ username +"/list?skip="+skip+"&limit="+limit;
      this.http.get<product[]>(productsListEndPoint).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((data : product[] |any) => {
        this.products = [...data];
        this.count = this.products.length;
        this.pagination(skip + 1);
      });
    }

  }

  getProductInfo(index: number)
  {
    let username = this.cookieService.get("username");
    if(username && username !== 'undefined')
    {
      const productDetailsEndPoint =  environment.apiUrl + '/api/Products/'+ username +"/"+ index;
      this.http.get<product[]>(productDetailsEndPoint).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((data : product |any) => {
        this.productInfo = data;
      });
    }

    
  }


  getTotalProducts()
  {
    let username = this.cookieService.get("username");
    if(username && username !== 'undefined')
    {
      const totalProductsEndPoint =  environment.apiUrl + '/api/Products/'+ username +"/count";
      this.http.get<number>(totalProductsEndPoint).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((data : number |any) => {
        this.totalCount = data;
      });
    }
  }

  pagination(index: number) {
    this.nextLink = "/products/view/" + index;
    this.prevLink = "/products/view/" + (index - 1);
    if (index <= 1) {
      this.prevLink = "/products/view/1";
      if (this.count < 9) {
        this.nextLink = "/products/view/1";
      }
      else {
        this.nextLink = "/products/view/" + (index + 1);
      }
    }
    else {
      this.prevLink = "/products/view/" + (index - 1);
      if (this.count < 9) {
        this.nextLink = "/products/view/" + (index);
      }
      else {
        this.nextLink = "/products/view/" + (index + 1);
      }
    }

  }
}
