import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { category } from 'src/app/core/models/category/category.models';
import { CategoryService } from 'src/app/core/services/categories/category.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent implements OnInit {

  categorySubcategoryData = {
    "root1": [
      "cat1", "cat2"
    ],
    "root2": ["cat3", "cat4", "cat5"],
  } as any;

  keyword = 'name';
  dataForCats: category[] = [];
  dataForSubCats: category[] = [];
  selectedCat: any;
  selectedSubCat: any;
  newName = "";
  disabledSubCats = true;
  selectSubCatText = "Please select category first";

  categories: category[] = [];

  @ViewChild('subcats') subcatselm: any;
  selectEvent(item: any) {
    this.selectedSubCat = item.name;
  }


  parentCats: string[] = [];
  constructor(public merchantService: MerchantService,
    private titleService: Title,
    private categoryService: CategoryService,
    private toastr: ToastrService,
    private router: Router,
    private cookieService: CookieService
  ) { }

  async getParentCategories(): Promise<void> {
   let categories =  await this.categoryService.getCategoriesObservable();
   this.dataForCats = categories;
  }
  getSubcategories(key: string): Array<category> {
    let item =  this.dataForCats.find(s=> s._id == key)?.childCategories;
    this.dataForSubCats = item;
    return item;
  }
  ngOnInit(): void {
    
    let username = this.cookieService.get("username");
    if (username == "") {
      this.router.navigateByUrl("/");
    }
    if (!this.merchantService.getMerchantData()) {
      this.merchantService.getMechangeDetails();
    }
    this.getParentCategories();
    this.titleService.setTitle("Add Category | CMS ");
  }


  onSelectParentCategory(evt: any): void {
    this.selectSubCatText = "Please Select SubCategory";
    this.disabledSubCats = false;
    let item =  this.dataForCats.find(s=> s._id == evt._id)?.childCategories;
    this.dataForSubCats = item;
    this.getSubcategories(evt._id);
  }

  onClearParent(): void {
    this.subcatselm.close();
    this.subcatselm.clear();
    this.selectSubCatText = "Please select category first";
    this.disabledSubCats = true;
    this.dataForSubCats = new Array();
  }

  async onAddClick(): Promise<void> {
    if(this.newName == null || this.newName == "")
    {
      this.toastr.error("Category name cannot be empty");
    }
    else
    {
      let parentCategory = this.selectedSubCat? this.selectedSubCat?.name : this.selectedCat?.name;
      let parentId = this.selectedSubCat? this.selectedSubCat?._id : this.selectedCat?._id;
      let result = await this.categoryService.addCategoryObservable(this.newName, parentCategory, parentId);
      this.newName = "";
      this.onClearParent();
      this.getParentCategories();
      this.toastr.success("Category added successfully");

    }
  }
}
