<div class="page">
  <!-- main-sidebar -->
  <div class="app-sidebar__overlay" data-toggle="sidebar"></div>
  <app-sidebar></app-sidebar>
  <!-- main-sidebar -->
  <!-- main-content -->
  <div class="main-content app-content">
    <!-- main-header -->
    <app-header></app-header>
    <!-- /main-header -->
    <!-- container -->
    <div class="container-fluid topabsolute">
      <!-- breadcrumb -->
      <div class="breadcrumb-header justify-content-between">
        <div class="my-auto">
          <div class="d-flex">
            <h4 class="content-title mb-0 my-auto">Product</h4>
            <span class="text-muted mt-1 tx-13 ml-2 mb-0">/ Update</span>
          </div>
        </div>
      </div>
      <!-- breadcrumb -->
      <div class="row">
        <div class="col-md-10">
          <div class="card mg-b-20">
            <div class="card-body">
              <div class="row row-sm">
                <div class="col-md-12 col-sm-12">
                  <div class="card box-shadow-0">
                    <div class="card-header">
                      <h4 class="card-title mb-1">Update Product</h4>
                    </div>
                    <div class="card-body pt-0">
                      <div class="row">
                        <div
                          class="col-6 col-md-3"
                          *ngFor="
                            let imageItem of this.serviceItem
                              ?.secondaryImageModel
                          "
                        >
                          <img
                            alt="this.serviceItem?.name"
                            class="img-thumbnail"
                            src="{{ imageItem.small }}"
                          />
                          <a
                            (click)="archiveImage(imageItem)"
                            style="
                              align-items: center;
                              position: absolute;
                              top: 3%;
                              left: 90%;
                            "
                            title="Archive"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </a>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <br />
                            <label> Upload File </label>
                            <input
                              type="file"
                              (change)="productImageChange($event)"
                              class="dropify height-wrapper"
                              accept=".png, .jpg, .jpeg"
                              data-allowed-file-extensions="jpg png jpeg"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group">
                              <label> Name </label>
                              <input
                                type="text"
                                class="form-control"
                                value="{{ this.serviceItem?.name }}"
                                readonly
                              />
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Keywords</label>
                              <input
                                type="text"
                                class="form-control"
                                value="{{ this.serviceItem?.keywordList }}"
                                [(ngModel)]="keywords"
                              />
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label> Short Description </label>
                              <textarea
                                class="form-control"
                                placeholder="Short Description"
                                rows="6"
                                value="{{ this.serviceItem?.shortBio }}"
                                [(ngModel)]="shortBio"
                              ></textarea>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Buy/Book Online</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Buy/Book Online"
                                value="{{ this.serviceItem?.buyOnlineLink }}"
                                [(ngModel)]="bookOnline"
                              />
                            </div>
                          </div>

                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Description</label>
                              <app-wysiwyg
                                *ngIf="this.serviceItem?.description"
                                [configObj]="cofigObj"
                                [uploadUrl]="apiPath"
                                [value]="this.serviceItem?.description"
                                [pressedSubmit]="submit"
                                (getData)="updateDescription($event)"
                              ></app-wysiwyg>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Brand</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Brand"
                                value="{{ this.serviceItem?.brand }}"
                                [(ngModel)]="brand"
                              />
                            </div>
                          </div>

                          <div class="col-md-8">
                            <div class="form-group" *ngIf="this.categories">
                              <!-- <label>Category</label>
                                <select [(ngModel)]="category" class="form-control">
                                  <option *ngFor="let catItem of this.allCategories" ng-selected="this.serviceItem?.categoryId == catItem._id" [ngValue]="catItem._id">
                                    <span *ngIf="catItem.isParent">{{catItem.name}}</span>
                                    <span *ngIf="!catItem.isParent">{{catItem.parentCategory}} > {{catItem.name}}</span>
                                  </option>
                                </select> -->
                              <app-category-helper
                                (selectChildCatData)="updateChildCatData($event)"
                                (selectedParentData)="updateParentCatData($event)"
                                [defaultSelected]="defaultCatId"
                                [categories]="categories"
                                [parentName]="'Product Category'"
                                [childName]="'Sub Category'"
                              ></app-category-helper>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Price</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Price per Item"
                                value="{{ this.serviceItem?.price }}"
                                [(ngModel)]="price"
                              />
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Discount (%)</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Discount in %"
                                value="{{ this.serviceItem?.discount }}"
                                [(ngModel)]="discount"
                              />
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Warranty</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Warranty"
                                value="{{ this.serviceItem?.warranty }}"
                                [(ngModel)]="warranty"
                              />
                            </div>
                          </div>
                        </div>

                        <div class="text-center mg-b-20" *ngIf="loaderEnabled">
                          <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>

                        <div class="form-group mb-0 mt-3 justify-content-end">
                          <div>
                            <button
                              type="submit"
                              class="btn btn-primary"
                              style="float: right"
                              (click)="updateProduct()"
                            >
                              UPDATE
                            </button>
                            <button
                              type="submit"
                              class="btn btn-secondary"
                              style="float: left"
                              (click)="archiveProduct()"
                            >
                              ARCHIVE
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- row -->
        <!-- row closed -->
      </div>
      <!-- Container closed -->
    </div>
    <!-- main-content closed -->
    <!-- Footer opened -->

    <!-- Footer closed -->
  </div>
</div>
