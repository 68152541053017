import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { JWTModel } from '../../models/jwt/jwt.models';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class JwtService {
  jwtToken: JWTModel | any;
  accessToken !: string;
  constructor(private authService: AuthenticationService, private cookieService: CookieService) { }

  setToken(token: JWTModel): void {
    this.jwtToken = token;
    if (token) {
      this.accessToken = token.access_token;
      var date = new Date();
      date.setTime(date.getTime() + (60 * 1000 * 10));
      this.cookieService.set('jwt', this.accessToken, date);
    }
  }

  getAccessToken(): string {
    return 'Bearer ' + this.cookieService.get('jwt');
  }

  resetToken(): void {
    this.jwtToken = undefined;
    localStorage.clear();
    this.cookieService.deleteAll();
  }

  isJwtValid(): boolean {
    const storedJWT = this.cookieService.get('jwt');
    if (storedJWT && storedJWT !== 'undefined') {
      return true;
    }
    return false;
  }


  authenticateObserver(): Observable<JWTModel> {
    return this.authService.login();
  }
}
