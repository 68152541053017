import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SigninComponent } from './components/signin/signin.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SignupComponent } from './components/signup/signup.component';
import { RequestInterceptorService } from './core/services/interceptor/request-interceptor.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SignoutComponent } from './components/signout/signout.component';
import { ProfileComponent } from './components/profile/profile.component';
import { EditProfileComponent } from './components/profile/edit-profile/edit-profile.component';
import { MessagesComponent } from './components/messages/messages.component';
import { SettingsComponent } from './components/settings/settings.component';
import { FooterComponent } from './components/footer/footer.component';
import { HtmlEditorComponent } from './components/html-editor/html-editor.component';
import { MainPipe } from './core/helpers/mainPipe.module';
import { ProductsComponent } from './components/products/products.component';
import { EditProductComponent } from './components/products/edit-product/edit-product.component';
import { AddProductComponent } from './components/products/add-product/add-product.component';
import { ServicesComponent } from './components/services/services.component';
import { AddServiceComponent } from './components/services/add-service/add-service.component';
import { EditServiceComponent } from './components/services/edit-service/edit-service.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { AddCategoryComponent } from './components/categories/add-category/add-category.component';
import { EditCategoryComponent } from './components/categories/edit-category/edit-category.component';
import { AlbumsComponent } from './components/albums/albums.component';
import { AddAlbumComponent } from './components/albums/add-album/add-album.component';
import { EditAlbumComponent } from './components/albums/edit-album/edit-album.component';
import { ViewAlbumComponent } from './components/albums/view-album/view-album.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { OffersComponent } from './components/offers/offers.component';
import { AddOfferComponent } from './components/offers/add-offer/add-offer.component';
import { EditOfferComponent } from './components/offers/edit-offer/edit-offer.component';
import { BlogComponent } from './components/blog/blog.component';
import { AddBlogComponent } from './components/blog/add-blog/add-blog.component';
import { EditBlogComponent } from './components/blog/edit-blog/edit-blog.component';
import { OrdersComponent } from './components/orders/orders.component';
import { OrderDetailsComponent } from './components/orders/order-details/order-details.component';
import { InvoiceComponent } from './components/orders/invoice/invoice.component';
import { ViewProductsComponent } from './components/products/view-products/view-products.component';
import { ViewServicesComponent } from './components/services/view-services/view-services.component';
import { JoditAngularModule } from 'jodit-angular';
import { WysiwygComponent } from './components/wysiwyg/wysiwyg.component';
import { MatTreeModule } from '@angular/material/tree';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ChangePasswordComponent } from './components/settings/change-password/change-password.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { DomainComponent } from './components/domain/domain.component';
import { ThemeColorComponent } from './components/profile/theme-color/theme-color.component';
import { TestimonyComponent } from './components/testimony/testimony.component';
import { AddTestimonyComponent } from './components/testimony/add-testimony/add-testimony.component';
import { UpdateTestimonyComponent } from './components/testimony/update-testimony/update-testimony.component';
import { CategoryHelperComponent } from './components/category-helper/category-helper.component';
import { NewSigninComponent } from './components/new/signin/signin.component';

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    NewSigninComponent,
    ForgotPasswordComponent,
    SignupComponent,
    DashboardComponent,
    HeaderComponent,
    SidebarComponent,
    SignoutComponent,
    ProfileComponent,
    EditProfileComponent,
    MessagesComponent,
    SettingsComponent,
    FooterComponent,
    HtmlEditorComponent,
    ProductsComponent,
    EditProductComponent,
    AddProductComponent,
    ServicesComponent,
    AddServiceComponent,
    EditServiceComponent,
    CategoriesComponent,
    AddCategoryComponent,
    EditCategoryComponent,
    AlbumsComponent,
    AddAlbumComponent,
    EditAlbumComponent,
    ViewAlbumComponent,
    NotFoundComponent,
    OffersComponent,
    AddOfferComponent,
    EditOfferComponent,
    BlogComponent,
    AddBlogComponent,
    EditBlogComponent,
    OrdersComponent,
    OrderDetailsComponent,
    InvoiceComponent,
    ViewProductsComponent,
    ViewServicesComponent,
    WysiwygComponent,
    ChangePasswordComponent,
    DomainComponent,
    ThemeColorComponent,
    TestimonyComponent,
    AddTestimonyComponent,
    UpdateTestimonyComponent,
    CategoryHelperComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    JoditAngularModule,
    AutocompleteLibModule,
    FormsModule,
    MainPipe,
    BrowserAnimationsModule,
    TreeModule,
    ReactiveFormsModule,
    MatTreeModule,
    ToastrModule.forRoot()
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: RequestInterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
