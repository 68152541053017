import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JwtService } from '../jwt/jwt.service';

@Injectable({
  providedIn: 'root'
})
export class RequestInterceptorService {

  constructor(private jwtservice: JwtService) { }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('connect')) {
      return next.handle(request);
    }
    const authReq = request.clone({
      headers: request.headers.set('Authorization',this.jwtservice.getAccessToken())
    });
    return next.handle(authReq).pipe(tap(event => {
      if (event instanceof HttpResponse) {
        if (event.status === 401) {
          this.jwtservice.resetToken();
        }
      }
    }));
  }
}
