import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { Merchant } from 'src/app/core/models/merchant/merchant.models';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  buttonEnabled: boolean = true;
  public passwdChangeForm : FormGroup;
  constructor(private cookieService: CookieService,
    private merchantService: MerchantService,
    private router: Router,
    private toastr: ToastrService,
    private titleService: Title) {

      this.passwdChangeForm = new FormGroup({
        oldPassword: new FormControl(null, Validators.required),
        password: new FormControl(null, Validators.required),
        confirmPassword: new FormControl(null, Validators.required)
      });
     }

   ngOnInit() {
    let username = this.cookieService.get("username");
    if(username == "")
    {
      this.router.navigateByUrl("/");
    }
    if(!this.merchantService.getMerchantData())
    {
      this.merchantService.getMechangeDetails();
    }
    this.titleService.setTitle("Change Password | Content Management Portal ");
  }


  changePassword(): void{

    this.buttonEnabled = false;
    const {oldPassword, password, confirmPassword} = this.passwdChangeForm.value;
    if(oldPassword == null || oldPassword == "")
    {
      this.toastr.error("Current Password cannot be empty", "Failed");
    }
    else if(password == null || password == "")
    {
      this.toastr.error("New Password cannot be empty", "Failed");
    }
    else if(password.length < 8 || password.length > 16 )
    {
      this.toastr.error("Password should have min of 8  & max of 16 characters");
    }
    else if(password != confirmPassword)
    {
      this.toastr.error("Passwords mismatch");

    }
    else{
      
      this.merchantService.changePassword(oldPassword, password).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((d: any) => {
        if(d == null)
        {
          this.toastr.success("Password changed successfully");
        }
        else if (d.err) {
          this.toastr.error("Invalid password")
        }
        else{
          this.toastr.success("Password changed successfully");
        }

        this.buttonEnabled = true;
      })
    }

  }

}
