import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { testimonial } from 'src/app/core/models/customer/testimonials.models';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { TestimonyService } from 'src/app/core/services/testimonials/testimony.service';

@Component({
  selector: 'app-testimony',
  templateUrl: './testimony.component.html',
  styleUrls: ['./testimony.component.css']
})
export class TestimonyComponent implements OnInit {

  
  loaderEnabled = true;
  testimonials: Array<testimonial> = [];
  constructor(private cookieService: CookieService,
    private titleService: Title,
    private router: Router,
    private testimonialService: TestimonyService,
    private merchantService: MerchantService) { }

  async ngOnInit(): Promise<void> {
    let username = this.cookieService.get("username");
    if (username == "") {
      this.router.navigateByUrl("/");
    }
    if (!this.merchantService.getMerchantData()) {
      this.merchantService.getMechangeDetails();
    }

    this.titleService.setTitle("View Testimonials | Content Management Portal ");
    this.testimonials = await this.testimonialService.getAllTestimonials();
    this.loaderEnabled = false;

  }

}
