<div id="global-loader" *ngIf="loaderEnabled">
    <img src="https://ewnsassets.s3.ap-south-1.amazonaws.com/cms/assets/img/loader.svg" class="loader-img" alt="Loader">
  </div>
<div class="page">
    <!-- main-sidebar -->
    <div class="app-sidebar__overlay" data-toggle="sidebar"></div>
    <app-sidebar></app-sidebar>
    <!-- main-sidebar -->
    <!-- main-content -->
    <div class="main-content app-content">
      <!-- main-header -->
      <app-header></app-header>
      <!-- /main-header -->
      <!-- container -->
      <div class="container-fluid topabsolute">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
          <div class="my-auto">
            <div class="d-flex">
              <h4 class="content-title mb-0 my-auto">Testimony</h4>
              <span class="text-muted mt-1 tx-13 ml-2 mb-0">/ Add</span>
            </div>
          </div>
        </div>
        <!-- breadcrumb -->
        <div class="row">
          <div class="col-md-10">
            <div class="card mg-b-20">
              <div class="card-body">
                <div class="row row-sm">
                  <div class="col-md-12 col-sm-12">
                    <div class="card box-shadow-0">
                      <div class="card-header">
                        <h4 class="card-title mb-1">Add Customer Testimony</h4>
                      </div>
                      <div class="card-body pt-0">
                        <div class="row">
                          <div class="col-md-12">
                            <input
                              type="file"
                              (change)="testimonyImageChange($event)"
                              class="dropify height-wrapper"
                              accept=".png, .jpg, .jpeg"
                              data-allowed-file-extensions="jpg png jpeg"
                            />
                            <br/>
                          </div>
                          
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label> Title </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Title"
                                [(ngModel)]="title"
                              />
                            </div>
                          </div>
  
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Description</label>
                              <textarea 
                              class="form-control"
                              placeholder="Description"
                              rows="6"
                              [(ngModel)]="description"
                            ></textarea>
                            </div>
                          </div>
  
                          <div class="col-md-6">
                            <div class="form-group">
                              <label>Name</label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Name"
                                [(ngModel)]="name"
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group">
                              <label for="exampleInputEmail1">Ratings</label>
                              <input
                                type="number"
                                class="form-control" min="1" max="5"
                                [(ngModel)]="rating"
                              />
                            </div>
                          </div>
                        </div>
  
                        <div class="text-center mg-b-20" *ngIf="loaderEnabled">
                          <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
  
                        <div class="row">
                          <div class="col-md-4"></div>
                          <div class="col-md-4">
                            <button
                              type="submit"
                              class="btn btn-primary"
                              style="float: right; width: 100%"
                              (click)="addTestimony()"
                            >
                              ADD TESTIMONY
                            </button>
                          </div>
                          <div class="col-md-4"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- row -->
        <!-- row closed -->
      </div>
      <!-- Container closed -->
    </div>
    <!-- main-content closed -->

  </div>