import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { ProductService } from 'src/app/core/services/products/product.service';

@Component({
  selector: 'app-view-products',
  templateUrl: './view-products.component.html',
  styleUrls: ['./view-products.component.css']
})
export class ViewProductsComponent implements OnInit {
  constructor(public productService: ProductService,
    private cookieService: CookieService,
    private router: Router,
    private merchantService: MerchantService,
    private route: ActivatedRoute,
    private titleService: Title) { 
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  ngOnInit(): void {
    let username = this.cookieService.get("username");
    if(username == "")
    {
      this.router.navigateByUrl("/");
    }
    if(!this.merchantService.getMerchantData())
    {
      this.merchantService.getMechangeDetails();
    }

    const routeParams = this.route.snapshot.paramMap;
    const index = Number(routeParams.get('index'));
    
    this.titleService.setTitle("View all products | CMS ");
    this.productService.getProducts((index-1), 9);
  }


}
