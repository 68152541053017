import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { of, Subscription } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ImageSliderModel } from 'src/app/core/models/merchant/bgImage.models';
import { BizHours, MerchantDescriptionRequest, MerchantProfileRequest, SocialMediaHandles, updateAddressRequest, updateMediaRequest } from 'src/app/core/models/merchant/merchant.models';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit, AfterViewInit, OnDestroy {

  public apiPath = environment.apiUrl + "/api/Images/custom/";
  backgroundImageApi = environment.apiUrl + '/api/Images/background/'
  uploadLogoApi = environment.apiUrl + '/api/Images/logo/'
  uploadFeaturedImageApi = environment.apiUrl + '/api/Images/featured/'
  public cofigObj = {
    "uploader": {
      "insertImageAsBase64URI": true
    },
    "minHeight": 400,
    "minWidth": 400
  }
  public loaderEnabled: boolean = false;
  public profileDetailsForm: FormGroup;
  public profileDescriptionForm: FormGroup;
  public bizHoursForm: FormGroup;
  subscription = new Subscription();
  public addressForm: FormGroup;
  public socialForm: FormGroup;
  backgroundImageString: any | undefined;
  sliderImages: ImageSliderModel[] = [];
  sliderImage: any;
  public submit = false;
  @ViewChildren('imageholder') imageholder: any;
  constructor(public merchantService: MerchantService,
    private titleService: Title,
    private http: HttpClient,

    private router: Router,
    private toastr: ToastrService,
    private cookieService: CookieService) {

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.profileDetailsForm = new FormGroup({
      email: new FormControl(merchantService?.merchant?.email, Validators.required),
      countryCode: new FormControl(merchantService?.merchant?.countryCode, Validators.required),
      mobileNumber: new FormControl(merchantService?.merchant?.mobileNumber, Validators.required),
      bizname: new FormControl(merchantService?.merchant?.name, Validators.required),
    });


    this.profileDescriptionForm = new FormGroup({
      description: new FormControl(merchantService?.merchant?.description, Validators.required),
      shortBio: new FormControl(merchantService?.merchant?.shortBio, Validators.required),
      htmlinput: new FormControl(merchantService?.merchant?.description, Validators.required)
    });

    this.bizHoursForm = new FormGroup({
      sunday: new FormControl(merchantService?.merchant?.bizHours != null ? merchantService?.merchant?.bizHours.sunday : "", Validators.required),
      monday: new FormControl(merchantService?.merchant?.bizHours != null ? merchantService?.merchant?.bizHours.monday : "", Validators.required),
      tuesday: new FormControl(merchantService?.merchant?.bizHours != null ? merchantService?.merchant?.bizHours.tuesday : "", Validators.required),
      wednesday: new FormControl(merchantService?.merchant?.bizHours != null ? merchantService?.merchant?.bizHours.wednesday : "", Validators.required),
      thursday: new FormControl(merchantService?.merchant?.bizHours != null ? merchantService?.merchant?.bizHours.thursday : "", Validators.required),
      friday: new FormControl(merchantService?.merchant?.bizHours != null ? merchantService?.merchant?.bizHours.friday : "", Validators.required),
      saturday: new FormControl(merchantService?.merchant?.bizHours != null ? merchantService?.merchant?.bizHours.saturday : "", Validators.required)
    });

    this.addressForm = new FormGroup({
      address: new FormControl(merchantService?.merchant?.address, Validators.required),
      locality: new FormControl(merchantService?.merchant?.locality, Validators.required),
      city: new FormControl(merchantService?.merchant?.city, Validators.required),
      country: new FormControl(merchantService?.merchant?.country, Validators.required),
      pincode: new FormControl(merchantService?.merchant?.pincode, Validators.required),
      lat: new FormControl(merchantService?.merchant?.lat, Validators.required),
      lng: new FormControl(merchantService?.merchant?.lng, Validators.required)
    });


    this.socialForm = new FormGroup({
      fbUrl: new FormControl(merchantService?.merchant?.socialLinks?.fbUrl, Validators.required),
      fbPageUrl: new FormControl(merchantService?.merchant?.socialLinks?.fbPageUrl, Validators.required),
      twitterUsername: new FormControl(merchantService?.merchant?.socialLinks?.twitterUsername, Validators.required),
      instagram: new FormControl(merchantService?.merchant?.socialLinks?.instagram, Validators.required),
      pinterest: new FormControl(merchantService?.merchant?.socialLinks?.pinterest, Validators.required),
      linkedIn: new FormControl(merchantService?.merchant?.socialLinks?.linkedIn, Validators.required),
      gplus: new FormControl(merchantService?.merchant?.socialLinks?.gplus, Validators.required)
    });




  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  ngAfterViewInit(): void {
    this.subscription.add(this.imageholder.changes.subscribe((_data: any) => {
      const windowObj = window as any;
      windowObj.$('.dropify').dropify({});
      windowObj.$('.dropify2').dropify({});
      windowObj.$('.dropify3').dropify({});



      windowObj.$('.dropify-wrapper').height("126")

      windowObj.$('.favicon').dropify({});
      windowObj.$('.favicon-wrapper').height("126")
    }))
  }

  ngOnInit(): void {

    let username = this.cookieService.get("username");
    if (username == "") {
      this.router.navigateByUrl("/");
    }

    this.merchantService.getCountryList();

    if (!this.merchantService.getMerchantData()) {
      this.merchantService.getMechangeDetails();

    }
    this.getBackgroundImage();
    this.titleService.setTitle("Edit Profile | Content Management Portal ");


  }


  backgroundImagechange(data: any): void {
    let file = data.target.files[0];
    let reader = new FileReader();
    const me = this;
    this.loaderEnabled = true;
    reader.readAsDataURL(file);
    reader.onload = async function () {
      const imagedata = reader.result?.toString().split(',')[1];
      try {
        await me.uploadBackGroundImages(imagedata);
        me.loaderEnabled = false;
      } catch (error) {
        me.toastr.error("something went wrong");
      }
    };
    reader.onerror = function (error) {
      me.toastr.error("something went wrong " + error);
      console.log('Error: ', error);
    };
  }
  logoChange(data: any): void {
    let file = data.target.files[0];
    let reader = new FileReader();
    const me = this;
    this.loaderEnabled = true;
    reader.readAsDataURL(file);
    reader.onload = async function () {
      const imagedata = reader.result?.toString().split(',')[1];
      try {
        await me.uploadLogoImage(imagedata);
        me.loaderEnabled = false;
      } catch (error) {
        me.toastr.error("something went wrong");
      }
    };
    reader.onerror = function (error) {
      me.toastr.error("something went wrong " + error);
    };
  }


  featuredImageChange(data: any): void {
    let file = data.target.files[0];
    let reader = new FileReader();
    const me = this;
    this.loaderEnabled = true;
    reader.readAsDataURL(file);
    reader.onload = async function () {
      const imagedata = reader.result?.toString().split(',')[1];
      try {
        await me.uploadFeaturedImage(imagedata);
        me.loaderEnabled = false;
      } catch (error) {
        me.toastr.error("something went wrong");
      }
    };
    reader.onerror = function (error) {
      me.toastr.error("something went wrong " + error);
    };
  }

  async archiveBackgroundImage(item: any): Promise<void> {
    this.loaderEnabled = true;
    let items = item.image.split('large/');
    let imageId = items[1].split('.')[0];

    await this.archiveImage(imageId);

    this.loaderEnabled = false;
    window.location.reload();
  }

  archiveImage(imageId: string): Promise<any> {
    let usernameDetails = this.cookieService.get("username");

    let archiveBgEndPoint = environment.apiUrl + "/api/Images/background/" + usernameDetails + "/" + imageId;

    return this.http.delete(archiveBgEndPoint).toPromise();
  }

  favIcon(data: any): void {
    let file = data.target.files[0];
    let reader = new FileReader();
    const me = this;
    this.loaderEnabled = true;
    reader.readAsDataURL(file);
    reader.onload = async function () {
      const imagedata = reader.result?.toString().split(',')[1];
      try {
        me.loaderEnabled = false;
      } catch (error) {
        me.toastr.error("something went wrong");
      }
    };
    reader.onerror = function (error) {
      me.toastr.error("something went wrong " + error);
      console.log('Error: ', error);
    };
  }

  uploadLogoImage(data: any): Promise<any> {
    const headers = new HttpHeaders().set("Content-Length", data.length.toString())
    let usernameDetails = this.cookieService.get("username");

    return this.http.put(`${this.uploadLogoApi}${usernameDetails}`,
      { "image": data },
      { headers, responseType: 'text' }).toPromise();
  }

  uploadFeaturedImage(data: any): Promise<any> {
    const headers = new HttpHeaders().set("Content-Length", data.length.toString())
    let usernameDetails = this.cookieService.get("username");

    return this.http.put(`${this.uploadFeaturedImageApi}${usernameDetails}`,
      { "image": data },
      { headers, responseType: 'text' }).toPromise();
  }

  uploadBackGroundImages(data: any): Promise<any> {
    const headers = new HttpHeaders().set("Content-Length", data.length.toString())
    let usernameDetails = this.cookieService.get("username");

    return this.http.put(`${this.backgroundImageApi}${usernameDetails}`,
      { "image": data },
      { headers, responseType: 'text' }).toPromise();

  }
  async uploadBackGroundImagesHelper() {
    this.loaderEnabled = true;
    try {
      await this.uploadBackGroundImages(this.backgroundImageString);
      this.loaderEnabled = false;
      this.backgroundImageString = "";
      this.getBackgroundImage();

    } catch (error) {

    }

  }


  updateProfile(): void {
    this.loaderEnabled = true;
    const { email, bizname, mobileNumber, countryCode } = this.profileDetailsForm.value;

    if (bizname == null || bizname == "") {
      this.toastr.error("Business name cannot be empty");
      this.loaderEnabled = false;
    }
    else if (email == null || email == "") {
      this.toastr.error("Email cannot be empty");
      this.loaderEnabled = false;
    }
    else if (mobileNumber == null || mobileNumber == "") {
      this.toastr.error("Mobile Number cannot be empty");
      this.loaderEnabled = false;
    }
    else if (countryCode == null || countryCode == "") {
      this.toastr.error("country Code cannot be empty");
      this.loaderEnabled = false;
    }
    else {

      let usernameDetails = this.cookieService.get("username");

      let userDetails: MerchantProfileRequest = {
        countryCode: countryCode,
        email: email,
        mobileNumber: mobileNumber,
        name: bizname,
        username: usernameDetails
      };


      let updateBizProfileEndPoint = environment.apiUrl + "/api/merchants/" + usernameDetails;

      this.http.put(updateBizProfileEndPoint, userDetails, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((d: any) => {
        if (d == null) {
          this.toastr.success("Profile updated");
        }
        else if (d.err) {
          this.toastr.error("Unable to update profile now");
        }

        this.loaderEnabled = false;

      });

    }
  }


  desciptionSubmitted() {
    this.loaderEnabled = true;
    this.submit = true;
  }
  updateDescription(wysiwygdata: string) {
    this.submit = false; // so the user can submit editor data again
    const description = wysiwygdata;
    const { shortBio } = this.profileDescriptionForm.value;

    if (description == null || description == "") {
      this.toastr.error("Business Description cannot be empty");
      this.loaderEnabled = false;
    }
    else if (shortBio == null || shortBio == "") {
      this.toastr.error("Short Bio cannot be empty");
      this.loaderEnabled = false;
    }
    else {
      let usernameDetails = this.cookieService.get("username");

      let userDetails: MerchantDescriptionRequest = {
        shortBio: shortBio,
        description: description,
        username: usernameDetails
      };


      let updateBizProfileEndPoint = environment.apiUrl + "/api/merchants/" + usernameDetails;

      this.http.put(updateBizProfileEndPoint, userDetails, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).pipe(
        take(1),
        catchError(err => {

          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((d: any) => {

        if (d == null) {
          this.toastr.success("Profile updated");
        }
        else if (d.err) {
          this.toastr.error("Unable to update profile now");
        }

        this.loaderEnabled = false;

      });
    }





  }

  updateBizHours(): void {

    this.loaderEnabled = true;
    const { sunday, monday, tuesday, wednesday, thursday, friday, saturday } = this.bizHoursForm.value;
    let usernameDetails = this.cookieService.get("username");

    let bzHoursModel: BizHours = {
      sunday: sunday,
      monday: monday,
      tuesday: tuesday,
      wednesday: wednesday,
      thursday: thursday,
      friday: friday,
      saturday: saturday
    };

    let updateBizProfileEndPoint = environment.apiUrl + "/api/merchants/" + usernameDetails + "/bizHours";

    this.http.put(updateBizProfileEndPoint, bzHoursModel, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).pipe(
      take(1),
      catchError(err => {
        return of({ err: true, message: err.error.error_description })
      })
    ).subscribe((d: any) => {
      if (d == null) {
        this.toastr.success("Profile updated");
      }
      else if (d.err) {
        this.toastr.error("Unable to update profile now");
      }

      this.loaderEnabled = false;

    });
  }

  getBackgroundImage(): void {

    this.merchantService.getBackgroundImage().pipe(
      take(1)
    ).subscribe((backgroundImagesList: any) => {
      const newArray: ImageSliderModel[] = [];
      if (backgroundImagesList.length > 0) {
        backgroundImagesList.forEach((element: any) => {

          const sliderImage: ImageSliderModel = {
            alt: '',
            image: element.imageModel.large,
            thumbImage: element.imageModel.large,
            title: ''
          };
          newArray.push(sliderImage);
        });
        newArray.push({
          alt: '',
          image: '',
          thumbImage: '',
          title: ''
        })
        this.sliderImages = [...newArray];
      }
      else {
        newArray.push({
          alt: '',
          image: '',
          thumbImage: '',
          title: ''
        })
        this.sliderImages = [...newArray];
      }
    });

  }


  updateSocialMedia(): void {

    this.loaderEnabled = true;
    const { fbUrl, fbPageUrl, twitterUsername, instagram, pinterest, linkedIn, gplus } = this.socialForm.value;
    let usernameDetails = this.cookieService.get("username");

    let mediaObject: SocialMediaHandles = {
      fbPageUrl: fbPageUrl,
      fbUrl: fbUrl,
      twitterUsername: twitterUsername,
      instagram: instagram,
      pinterest: pinterest,
      linkedIn: linkedIn,
      gplus: gplus
    };

    let profileDetails: updateMediaRequest = {
      username: usernameDetails,
      socialLinks: mediaObject
    }

    let updateBizProfileEndPoint = environment.apiUrl + "/api/merchants/" + usernameDetails;

    this.http.put(updateBizProfileEndPoint, profileDetails, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).pipe(
      take(1),
      catchError(err => {
        return of({ err: true, message: err.error.error_description })
      })
    ).subscribe((d: any) => {
      if (d == null) {
        this.toastr.success("Profile updated");
      }
      else if (d.err) {
        this.toastr.error("Unable to update profile now");
      }

      this.loaderEnabled = false;

    });
  }

  updateAddress(): void {
    this.loaderEnabled = true;
    const { address, city, locality, country, lat, lng, pincode } = this.addressForm.value;
    let usernameDetails = this.cookieService.get("username");


    if (address == "" || address == undefined) {
      this.toastr.error("Address cannot be empty");
      this.loaderEnabled = false;
    }
    else if (city == "" || city == undefined) {
      this.toastr.error("City cannot be empty");
      this.loaderEnabled = false;
    }
    else if (locality == "" || locality == undefined) {
      this.toastr.error("Locality cannot be empty");
      this.loaderEnabled = false;
    } else {

      let countryCode = this.merchantService.getCountryCodeForCountry(country);

      let addressRequest: updateAddressRequest = {
        username: usernameDetails,
        address: address,
        city: city,
        locality: locality,
        country: country,
        lat: Number(lat),
        lng: Number(lng),
        pincode: pincode,
        countryCode: countryCode
      }


      let updateBizProfileEndPoint = environment.apiUrl + "/api/merchants/" + usernameDetails;

      this.http.put(updateBizProfileEndPoint, addressRequest, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((d: any) => {
        if (d == null) {
          this.toastr.success("Profile updated");
        }
        else if (d.err) {
          this.toastr.error("Unable to update profile now");
        }

        this.loaderEnabled = false;

      });
    }


    this.loaderEnabled = false;




  }

}
