import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { album } from 'src/app/core/models/albums/album.models';
import { AlbumService } from 'src/app/core/services/albums/album.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { PostsService } from 'src/app/core/services/posts/posts.service';

@Component({
  selector: 'app-albums',
  templateUrl: './albums.component.html',
  styleUrls: ['./albums.component.css']
})
export class AlbumsComponent implements OnInit {

  albums: album[] = [];
  constructor(public albumService: AlbumService,
    private cookieService: CookieService,
    private router: Router,
    private merchantService: MerchantService,
    private route: ActivatedRoute,
    private titleService: Title) { 
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  async ngOnInit(): Promise<void> {
    let username = this.cookieService.get("username");
    if(username == "")
    {
      this.router.navigateByUrl("/");
    }
    if(!this.merchantService.getMerchantData())
    {
      this.merchantService.getMechangeDetails();
    }
    
    this.titleService.setTitle("View Image Gallery | Content Management Portal ");
    this.albums =  await this.albumService.getAlbumsObservable();
  }

}
