<div class="page">
    <!-- main-sidebar -->
    <div class="app-sidebar__overlay" data-toggle="sidebar"></div>
    <app-sidebar></app-sidebar>
    <!-- main-sidebar -->
    <!-- main-content -->
    <div class="main-content app-content">
      <!-- main-header -->
      <app-header></app-header>
      <!-- /main-header -->
      <!-- container -->
      <div class="container-fluid topabsolute">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
          <div class="my-auto">
            <div class="d-flex">
              <h4 class="content-title mb-0 my-auto">Product</h4>
              <span class="text-muted mt-1 tx-13 ml-2 mb-0">/ Update</span>
            </div>
          </div>
        </div>
        <!-- breadcrumb -->
        <div class="row">
          <div class="col-md-10">
            <div class="card mg-b-20">
              <div class="card-body">
                <div class="row row-sm">
                  <div class="col-md-12 col-sm-12">
                    <div class="card box-shadow-0">
                      <div class="card-header">
                        <h4 class="card-title mb-1">Update Product</h4>
                      </div>
                      <div class="card-body pt-0">
                        <div class="row">
  
                            <div class="col-6 col-md-3" *ngFor="let imageItem of this.albumItem?.images">
                            <img alt="this.albumItem?.name" class="img-thumbnail" src="{{imageItem?.image?.small}}">
                              <a (click)="archiveImage(imageItem)" style="align-items: center; position: absolute; top: 3%; left: 90%;" title="Archive">
                                <i class="fas fa-trash-alt"></i>
                              </a>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group">
                              <br/>
                              <label> Upload File </label>
                              <input
                                type="file"
                                (change)="albumImageChange($event)"
                                class="dropify height-wrapper"
                                accept=".png, .jpg, .jpeg"
                                data-allowed-file-extensions="jpg png jpeg"
                              />
                            </div>
                          </div>

                          <div class="col-md-12">
                            <div class="form-group">
                              <label> Name </label>
                              <input
                                type="text"
                                class="form-control"
                                placeholder="Album name"
                                value="{{this.albumItem?.name}}"
                                [(ngModel)]="albumName"
                              />
                            </div>
                          </div>
                            <div class="form-group mb-0 mt-3 justify-content-end">
                            <div>
                              <button
                                type="submit"
                                class="btn btn-primary"
                                style="float: right"
                                (click)="updateAlbum()"
                              >
                                UPDATE
                              </button>
                              <button
                                type="submit"
                                class="btn btn-secondary"
                                style="float: left"
                                (click)="archiveAlbum()"
                              >
                                ARCHIVE
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- row -->
          <!-- row closed -->
        </div>
        <!-- Container closed -->
      </div>
      <!-- main-content closed -->
      <!-- Footer opened -->
   
      <!-- Footer closed -->
    </div>
  </div>
  