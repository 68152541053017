<!-- Page -->
<div class="page">

  <div class="container-fluid">
    <div class="row no-gutter">
      <!-- The image half -->
      <div class="col-md-6 col-lg-6 col-xl-7 d-none d-md-flex bg-primary-transparent">
        <div class="row wd-100p mx-auto text-center">
          <div class="col-md-12 col-lg-12 col-xl-12 my-auto mx-auto wd-100p d-md-flex justify-content-center">
            <div>
              <img class="my-auto ht-xl-77p wd-md-77p wd-xl-80p mx-auto"
                src="https://ewnsassets.s3.ap-south-1.amazonaws.com/cms/assets/img/media/roocket.svg" height="77px"
                width="77px" alt="rocket-logo">
              <div class="left-description">
                <p>Take your <span class="business-text">Business</span> <br>to the next level !!</p>
              </div>
            </div>
            <div>
              <img src="https://ewnsassets.s3.ap-south-1.amazonaws.com/cms/assets/img/media/charecter.svg"
                height="260px" width="275px" alt="rocket-logo">
            </div>
          </div>
        </div>
      </div>
      <!-- The content half -->

      <div class="col-md-6 col-lg-6 col-xl-5 bg-white">

        <div class="login d-flex align-items-center py-2">
          <!-- Demo content-->
          <div class="container p-0">
            <div class="row">
              <div class="col-md-10 col-lg-10 col-xl-9 mx-auto">
                <div class="card-sigin">
                  <div class="d-flex right-logo">
                    <a href="https://portal.ewns.in">
                      <img src="https://ewns.in/assets/img/ewns-logo3.png"
                        class="sign-favicon ht-60" alt="logo">
                    </a>
                  </div>
                  <div class="card-sigin">
                    <div class="main-signup-header">
                      <h2>Welcome back!</h2>
                      <h5 class="font-weight-semibold mb-4">Please sign in to continue.</h5>
                      <form [formGroup]="loginForm" (ngSubmit)="login()" (keyup.enter)="login()">
                        <div class="form-group">
                          <label>Username</label> <input formControlName="username" class="form-control"
                            placeholder="Enter your Username" type="text">
                        </div>
                        <div class="form-group">
                          <label>Password</label> <input formControlName="password" class="form-control"
                            placeholder="Enter your password" type="password">
                        </div><button class="btn btn-primary btn-block">Sign In</button>

                      </form>
                      <div class="main-signin-footer mt-5">
                        <p><a routerLink="/forgotPassword" title="Forgot Password">Forgot password?</a></p>
                        <p>Don't have an account? <a routerLink="/signup" title="Create an Account">Create an
                            Account</a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div><!-- End -->


        </div>

      </div><!-- End -->
    </div>
    <div class="row">

    </div>
  </div>

</div>
<!-- End Page -->
