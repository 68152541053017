import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Merchant } from 'src/app/core/models/merchant/merchant.models';
import { bizDetails1 } from 'src/app/core/models/registerAccount/registerAccount.models';
import { country } from 'src/app/core/models/signup/country.models';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  step1: boolean | any;
  step2: boolean | any;
  step3: boolean | any;
  public screen1: FormGroup;
  public screen2: FormGroup;
  public screen3: FormGroup;
  merchantInfo: Merchant | any;
  countryList: Array<country> | any = [];
  public fieldTextType: boolean = false;

  constructor(private titleService: Title,
    private cookieService: CookieService,
    private toastr: ToastrService,
    private merchantService: MerchantService
  ) {

    this.screen1 = new FormGroup({
      email: new FormControl(null, Validators.required),
      businessName: new FormControl(null, Validators.required),
      mobileNumber: new FormControl(null, null),
      password: new FormControl(null, Validators.required),
      confirmPassword: new FormControl(null, null)
    });


    this.screen2 = new FormGroup({
      streetAddress: new FormControl(null, Validators.required),
      locality: new FormControl(null, Validators.required),
      city: new FormControl(null, null),
      country: new FormControl("India", Validators.required),
      zipCode: new FormControl(null, null)
    });


    this.screen3 = new FormGroup({
      shortDescription: new FormControl(null, Validators.required),
      category: new FormControl("General", Validators.required)
    });
  }


  ngOnInit(): void {

    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
    this.merchantService.getCountryList();

    this.titleService.setTitle("Create a Webstore instantly | Manage your Website | CMS");
    this.getBusinessInfo();
    this.countryList = this.merchantService.countryList;

  }


  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  getBusinessInfo() {
    this.merchantInfo = this.cookieService.get("merchantInfo");
    if (this.merchantInfo == null || this.merchantInfo == "") {
      this.step1 = true;
    }
    else {
      let bizInfo = this.cookieService.get("merchantInfo");
      this.merchantInfo = JSON.parse(bizInfo);
      if (this.merchantInfo.email == "" || this.merchantInfo.email == undefined) {
        this.step1 = true;
      }
      else if (this.merchantInfo.address == "" || this.merchantInfo.address == undefined) {
        this.step2 = true;
      }
      else {
        this.step3 = true;
      }
    }
  }

  saveStep1() {
    const { email, businessName, mobileNumber, password, confirmPassword } = this.screen1.value;


    if (email == "" || email == null) {
      this.toastr.error("Email cannot be empty");
    }
    else if (businessName == "" || businessName == null) {
      this.toastr.error("Business Name cannot be empty");
    }
    else if (mobileNumber == "" || mobileNumber == null) {
      this.toastr.error("Mobile number cannot be empty");
    }
    else if (password == "" || password == null) {
      this.toastr.error("Password cannot be empty");
    }
    else if (password.length < 8) {
      this.toastr.error("Password length must be minimum of 8 characters");
    }
    else if (confirmPassword != password) {
      this.toastr.error("Password mismatch");
    }
    else {

      let merchantInformation: bizDetails1 = {
        username: businessName,
        name: businessName,
        password: password,
        email: email,
        mobileNumber: mobileNumber

      }


      this.step2 = true;
      this.step1 = false;

      this.cookieService.set("merchantInfo", JSON.stringify(merchantInformation));
    }

  }

  saveStep2() {
    const { streetAddress, locality, city, country, zipCode } = this.screen2.value;
    if (streetAddress == "" || streetAddress == null) {
      this.toastr.error("Street Address cannot be empty");
    }
    else if (locality == "" || locality == null) {
      this.toastr.error("locality cannot be empty");
    }
    else if (city == "" || city == null) {
      this.toastr.error("city cannot be empty");
    }
    else if (country == "" || country == null) {
      this.toastr.error("country cannot be empty");
    }
    else {

      let bizInfo = this.cookieService.get("merchantInfo");
      this.merchantInfo = JSON.parse(bizInfo);

      let countryCode = this.merchantService.getCountryCodeForSignup(country);

      this.merchantInfo.address = streetAddress;
      this.merchantInfo.locality = locality;
      this.merchantInfo.city = city;
      this.merchantInfo.country = country;
      this.merchantInfo.pincode = zipCode;
      this.merchantInfo.countryCode = countryCode;

      this.cookieService.set("merchantInfo", JSON.stringify(this.merchantInfo));

      this.step2 = false;
      this.step3 = true;
    }

  }

  registerMerchant() {
    const { shortDescription, category } = this.screen3.value;
    if (category == null || category == "") {
      this.toastr.error("Category cannot be empty");
    }
    else if (shortDescription == null || shortDescription == "") {
      this.toastr.error("Short description cannot be empty");
    }
    else {
      let bizInfo = this.cookieService.get("merchantInfo");
      this.merchantInfo = JSON.parse(bizInfo);

      this.merchantInfo.shortBio = shortDescription;
      this.merchantInfo.category = category;


      this.cookieService.set("merchantInfo", JSON.stringify(this.merchantInfo));
      this.step3 = false;

      this.merchantService.createMerchant(this.merchantInfo);




    }


  }




}
