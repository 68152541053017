<div class="page">

    <!-- main-sidebar -->
    <div class="app-sidebar__overlay z0" data-toggle="sidebar"></div>
   <app-sidebar></app-sidebar>
    <!-- main-sidebar -->

    <!-- main-content -->
    <div class="main-content app-content">

        <!-- main-header -->
        <app-header></app-header>
        <!-- /main-header -->

        <!-- container -->
        <div class="container-fluid topabsolute">



            <!-- breadcrumb -->
            <div class="breadcrumb-header justify-content-between">
                <div class="my-auto">
                    <div class="d-flex">
                        <h4 class="content-title mb-0 my-auto">Home</h4><span class="text-muted mt-1 tx-13 ml-2 mb-0">/ Profile</span>
                    </div>
                </div>

            </div>
            <!-- breadcrumb -->

            <div class="row">
                <div class="col-md-10">
 <!-- row -->
 <div class="row row-sm">
    <div class="col-lg-4">
        <div class="card mg-b-20">
            <div class="card-body">
                <div class="pl-0">
                    <div class="main-profile-overview">
                        <div class="main-img-user profile-user">

                            <img *ngIf="!merchantService?.merchant?.logoImage" alt="{{merchantService?.merchant?.name}}" style="object-fit: contain !important;" src="https://ewnsassets.s3.ap-south-1.amazonaws.com/cms/assets/img/faces/6.jpg"><a class="fas  fa-camera profile-edit" style="color: rgba(1, 82, 168, 1);" href="JavaScript:void(0);"></a>
                            <img *ngIf="merchantService?.merchant?.logoImage" alt="{{merchantService?.merchant?.name}}" style="object-fit: contain !important;" src="{{merchantService?.merchant?.logoImage.small}}"><a class="fas fa-camera profile-edit" style="color: rgba(1, 82, 168, 1);" href="JavaScript:void(0);"></a>
                        </div>
                        <div class="d-flex justify-content-between mg-b-20">
                            <div>
                                <h5 class="main-profile-name">{{merchantService?.merchant?.name}}</h5>
                                <p class="main-profile-name-text">{{merchantService.merchant.locality}} {{merchantService.merchant.city}}</p>
                            </div>
                        </div>
                        <h6>Bio</h6>
                        <div class="main-profile-bio" style="text-align: justify;">
                            {{merchantService.merchant.shortBio}}
                        </div><!-- main-profile-bio -->
                        <div class="row">
                            <div class="col-md-3 col mb20">
                                <h5>{{productService.totalCount}}</h5>
                                <h6 class="text-small text-muted mb-0">Products</h6>
                            </div>
                            <div class="col-md-3 col mb20">
                                <h5>{{service.totalCount}}</h5>
                                <h6 class="text-small text-muted mb-0">Services</h6>
                            </div>
                            <div class="col-md-3 col mb20">
                                <h5>{{blogService.totalCount}}</h5>
                                <h6 class="text-small text-muted mb-0">Posts</h6>
                            </div>

                            <div class="col-md-3 col mb20">
                                <h5>{{subscriberService.totalCount}}</h5>
                                <h6 class="text-small text-muted mb-0">Subscribers</h6>
                            </div>
                        </div>
                        <hr class="mg-y-30">
                        <label class="main-content-label tx-13 mg-b-20">Social</label>
                        <div class="main-profile-social-list">
                            <div class="media  CH" (click)="onSocialMediaConnect('facebook')">
                                <div class="media-icon bg-primary-transparent text-info" style="color: rgba(1, 82, 168, 1) !important;">
                                    <i class="fab fa-facebook"></i>
                                </div>
                                <div class="media-body">
                                    <span>Facebook</span>
                                     <a *ngIf="merchantService.merchant.socialLinks" style="color: rgba(1, 82, 168, 1);" href="{{merchantService.merchant.socialLinks.fbPageUrl}}" title="Facebook Page">{{merchantService.merchant.socialLinks.fbPageUrl}}

                                     </a>
                                     <a *ngIf="!merchantService.merchant.socialLinks" routerLink="/profile/edit" title="Facebook Page">Not connected

                                    </a>
                                </div>
                            </div>
                            <div class="media  CH" (click)="onSocialMediaConnect('twitter')">
                                <div class="media-icon bg-primary-transparent text-info" style="color: #1DA1F2 !important;">
                                    <i class="fab fa-twitter"></i>
                                </div>
                                <div class="media-body">
                                    <span>Twitter</span>
                                    <a *ngIf="!merchantService.merchant.socialLinks" routerLink="/profile/edit" title="Twitter Page">Not connected

                                    </a>
                                    <a *ngIf="merchantService.merchant.socialLinks" style="color: rgba(1, 82, 168, 1);" href="{{merchantService.merchant.socialLinks.twitterUsername}}" title="Twitter Page">{{merchantService.merchant.socialLinks.twitterUsername}}</a>
                                </div>
                            </div>
                            <div class="media CH" (click)="onSocialMediaConnect('linkedin')">
                                <div class="media-icon bg-primary-transparent text-info">
                                    <i class="fab fa-linkedin"></i>
                                </div>
                                <div class="media-body">
                                    <span>Linkedin</span>
                                    <a *ngIf="!merchantService.merchant.socialLinks" routerLink="/profile/edit" title="LinkedIn Page">Not connected

                                    </a>
                                     <a *ngIf="merchantService.merchant.socialLinks" style="color: rgba(1, 82, 168, 1);" href="{{merchantService.merchant.socialLinks.linkedIn}}" title="LinkedIn">{{merchantService.merchant.socialLinks.linkedIn}}</a>
                                </div>
                            </div>
                            <div class="media CH" (click)="onSocialMediaConnect('instagram')">
                                <div class="media-icon bg-primary-transparent text-info">
                                    <i class="fab fa-instagram"></i>
                                </div>
                                <div class="media-body">
                                    <span>Instagram</span>
                                    <a *ngIf="!merchantService.merchant.socialLinks" routerLink="/profile/edit" title="Instagram">Not connected

                                    </a>
                                     <a *ngIf="merchantService.merchant.socialLinks" style="color: rgba(1, 82, 168, 1);" href="{{merchantService.merchant.socialLinks.instagram}}" title="Instagram">{{merchantService.merchant.socialLinks.instagram}}</a>
                                </div>
                            </div>

                            <div class="media CH" (click)="onSocialMediaConnect('pintrest')">
                                <div class="media-icon bg-primary-transparent text-info">
                                    <i class="fab fa-pinterest"></i>
                                </div>
                                <div class="media-body">
                                    <span>Pinterest</span>
                                    <a *ngIf="!merchantService.merchant.socialLinks" routerLink="/profile/edit" title="Pinterest">Not connected

                                    </a>
                                     <a *ngIf="merchantService.merchant.socialLinks" style="color: rgba(1, 82, 168, 1);" href="{{merchantService.merchant.socialLinks.pinterest}}" title="Pinterest">{{merchantService.merchant.socialLinks.pinterest}}</a>
                                </div>
                            </div>
                        </div>
                        <hr class="mg-y-30">



                    </div><!-- main-profile-overview -->
                </div>
            </div>
        </div>

        <div class="card mg-b-20">
            <div class="card-body">
                <div class="main-content-label tx-13 mg-b-25">
                    Conatct
                </div>
                <div class="main-profile-contact-list">
                    <div class="media">
                        <div class="media-icon bg-primary-transparent text-info">
                            <i class="fas fa-mobile-alt"></i>
                        </div>
                        <div class="media-body">
                            <span>Mobile</span>
                            <div>
                                {{merchantService.merchant.countryCode}} {{merchantService.merchant.mobileNumber}}
                            </div>
                        </div>
                    </div>
                    <div class="media">
                        <div class="media-icon bg-primary-transparent text-info">
                            <i class="fas fa-envelope"></i>
                        </div>
                        <div class="media-body">
                            <span>Email</span>
                            <div>
                                {{merchantService.merchant.email}}
                            </div>
                        </div>
                    </div>
                    <div class="media">
                        <div class="media-icon bg-primary-transparent text-info">
                            <i class="fas fa-map-marker-alt"></i>
                        </div>
                        <div class="media-body">
                            <span>Current Address</span>
                            <div>
                                {{merchantService.merchant.streetAddress}}  {{merchantService.merchant.locality}}  {{merchantService.merchant.city}}
                                {{merchantService.merchant.country}}  {{merchantService.merchant.zipCode}}
                            </div>
                        </div>
                    </div>
                </div><!-- main-profile-contact-list -->
            </div>
        </div>
    </div>
    <div class="col-lg-8">
        <div class="row row-sm">
            <div class="col-sm-12 col-xl-4 col-lg-12 col-md-12">
                <div class="card ">
                    <div class="card-body">
                        <div class="counter-status d-flex md-mb-0">
                            <div class="counter-icon bg-primary-transparent">
                                <i class="icon-layers text-info"></i>
                            </div>
                            <div class="ml-auto">
                                <h5 class="tx-13">Orders</h5>
                                <h2 class="mb-0 tx-22 mb-1 mt-1">{{orderService.totalCount}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-xl-4 col-lg-12 col-md-12">
                <div class="card ">
                    <div class="card-body">
                        <div class="counter-status d-flex md-mb-0">
                            <div class="counter-icon bg-primary-transparent">
                                <i class="icon-paypal text-info"></i>
                            </div>
                            <div class="ml-auto">
                                <h5 class="tx-13">Revenue</h5>
                                <h2 class="mb-0 tx-22 mb-1 mt-1">46,782</h2>
                                <p class="text-muted mb-0 tx-11"><i class="si si-arrow-up-circle text-success mr-1"></i>increase</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-xl-4 col-lg-12 col-md-12">
                <div class="card ">
                    <div class="card-body">
                        <div class="counter-status d-flex md-mb-0">
                            <div class="counter-icon bg-primary-transparent">
                                <i class="icon-rocket text-info"></i>
                            </div>
                            <div class="ml-auto">
                                <h5 class="tx-13">Product sold</h5>
                                <h2 class="mb-0 tx-22 mb-1 mt-1">1,890</h2>
                                <p class="text-muted mb-0 tx-11"><i class="si si-arrow-up-circle text-success mr-1"></i>increase</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="tabs-menu ">
                    <!-- Tabs -->
                    <ul class="nav nav-tabs profile navtab-custom panel-tabs">
                        <li class="active">
                            <a href="#home" data-toggle="tab" aria-expanded="true"> <span class="visible-xs"><i class="las la-user-circle tx-16 mr-1"></i></span> <span class="hidden-xs">ABOUT US</span> </a>
                        </li>
                        <li class="">
                            <a href="#profile" data-toggle="tab" aria-expanded="false"> <span class="visible-xs"><i class="las la-images tx-15 mr-1"></i></span> <span class="hidden-xs">LOGO</span> </a>
                        </li>

                    </ul>
                </div>
                <div class="tab-content border-left border-bottom border-right border-top-0 p-4">
                    <div class="tab-pane active" id="home">
                        <h4 class="tx-15 text-uppercase mb-3">Description</h4>
                        <p class="m-b-5" [innerHTML]="merchantService.merchant.description | keepHtml" style="text-align: justify;" ></p>

                    </div>
                    <div class="tab-pane" id="profile">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="border p-1 card thumb">
                                    <img *ngIf="merchantService?.merchant?.logoImage" src="{{merchantService?.merchant?.logoImage.small}}" class="thumb-img" alt="work-thumbnail">
                                    <img *ngIf="!merchantService?.merchant?.logoImage" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5fff3d958d5dbc10304de1bb.png" class="thumb-img" alt="work-thumbnail">
                                    <h4 class="text-center tx-14 mt-3 mb-0">Logo</h4>
                                    <div class="ga-border-2"></div>
                                    <p class="text-muted text-center"><small>Logo</small></p>
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="border p-1 card thumb">
                                    <img *ngIf="merchantService.merchant.favImage" src="{{merchantService.merchant.favImage.small}}" class="thumb-img" alt="work-thumbnail">
                                    <img *ngIf="!merchantService.merchant.favImage" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5fff3d958d5dbc10304de1bb.png" class="thumb-img" alt="work-thumbnail">
                                    <h4 class="text-center tx-14 mt-3 mb-0">FavIcon</h4>
                                    <div class="ga-border-2"></div>
                                    <p class="text-muted text-center"><small>FavIcon</small></p>
                                </div>
                            </div>

                            <div class="col-sm-4">
                                <div class="border p-1 card thumb">
                                    <img *ngIf="merchantService.merchant.featuredImage" src="{{merchantService.merchant.featuredImage.small}}" class="thumb-img" alt="work-thumbnail">
                                    <img *ngIf="!merchantService.merchant.featuredImage" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5fff3d958d5dbc10304de1bb.png" class="thumb-img" alt="work-thumbnail">
                                    <h4 class="text-center tx-14 mt-3 mb-0">featuredImage</h4>
                                    <div class="ga-border-2"></div>
                                    <p class="text-muted text-center"><small>featuredImage</small></p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- row closed -->
                </div>
            </div>



        </div>
        <!-- Container closed -->
    </div>
    <!-- main-content closed -->



</div>
