<div class="row">
  <div class="col-md-6">
    <div class="form-group">
      <label>{{ this.parentName }}</label>
      <ng-autocomplete
        placeholder="Select Category"
        [data]="dataForCats"
        [searchKeyword]="keyword"
        (selected)="onSelectParentCategory($event)"
        [(ngModel)]="selectedCat"
        [itemTemplate]="itemTemplate"       
        [notFoundTemplate]="notFoundTemplate"
        (inputCleared)="onClearParent()"
      >
      </ng-autocomplete>
      <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.name"></a>
      </ng-template>

      <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
      </ng-template>
    </div>
  </div>
  <div class="col-md-6">
    <div class="form-group">
      <label>{{ this.childName }}</label>
      <ng-autocomplete
        #subcats
        [placeholder]="selectSubCatText"
        [data]="dataForSubCats"
        [disabled]="disabledSubCats"
        [searchKeyword]="keyword"
        (selected)="selectChildEvent($event)"
        [(ngModel)]="selectedSubCat"
        (inputCleared)="clearChildData()"
        [itemTemplate]="itemTemplate"
        [notFoundTemplate]="notFoundTemplate"
      >
      </ng-autocomplete>

      <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.name"></a>
      </ng-template>

      <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
      </ng-template>
    </div>
  </div>
</div>
