import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { addTestimonyRequest } from 'src/app/core/models/customer/testimonials.models';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { TestimonyService } from 'src/app/core/services/testimonials/testimony.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-testimony',
  templateUrl: './add-testimony.component.html',
  styleUrls: ['./add-testimony.component.css']
})
export class AddTestimonyComponent implements OnInit {

  public uploadTestimonyImage = environment.apiUrl + "/api/Testimonys/image";
  public testimonyImage = "";

  loaderEnabled = false;
  public title = "";
  public description = "";
  public name = "";
  public rating = 5;
  constructor(private cookieService: CookieService,
    private merchantService: MerchantService,
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private testimonialService: TestimonyService,
    private titleService: Title) { }

  ngOnInit(): void {

    const windowObj = window as any;
    windowObj.$('.dropify').dropify({});
    let username = this.cookieService.get("username");
    if (username == "") {
      this.router.navigateByUrl("/");
    }
    if (!this.merchantService.getMerchantData()) {
      this.merchantService.getMechangeDetails();
    }

    this.titleService.setTitle("Add Customer Testimonial | Content Management Portal");
  }

  async addTestimony(){
   this.loaderEnabled = true;
   if(this.name == undefined || this.name == null || this.name == "")
   {
     this.loaderEnabled = false;
     this.toastr.error("Name cannot be empty");
   }
   else if(this.title == undefined || this.title == null || this.title == "")
   {
    this.loaderEnabled = false;
    this.toastr.error("Title cannot be empty");
   }
   else if(this.description == undefined || this.description == null || this.description == "")
   {
    this.loaderEnabled = false;
    this.toastr.error("Description cannot be empty");
   }
   else{
     let test: addTestimonyRequest = {
      name: this.name,
      description: this.description,
      title: this.title,
      imageUrl: this.testimonyImage,
      rating: this.rating,
      username: this.cookieService.get("username")
     };

     await this.testimonialService.addTestimonial(test).then((data) => {
      this.toastr.success("Testimonial added successfully");
      this.loaderEnabled = false;
  })
  .catch((error) => {
    console.log(error);
    this.loaderEnabled = false;
  });
   }

  }

  testimonyImageChange(data: any): void {
    let file = data.target.files[0];
    let reader = new FileReader();
    const me = this;
    this.loaderEnabled = true;
    reader.readAsDataURL(file);
    reader.onload = async function () {
      const imagedata = reader.result?.toString().split(',')[1];
      try {
        let imageUrl = await me.uploadTestimonyImages(imagedata);
        me.toastr.success("Image uploaded successfully");
        me.testimonyImage = imageUrl;
        me.loaderEnabled = false;
      } catch (error) {
        me.toastr.error("something went wrong");
      }
    };
    reader.onerror = function (error) {
      me.toastr.error("something went wrong " + error);
    };
  }


  uploadTestimonyImages(data: any): Promise<any> {
    const headers = new HttpHeaders().set("Content-Length", data.length.toString())

    return this.http.put(this.uploadTestimonyImage,
      { "image": data },
      { headers, responseType: 'text' }).toPromise();

  }



}
