import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

import { CookieService } from 'ngx-cookie-service';
import { ToastrService, ToastToken } from 'ngx-toastr';

@Component({
  selector: 'app-wysiwyg',
  templateUrl: './wysiwyg.component.html',
  styleUrls: ['./wysiwyg.component.css']
})
export class WysiwygComponent implements OnInit, OnChanges {

  constructor(private http: HttpClient,private toastr: ToastrService, private cookieService: CookieService) { }


  @Input()
  uploadUrl: String = "";
  @Input()
  pressedSubmit: boolean = false;
  @Input()
  configObj: any = undefined;
  @Input()
  value: String = "";
  @Output()
  getData = new EventEmitter()

  @ViewChild('editor') controlname: any;
  ngOnInit(): void {
    if (!this.configObj) {
      this.configObj = {
        "uploader": {
          "insertImageAsBase64URI": true
        },
        "minHeight": 400,
        "minWidth": 400
      }
    }
  }

  async processEditorData(): Promise<void> {
    let htmlData = this.controlname.value;
    const regex = /"data:image\/([a-zA-Z]*);base64,([^\"]*)\"/gm;
    const matched = htmlData.match(regex);
    if (matched) {
      for (let index = 0; index < matched.length; index++) {
        const element = matched[index];
        const baseString = element.split(',')[1].replace('"', "").trim();
        let updatedUrl;

        try {
          updatedUrl = await this.uploadImage(baseString);
        } catch (error) {
          this.toastr.error("Something went wrong while uploading image");
          return
        }

        htmlData = htmlData.replace(element, '"' + updatedUrl + '"');
      }
    }
    this.getData.emit(htmlData);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pressedSubmit.currentValue) {
      this.processEditorData();
    }
  }
  uploadImage(bytes: string): Promise<any> {
    const usernameDetails = this.cookieService.get("username");
    if (!usernameDetails) {
      return new Promise((r, rej) => {
        rej("userdeatils not found")
      })
    }

    const headers = new HttpHeaders().set("Content-Length", bytes.length.toString())
    return this.http.put(`${this.uploadUrl}${usernameDetails}`,
      { "image": bytes },
      { headers, responseType: 'text' }).toPromise();

  }

}
