import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { PostsService } from 'src/app/core/services/posts/posts.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  constructor(public blogService: PostsService,
    private cookieService: CookieService,
    private router: Router,
    private merchantService: MerchantService,
    private route: ActivatedRoute,
    private titleService: Title) { 
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  ngOnInit(): void {
    let username = this.cookieService.get("username");
    if(username == "")
    {
      this.router.navigateByUrl("/");
    }
    if(!this.merchantService.getMerchantData())
    {
      this.merchantService.getMechangeDetails();
    }

    const routeParams = this.route.snapshot.paramMap;
    const index = Number(routeParams.get('index'));
    
    this.titleService.setTitle("View Our Blog Posts | Content Management Portal ");
    this.blogService.getPosts((index-1), 9);
  }

}
