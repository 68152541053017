<div class="page">
  <!-- main-sidebar -->
  <div class="app-sidebar__overlay z0" data-toggle="sidebar"></div>
  <app-sidebar></app-sidebar>
  <!-- main-sidebar -->
  <!-- main-content -->
  <div class="main-content app-content">
    <!-- main-header -->
    <app-header></app-header>
    <!-- /main-header -->
    <!-- container -->
    <div class="container-fluid">
      <!-- breadcrumb -->
      <div class="breadcrumb-header justify-content-between">
        <div class="my-auto">
          <div class="d-flex">
            <h4 class="content-title mb-0 my-auto">Home</h4>
            <span class="text-muted mt-1 tx-13 ml-2 mb-0">/ Category</span>
          </div>
        </div>
      </div>
      <!-- breadcrumb -->
      <div class="row">
        <div class="col-md-12">
          <div class="card mg-b-20">
            <div class="card-body">
              <div class="main-content-label mg-b-5">
                Add Categories
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- row -->
      <!-- row closed -->
      <div class="row p-3">


        <div class="col-md-4">
          <input class="form-control bs" placeholder="Add new category" type="text" [(ngModel)]="newName" />
        </div>
        <div class="col-md-3">
          <div class="ng-autocomplete">
            <div class="ng-autocomplete">
              <ng-autocomplete placeholder="Select Category" [data]="dataForCats" [searchKeyword]="keyword"
                (selected)="onSelectParentCategory($event)" [(ngModel)]="selectedCat" [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate" (inputCleared)="onClearParent()">
              </ng-autocomplete>

              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.name"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="ng-autocomplete">
            <div class="ng-autocomplete">
              <ng-autocomplete #subcats [placeholder]="selectSubCatText" [data]="dataForSubCats"
                [disabled]="disabledSubCats" [searchKeyword]="keyword" (selected)="selectEvent($event)"
                [(ngModel)]="selectedSubCat" [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>

              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.name"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-md-2">
          <button class="btn btn-primary btn-block" [disabled]="newName.length <= 0" (click)="onAddClick()">
            Add
          </button>
        </div>
      </div>
      <!-- Container closed -->
    </div>
    <!-- main-content closed -->
    <!-- Footer opened -->

    <!-- Footer closed -->
  </div>

  <app-footer></app-footer>
</div>