import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, skip, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { message } from '../../models/messages/message.models';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  totalCount: number | any = 0;
  allMessages : Array<message> | any;
  constructor(private http: HttpClient, 
    private cookieService: CookieService,
    private toastr: ToastrService) { }

  getMessages(slip: number, limit: number): void{

    let username = this.cookieService.get("username");
    const getBizEnquiryEndPoint = environment.apiUrl + '/api/Internal/messages/' + username+ "?skip=" + slip + "&limit=" + limit;
    this.http.get<message[]>(getBizEnquiryEndPoint).pipe(
      take(1),
      catchError(err => {
        return of({ err: true, message: err.error.error_description })
      })
    ).subscribe((data : message[] |any) => {
      this.allMessages = [...data];
    });
  }



  getMessagesCount(): void{

    let username = this.cookieService.get("username");
    const getBizEnquiryEndPoint = environment.apiUrl + '/api/Internal/messages/' + username + "/count";
    this.http.get<number>(getBizEnquiryEndPoint).pipe(
      take(1),
      catchError(err => {
        return of({ err: true, message: err.error.error_description })
      })
    ).subscribe((data : number |any) => {
      this.totalCount = data;
    });
  }
}
