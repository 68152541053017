<div class="page">

  <div class="container-fluid">
    <div class="row no-gutter">
      <!-- The image half -->
      <div class="col-md-6 col-lg-6 col-xl-7 d-none d-md-flex bg-primary-transparent">
        <div class="row wd-100p mx-auto text-center">
          <div class="col-md-12 col-lg-12 col-xl-12 my-auto mx-auto wd-100p d-md-flex justify-content-center">
            <div>
              <img class="my-auto ht-xl-77p wd-md-77p wd-xl-80p mx-auto"
                src="https://ewnsassets.s3.ap-south-1.amazonaws.com/cms/assets/img/media/roocket.svg" height="77px"
                width="77px" alt="rocket-logo">
              <div class="left-description">
                <p>Take your <span class="business-text">Business</span> <br>to the next level !!</p>
              </div>
            </div>
            <div>
              <img src="https://ewnsassets.s3.ap-south-1.amazonaws.com/cms/assets/img/media/charecter.svg"
                height="260px" width="275px" alt="rocket-logo">
            </div>
          </div>
        </div>
      </div>
      <!-- The content half -->
      <div class="col-md-6 col-lg-6 col-xl-5 bg-white">
        <div class="login d-flex align-items-center py-2">
          <!-- Demo content-->
          <div class="container p-0">
            <div class="row">
              <div class="col-md-10 col-lg-10 col-xl-9 mx-auto">
                <div class="card-sigin">
                  <div class="mb-5 d-flex"> <a href="https://portal.ewns.in">
                      <img src="https://ewns.in/assets/img/ewns-logo3.png"
                        class="sign-favicon ht-60" alt="logo">
                    </a></div>
                  <div class="main-signup-header">
                    <h2 class="text-primary">Get Started</h2>
                    <h5 class="font-weight-normal mb-4">It's time to get your business online</h5>
                    <form [formGroup]="screen1" (ngSubmit)="saveStep1()" *ngIf="step1">
                      <div class="form-group">
                        <label>Name of your Business*</label>
                        <input formControlName="businessName" class="form-control"
                          placeholder="Enter name of your Business" type="text">
                      </div>
                      <div class="form-group">
                        <label>Email*</label>
                        <input formControlName="email" class="form-control" placeholder="Enter your email" type="text">
                      </div>
                      <div class="form-group">
                        <label>Mobile Number*</label>
                        <input formControlName="mobileNumber" class="form-control"
                          placeholder="Enter your Mobile number" type="text">
                      </div>
                      <div class="form-group">
                        <label>Password*</label>
                        <div class="d-flex view-pass">
                          <input formControlName="password" class="form-control" placeholder="Enter your password"
                          [type]="fieldTextType ? 'text' : 'password'">
                          <div class="input-group-append">
                            <span class="input-group-text">
                              <i class="fa" [ngClass]="{
                                    'fa-eye-slash': !fieldTextType,
                                    'fa-eye': fieldTextType
                                  }" (click)="toggleFieldTextType()"></i>
                            </span>
                          </div>
                        </div>

                      </div>
                      <div class="form-group">
                        <label>Confirm Password*</label>
                        <div class="d-flex view-pass">
                        <input formControlName="confirmPassword" class="form-control"
                          placeholder="Confirm your password" [type]="fieldTextType ? 'text' : 'password'">
                        <div class="input-group-append">
                          <span class="input-group-text">
                            <i class="fa" [ngClass]="{
                                  'fa-eye-slash': !fieldTextType,
                                  'fa-eye': fieldTextType
                                }" (click)="toggleFieldTextType()"></i>
                          </span>
                        </div>
                      </div>
                      </div>
                      <button class="btn btn-primary btn-block">CONTINUE</button>

                    </form>

                    <form [formGroup]="screen2" (ngSubmit)="saveStep2()" *ngIf="step2">
                      <div class="form-group">
                        <label>Street Address*</label>
                        <input formControlName="streetAddress" class="form-control"
                          placeholder="Street address of your Business" type="text">
                      </div>
                      <div class="form-group">
                        <label>Locality*</label>
                        <input formControlName="locality" class="form-control" placeholder="Locality" type="text">
                      </div>
                      <div class="form-group">
                        <label>City*</label>
                        <input formControlName="city" class="form-control" placeholder="City" type="text">
                      </div>
                      <div class="form-group">
                        <label>Country*</label>
                        <select formControlName="country" class="form-control">
                          <option value="{{countryItem.name}}" *ngFor="let countryItem of countryList">
                            {{countryItem.name}}</option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label>ZipCode</label>
                        <input formControlName="zipCode" class="form-control" placeholder="ZipCode" type="text">
                      </div>
                      <button class="btn btn-main-primary btn-block">CONTINUE</button>

                    </form>
                    <form [formGroup]="screen3" (ngSubmit)="registerMerchant()" *ngIf="step3">
                      <div class="form-group">
                        <label> Short Description about your business</label>
                        <textarea formControlName="shortDescription" class="form-control" rows="5"
                          placeholder="Short description"></textarea>
                      </div>
                      <div class="form-group">
                        <label>Category</label>
                        <select formControlName="category" class="form-control">
                          <option value="General">General</option>
                          <option value="Manufacturers">Manufacturers</option>
                          <option value="Doctors">Doctors</option>
                          <option value="Restaurants">Restaurants</option>
                          <option value="Automobiles">Automobiles</option>
                          <option value="Hospitals">Hospitals</option>
                          <option value="InteriorDesign">InteriorDesign</option>
                          <option value="RealEstate">RealEstate</option>
                          <option value="Boutique">Boutique</option>
                          <option value="Hotel">Hotel</option>
                          <option value="Education">Education</option>
                          <option value="Electronics">Electronics</option>
                          <option value="Spa">Spa</option>
                          <option value="Logistics">Logistics</option>
                          <option value="Schools">Schools</option>
                          <option value="KinderGarden">KinderGarden</option>
                          <option value="Pubs">Pubs</option>
                          <option value="Traders">Traders</option>
                          <option value="ExportsNImports">ExportsNImports</option>
                          <option value="Saloon">Saloon</option>
                          <option value="Astrologers">Astrologers</option>
                          <option value="SwimmingPools">SwimmingPools</option>
                          <option value="DigitalMarketing">DigitalMarketing</option>
                          <option value="Marketing">Marketing</option>
                          <option value="Institutes">Institutes</option>

                        </select>
                      </div>

                      <button class="btn btn-main-primary btn-block">CREATE ACCOUNT</button>

                    </form>
                    <div class="main-signup-footer mt-5">
                      <p>Already have an account? <a routerLink="/" title="Sign In">Sign In</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div><!-- End -->
        </div>
      </div><!-- End -->
    </div>
  </div>

</div>
