import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subject, of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { album, albumRequest } from '../../models/albums/album.models';

@Injectable({
  providedIn: 'root'
})
export class AlbumService {

  albums: album[] | any;
  albInfoUpdated = new Subject();
  albumInfo: album | any;
  constructor(private http: HttpClient, private cookieService: CookieService) { }

  getAlbums() {
    let username = this.cookieService.get("username");
    if (username && username !== 'undefined') {
      const albumsEndPoint = environment.apiUrl + '/api/Albums/merchant/' + username;
      this.http.get<album[]>(albumsEndPoint).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((data: album[] | any) => {
        this.albums = [...data];
      });
    }
  }

  getAlbumDetails(albumId: string) {
    const albumsDetailsEndPoint = environment.apiUrl + '/api/Albums/' + albumId;
    this.http.get<album>(albumsDetailsEndPoint).pipe(
      take(1),
      catchError(err => {
        return of({ err: true, message: err.error.error_description })
      })
    ).subscribe((data: album | any) => {
      this.albumInfo = data;
      this.albInfoUpdated.next(data);
    });
  }

  async getAlbumsObservable(): Promise<Array<album>>{
    let username = this.cookieService.get("username");
    let albumsEndPoint = environment.apiUrl + '/api/Albums/merchant/' + username;
    return this.http.get<album[]>(albumsEndPoint).toPromise();
  }

  //  api for getting album details
  async getAlbumDetailsAsync(albumId: string): Promise<album> {
    const albumsDetailsEndPoint = environment.apiUrl + '/api/Albums/' + albumId;
    return this.http.get<album>(albumsDetailsEndPoint).toPromise();
  }

  async addAlbumObservable(albumName: string): Promise<any>{
    let albumsEndPoint = environment.apiUrl + '/api/Albums';
    let albumRequest : albumRequest = {
        username: this.cookieService.get('username'),
        name: albumName
    };

    return this.http.post<any>(albumsEndPoint,albumRequest, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise();
  }

  async updateAlbumName(albumName: string, albumId: string): Promise<any>{
    let albumsEndPoint = environment.apiUrl + '/api/Albums/'+ albumId;
    let albumRequest : albumRequest = {
        username: this.cookieService.get('username'),
        name: albumName
    };

    return this.http.put<any>(albumsEndPoint,albumRequest, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise();
  }
}
