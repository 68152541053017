import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { changePasswordReq } from '../../models/merchant/authentication.models';
import { backgroundImage } from '../../models/merchant/bgImage.models';
import { Merchant, merchantProperties, MerchantRequest } from '../../models/merchant/merchant.models';
import { country } from '../../models/signup/country.models';

@Injectable({
  providedIn: 'root'
})
export class MerchantService {

  merchant: Merchant | any;
  countryList: Array<country>  = [];
  constructor(private cookieService: CookieService, 
    private toastr: ToastrService,
    private router: Router,
    private http: HttpClient) { }


  getMechangeDetails(): void {
    let username = this.cookieService.get('username');
    const merchantDetailsEndPoint = environment.apiUrl + '/api/Merchants/' + username;


    this.http.get(merchantDetailsEndPoint, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).pipe(
      take(1),
      catchError(err => {
        return of({ err: true, message: err.error.error_description })
      })
    ).subscribe((d: Merchant | any) => {
      if (d.err) {
        this.toastr.error("Something is wrong at our end, please reload again", "Service Call Failed");
      }
      else{
        this.merchant = d;
      }
    
    });
  }


  setMerchantData(data : Merchant | any): void{
    this.merchant = data;
  }

  getMerchantData(): Merchant {
    return this.merchant;
  }


  getMechangeDetailsObserver(): Observable<any> {
    let username = this.cookieService.get('username');
    let merchantDetailsEndPoint = environment.apiUrl + '/api/Merchants/' + username;
    return this.http.get<Merchant>(merchantDetailsEndPoint);
  }

  updateMerchantDetails(merchantDetailsReq: MerchantRequest): void{
    let username = this.cookieService.get('username');
    const merchantDetailsEndPoint = environment.apiUrl + '/api/Merchants/' + username;

    this.http.put(merchantDetailsEndPoint, merchantDetailsReq, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).pipe(
      take(1),
      catchError(err => {
        this.toastr.error(err.error.error_description, "Profile Update Failed")
        return of({ err: true, message: err.error.error_description })
      })
    ).subscribe((d: Merchant | any) => {
      if (d.err) {
        this.toastr.error("Unable to update business details", "Profile Update Failed");
      }
    
    });

  }

  getBackgroundImage(): Observable<any> {
    let username = this.cookieService.get('username');
    const backgroundImagesEndpoint = environment.apiUrl + '/api/Images/background/' + username;
    return this.http.get<backgroundImage[]>(backgroundImagesEndpoint)
  }

  createMerchant(merchantDetailsReq: MerchantRequest): void{
    const merchantDetailsEndPoint = environment.apiUrl + '/api/Merchants';
    this.http.post(merchantDetailsEndPoint, merchantDetailsReq, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).pipe(
      take(1),
      catchError(err => {
        this.toastr.error(err.error.error_description, "Unable to create profile")
        return of({ err: true, message: err.error.error_description })
      })
    ).subscribe((d: Merchant | any) => {
      if (d.err) {
        
        this.toastr.error("Unable to update business details", "Profile Update Failed");
      }
      else{
        this.merchant = d;
        this.cookieService.set("username", this.merchant.username);
        this.cookieService.delete("merchantInfo");
        this.router.navigateByUrl("/");
      }
    
    });
  } 

  getCountryList(){

    this.countryList.push({name: "Afghanistan", code: "+93"});
    this.countryList.push({name: "Albania", code: "+355"});
    this.countryList.push({name: "Algeria", code: "+213"});
    this.countryList.push({name: "Angola", code: "+244"});
    this.countryList.push({name: "Antigua and Barbuda", code: "+1"});
    this.countryList.push({name: "Argentina", code: "+54"});
    this.countryList.push({name: "Armenia", code: "+374"});
    this.countryList.push({name: "Aruba", code: "+297"});
    this.countryList.push({name: "Australia", code: "+61"});
    this.countryList.push({name: "Austria", code: "+43"});
    this.countryList.push({name: "Azerbaijan", code: "+994"});


    this.countryList.push({name: "Bahamas", code: "+1"});
    this.countryList.push({name: "Bahrain", code: "+973"});
    this.countryList.push({name: "Bangladesh", code: "+880"});
    this.countryList.push({name: "Barbados", code: "+1"});
    this.countryList.push({name: "Belarus", code: "+375"});
    this.countryList.push({name: "Belgium", code: "+32"});
    this.countryList.push({name: "Bermuda", code: "+1"});
    this.countryList.push({name: "Bhutan", code: "+975"});
    this.countryList.push({name: "Bolivia", code: "+591"});
    this.countryList.push({name: "Bosnia and Herzegovinauba", code: "+387"});
    this.countryList.push({name: "Botswana", code: "+267"});
    this.countryList.push({name: "Brazil", code: "+55"});
    this.countryList.push({name: "Brunei Darussalam", code: "+673"});
    this.countryList.push({name: "Bulgaria", code: "+359"});
    this.countryList.push({name: "Burkina Faso", code: "+226"});
    this.countryList.push({name: "Burundi", code: "+257"});

    this.countryList.push({name: "Cambodia", code: "+855"});
    this.countryList.push({name: "Cameroon", code: "+237"});
    this.countryList.push({name: "Canada", code: "+1"});
    this.countryList.push({name: "Cape Verde", code: "+238"});
    this.countryList.push({name: "Central African Republic", code: "+236"});
    this.countryList.push({name: "Chad", code: "+235"});
    this.countryList.push({name: "Chile", code: "+56"});
    this.countryList.push({name: "China", code: "+86"});
    this.countryList.push({name: "Colombia", code: "+57"});
    this.countryList.push({name: "Comoros", code: "+269"});
    this.countryList.push({name: "Costa Rica", code: "+506"});
    this.countryList.push({name: "Croatia", code: "+385"});
    this.countryList.push({name: "Cuba", code: "+53"});
    this.countryList.push({name: "Cyprus", code: "+357"});
    this.countryList.push({name: "Czech Republic", code: "+420"});


    this.countryList.push({name: "Denmark", code: "+45"});
    this.countryList.push({name: "Djibouti", code: "+253"});
    this.countryList.push({name: "Dominica", code: "+1"});
    this.countryList.push({name: "Dominican Republic", code: "+1"});


    this.countryList.push({name: "Ecuador", code: "+593"});
    this.countryList.push({name: "Egypt", code: "+20"});
    this.countryList.push({name: "El Salvador", code: "+503"});
    this.countryList.push({name: "Equatorial Guinea", code: "+240"});
    this.countryList.push({name: "Eritrea", code: "+291"});
    this.countryList.push({name: "Estonia", code: "+372"});
    this.countryList.push({name: "Ethiopia", code: "+251"});

    this.countryList.push({name: "Faroe Islands", code: "+298"});
    this.countryList.push({name: "Fiji", code: "+679"});
    this.countryList.push({name: "Finland", code: "+358"});
    this.countryList.push({name: "France", code: "+33"});
    this.countryList.push({name: "French Guiana", code: "+594"});
    this.countryList.push({name: "French Polynesia", code: "+689"});


    this.countryList.push({name: "Gabon", code: "+241"});
    this.countryList.push({name: "Gambia", code: "+220"});
    this.countryList.push({name: "Georgia", code: "+995"});
    this.countryList.push({name: "Germany", code: "+49"});
    this.countryList.push({name: "Ghana", code: "+233"});
    this.countryList.push({name: "Gibraltar", code: "+350"});
    this.countryList.push({name: "Greece", code: "+30"});
    this.countryList.push({name: "Greenland", code: "+299"});
    this.countryList.push({name: "Grenada", code: "+1"});
    this.countryList.push({name: "Guadeloupe", code: "+590"});
    this.countryList.push({name: "Guam", code: "+1"});
    this.countryList.push({name: "Guatemala", code: "+502"});
    this.countryList.push({name: "Guinea", code: "+224"});
    this.countryList.push({name: "Guinea-Bissau", code: "+245"});
    this.countryList.push({name: "Guyana", code: "+592"});


    this.countryList.push({name: "Haiti", code: "+509"});
    this.countryList.push({name: "Holy See", code: "+379"});
    this.countryList.push({name: "Honduras", code: "+504"});
    this.countryList.push({name: "Hong Kong", code: "+852"});
    this.countryList.push({name: "Hungary", code: "+36"});

    this.countryList.push({name: "Iceland", code: "+354"});
    this.countryList.push({name: "India", code: "+91"});
    this.countryList.push({name: "Indonesia", code: "+62"});
    this.countryList.push({name: "Iran", code: "+98"});
    this.countryList.push({name: "Iraq", code: "+964"});
    this.countryList.push({name: "Ireland", code: "+353"});
    this.countryList.push({name: "Israel", code: "+972"});
    this.countryList.push({name: "Italy", code: "+39"});

    this.countryList.push({name: "Jamaica", code: "+1"});
    this.countryList.push({name: "Japan", code: "+81"});
    this.countryList.push({name: "Jordan", code: "+962"});

    this.countryList.push({name: "Kazakhstan", code: "+8107"});
    this.countryList.push({name: "Kenya", code: "+254"});
    this.countryList.push({name: "Kiribati", code: "+686"});
    this.countryList.push({name: "Korea North", code: "+850"});
    this.countryList.push({name: "Korea South", code: "+00182"});
    this.countryList.push({name: "Kuwait", code: "+965"});
    this.countryList.push({name: "Kyrgyzstan", code: "+996"});

    this.countryList.push({name: "Laos", code: "+856"});
    this.countryList.push({name: "Latvia", code: "+371"});
    this.countryList.push({name: "Lebanon", code: "+961"});
    this.countryList.push({name: "Lesotho", code: "+266"});
    this.countryList.push({name: "Liberia", code: "+231"});
    this.countryList.push({name: "Libya", code: "+218"});
    this.countryList.push({name: "Liechtenstein", code: "+423"});
    this.countryList.push({name: "Lithuania", code: "+370"});
    this.countryList.push({name: "Luxembourg", code: "+352"});


    this.countryList.push({name: "Macao", code: "+853"});
    this.countryList.push({name: "Macedonia", code: "+389"});
    this.countryList.push({name: "Madagascar", code: "+261"});
    this.countryList.push({name: "Malawi", code: "+265"});
    this.countryList.push({name: "Malaysia", code: "+60"});
    this.countryList.push({name: "Maldives", code: "+960"});
    this.countryList.push({name: "Mali", code: "+223"});
    this.countryList.push({name: "Malta", code: "+356"});
    this.countryList.push({name: "Marshall Islands", code: "+692"});
    this.countryList.push({name: "Martinique", code: "+596"});
    this.countryList.push({name: "Mauritania", code: "+222"});
    this.countryList.push({name: "Mauritius", code: "+230"});
    this.countryList.push({name: "Mexico", code: "+52"});
    this.countryList.push({name: "Moldova", code: "+373"});
    this.countryList.push({name: "Monaco", code: "+377"});
    this.countryList.push({name: "Mongolia", code: "+976"});
    this.countryList.push({name: "Montenegro", code: "+99382"});
    this.countryList.push({name: "Morocco", code: "+212"});
    this.countryList.push({name: "Mozambique", code: "+258"});
    this.countryList.push({name: "Myanmar", code: "+95"});


    this.countryList.push({name: "Namibia", code: "+264"});
    this.countryList.push({name: "Nauru", code: "+674"});
    this.countryList.push({name: "Nepal", code: "+977"});
    this.countryList.push({name: "Netherlands", code: "+31"});
    this.countryList.push({name: "Netherlands Antilles", code: "+599"});
    this.countryList.push({name: "New Zealand", code: "+64"});
    this.countryList.push({name: "Nicaragua", code: "+505"});
    this.countryList.push({name: "Nigeria", code: "+009234"});
    this.countryList.push({name: "Niue", code: "+683"});
    this.countryList.push({name: "Norfolk Island", code: "+672"});
    this.countryList.push({name: "Norway", code: "+47"});

    this.countryList.push({name: "Oman", code: "+968"});

    this.countryList.push({name: "Pakistan", code: "+92"});
    this.countryList.push({name: "Palau", code: "+680"});
    this.countryList.push({name: "Panama", code: "+507"});
    this.countryList.push({name: "Papua New Guinea", code: "+05675"});
    this.countryList.push({name: "Paraguay", code: "+002595"});
    this.countryList.push({name: "Peru", code: "+51"});
    this.countryList.push({name: "Philippines", code: "+63"});
    this.countryList.push({name: "Pitcairn", code: "+870"});
    this.countryList.push({name: "Poland", code: "+48"});
    this.countryList.push({name: "Portugal", code: "+351"});
    this.countryList.push({name: "Puerto Rico", code: "+0111"});

    this.countryList.push({name: "Qatar", code: "+974"});

    this.countryList.push({name: "Réunion and Mayotte", code: "+262"});
    this.countryList.push({name: "Romania", code: "+40"});
    this.countryList.push({name: "Russian Federation", code: "+8107"});
    this.countryList.push({name: "Rwanda", code: "+250"});


    this.countryList.push({name: "Saint Kitts and Nevis", code: "+1"});
    this.countryList.push({name: "Saint Lucia", code: "+1"});
    this.countryList.push({name: "Samoa", code: "+685"});
    this.countryList.push({name: "San Marino", code: "+378"});
    this.countryList.push({name: "São Tomé and Principe", code: "+239"});
    this.countryList.push({name: "Saudi Arabia", code: "+966"});
    this.countryList.push({name: "Senegal", code: "+221"});
    this.countryList.push({name: "Serbia", code: "+381"});
    this.countryList.push({name: "Seychelles", code: "+248"});
    this.countryList.push({name: "Sierra Leone", code: "+232"});
    this.countryList.push({name: "Singapore", code: "+65"});
    this.countryList.push({name: "Slovakia", code: "+421"});
    this.countryList.push({name: "Slovenia", code: "+386"});
    this.countryList.push({name: "Solomon Islands", code: "+677"});
    this.countryList.push({name: "Somalia", code: "+252"});
    this.countryList.push({name: "South Africa", code: "+27"});
    this.countryList.push({name: "Spain", code: "+34"});
    this.countryList.push({name: "Sri Lanka", code: "+94"});
    this.countryList.push({name: "Sudan", code: "+249"});
    this.countryList.push({name: "Suriname", code: "+597"});
    this.countryList.push({name: "Swaziland", code: "+268"});
    this.countryList.push({name: "Sweden", code: "+46"});
    this.countryList.push({name: "Switzerland", code: "+41"});
    this.countryList.push({name: "Syrian Arab Republic", code: "+963"});

    this.countryList.push({name: "Taiwan, Republic of China", code: "+886"});
    this.countryList.push({name: "Tajikistan", code: "+992"});
    this.countryList.push({name: "Tanzania", code: "+255"});
    this.countryList.push({name: "Thailand", code: "+66"});
    this.countryList.push({name: "Timor-Leste", code: "+670"});
    this.countryList.push({name: "Togo", code: "+228"});
    this.countryList.push({name: "Tonga", code: "+676"});
    this.countryList.push({name: "Trinidad and Tobago", code: "+1"});
    this.countryList.push({name: "Tunisia", code: "+216"});
    this.countryList.push({name: "Turkey", code: "+90"});
    this.countryList.push({name: "Turkmenistan", code: "+993"});
    this.countryList.push({name: "Tuvalu", code: "+688"});

    this.countryList.push({name: "Uganda", code: "+256"});
    this.countryList.push({name: "Ukraine", code: "+380"});
    this.countryList.push({name: "United Arab Emirates", code: "+971"});
    this.countryList.push({name: "United Kingdom", code: "+44"});
    this.countryList.push({name: "United States of America", code: "+1"});
    this.countryList.push({name: "Uruguay", code: "+598"});
    this.countryList.push({name: "Uzbekistan", code: "+998"});

    this.countryList.push({name: "Vanuatu", code: "+678"});
    this.countryList.push({name: "Venezuela", code: "+58"});
    this.countryList.push({name: "Vietnam", code: "+84"});
    this.countryList.push({name: "Virgin Islands, British", code: "+1"});
    this.countryList.push({name: "Virgin Islands, US", code: "+1"});

    this.countryList.push({name: "Wallis and Futuna Islands", code: "+681"});

    this.countryList.push({name: "Yemen", code: "+967"});

    this.countryList.push({name: "Zambia", code: "+260"});
    this.countryList.push({name: "Zimbabwe", code: "+263"});
  }

  getCountryCodeForSignup(country: string): string{
    this.getCountryList();
    let countryObject = this.countryList.find(s=> s.name == country);
    let output: string | any;
    output = countryObject?.code;

    return output;
  }

  getCountryCodeForCountry(country: string): string{

    this.getCountryList();
    let countryObject = this.countryList.find(s=> s.name == country);
    let output = countryObject?.code;
    if(output == undefined)
    {
      return "+91";
    }

    if(this.merchant?.countryCode == null || this.merchant?.countryCode == "")
    {
       this.merchant.countryCode = output;
    }
    return output;
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any>{
    let username = this.cookieService.get('username');
    let changePasswordReq: changePasswordReq ={
      currentPassword: oldPassword,
      newPassword: newPassword
    };

    let changePasswordEndPoint = environment.apiUrl + '/api/Merchants/' + username + "/password/change";

    return this.http.put(changePasswordEndPoint, changePasswordReq, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    });
  }


  addMerchantProperties(props: merchantProperties):Promise<any>
  {
    let username = this.cookieService.get('username');
    let addMerchantPropertiesEndPoint = environment.apiUrl + '/api/Merchants/properties';
    return this.http.post<any>(addMerchantPropertiesEndPoint, props, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise();
  }


  updateMerchantProperties(props: merchantProperties):Promise<any>
  {
    let username = this.cookieService.get('username');
    let editMerchantPropertiesEndPoint = environment.apiUrl + '/api/Merchants/properties';
    return this.http.put<any>(editMerchantPropertiesEndPoint, props, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise();
  }

  getMerchantProperties():Promise<any>
  {
    let username = this.cookieService.get('username');
    let getMerchantPropertiesEndPoint = environment.apiUrl + '/api/Merchants/properties/username/'+ username;
    return this.http.get<any>(getMerchantPropertiesEndPoint, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise();
  }
}
