<div class="page">
    <!-- main-sidebar -->
    <div class="app-sidebar__overlay z0" data-toggle="sidebar"></div>
    <app-sidebar></app-sidebar>
    <!-- main-sidebar -->
    <!-- main-content -->
    <div class="main-content app-content">
       <!-- main-header -->
       <app-header></app-header>
       <!-- /main-header -->
       <!-- container -->
       <div class="container-fluid topabsolute">
          <!-- breadcrumb -->
          <div class="breadcrumb-header justify-content-between">
             <div class="my-auto">
                <div class="d-flex">
                   <h4 class="content-title mb-0 my-auto">Home</h4>
                   <span class="text-muted mt-1 tx-13 ml-2 mb-0">/ Albums</span>
                </div>
             </div>
          </div>
          <!-- breadcrumb -->
          <div class="row row-sm">
       
            <div class="col-xl-10 col-lg-9 col-md-12">
             
                <div class="row row-sm">
                    <div class="col-md-6 col-lg-6 col-xl-4  col-sm-6" *ngFor="let prodItem of albums">
                        <div class="card" *ngIf="!prodItem.images">
                            <img class="card-img-top w-100 bg-danger-transparent"  src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5fff3d958d5dbc10304de1bb.png" alt="{{prodItem.name}}">
                            <div class="card-body">
                               
                                <a routerLink="/albums/edit/{{prodItem._id}}" title="Update {{prodItem.name}}">
                                    <h4 class="card-title">{{prodItem?.name}}</h4>
                                 </a>

                                 <a routerLink="/albums/edit/{{prodItem._id}}" title="Update {{prodItem.name}}">
                                    <span class="font-weight-bold text-danger">EDIT</span><i class="fe fe-arrow-right ml-1"></i>
                                </a>
                                
                            </div>
                        </div>
                        <div class="card" *ngIf="prodItem.images">
                           <div *ngFor="let items of prodItem?.images; let first=first" >
                              <img *ngIf="first" class="card-img-top w-100 bg-danger-transparent" src="{{items.image.small}}" alt="{{prodItem.name}}">
                           </div>
                           
                           <div class="card-body">
                              
                               <a routerLink="/albums/edit/{{prodItem._id}}" title="Update {{prodItem.name}}">
                                   <h4 class="card-title">{{prodItem?.name}}</h4>
                                </a>

                                <a routerLink="/albums/edit/{{prodItem._id}}" title="Update {{prodItem.name}}">
                                   <span class="font-weight-bold text-danger">EDIT</span><i class="fe fe-arrow-right ml-1"></i>
                               </a>
                               
                           </div>
                       </div>
                    </div>

                    
                    
              </div>

              
            </div>
        </div>

        
          <!-- row -->
          <!-- row closed -->
       </div>
       <!-- Container closed -->
    </div>
    <!-- main-content closed -->
    <!-- Footer opened -->
 
    <!-- Footer closed -->
 </div>