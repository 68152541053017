import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { addTestimonyRequest, updateTestimonyRequest } from '../../models/customer/testimonials.models';

@Injectable({
  providedIn: 'root'
})
export class TestimonyService {

  constructor(private toastr: ToastrService,private http: HttpClient, private cookieService: CookieService) {

   }

  getAllTestimonials(): Promise<any>{
    let username = this.cookieService.get("username");
    let getTestimonialsEndPoint = environment.apiUrl + "/api/Testimonys/"+ username;
    return this.http.get<any>(getTestimonialsEndPoint).toPromise();
  }

  addTestimonial(testimony: addTestimonyRequest): Promise<any>{
    let username = this.cookieService.get("username");
    let addTestimonialsEndPoint = environment.apiUrl + "/api/Testimonys";
    return this.http.post<any>(addTestimonialsEndPoint, testimony, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise();
  }

  updateTestimonial(testimony: updateTestimonyRequest, testId: string): Promise<any>{
    let username = this.cookieService.get("username");
    let updateTestimonialsEndPoint = environment.apiUrl + "/api/Testimonys/"+ testId;
    return this.http.put<any>(updateTestimonialsEndPoint, testimony, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise();
  }

  archiveTestimony(testId: string): Promise<any>
  {
    let archiveTestimonialsEndPoint = environment.apiUrl + "/api/Testimonys/"+ testId;
    return this.http.delete<any>(archiveTestimonialsEndPoint).toPromise();
  }

}
