<div class="page">
    <!-- main-sidebar -->
    <div class="app-sidebar__overlay z0" data-toggle="sidebar"></div>
    <app-sidebar></app-sidebar>
    <!-- main-sidebar -->
    <!-- main-content -->
    <div class="main-content app-content">
       <!-- main-header -->
       <app-header></app-header>
       <!-- /main-header -->
       <!-- container -->
       <div class="container-fluid topabsolute">
          <!-- breadcrumb -->
          <div class="breadcrumb-header justify-content-between">
             <div class="my-auto">
                <div class="d-flex">
                   <h4 class="content-title mb-0 my-auto">Home</h4>
                   <span class="text-muted mt-1 tx-13 ml-2 mb-0">/ Products</span>
                </div>
             </div>
          </div>
          <!-- breadcrumb -->
          <div class="row row-sm">
       
            <div class="col-xl-10 col-lg-9 col-md-12">
             
                <div class="row row-sm">
                    <div class="col-md-6 col-lg-6 col-xl-4  col-sm-6" *ngFor="let prodItem of productService?.products">
                        <div class="card custom-card">
                            <img class="card-img-top w-100 bg-danger-transparent" *ngIf="!prodItem.primaryImage?.small" src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/small/5fff3d958d5dbc10304de1bb.png" alt="{{prodItem.name}}">
                            <img class="card-img-top w-100 bg-danger-transparent" *ngIf="prodItem.primaryImage?.small" src="{{prodItem.primaryImage?.small}}" alt="{{prodItem.name}}">
                            <div class="card-body">
                                <h4 class="card-title">{{prodItem?.name}}</h4>
                                <p class="card-text text-danger" *ngIf="prodItem.price == 0">Ask for Price</p>
                                <p class="card-text text-danger" *ngIf="prodItem.price > 0">{{prodItem.currencyCode}} {{prodItem.price}} </p>
                                <p class="card-text" innerHtml="{{prodItem?.description | slice:0:60}}..."></p>
                                <a routerLink="/products/edit/{{prodItem._id}}" title="Update {{prodItem.name}}"><span class="font-weight-bold text-danger">EDIT</span><i class="fe fe-arrow-right ml-1"></i></a>
                            </div>
                        </div>
                    </div>
              </div>

                <div class="row">
                    <div class="col-md-3"> </div>
                   
                    <div class="col-md-3">

                        <ul class="pagination product-pagination ml-auto float-right">
                            <li class="page-item page-prev" style="float: left;">
                                <a class="page-link" title="Previous" routerLink ="{{productService?.prevLink}}">Prev</a>
                            </li>
                           
                            <li class="page-item page-next" style="float: right;">
                                <a class="page-link" title="Next" routerLink="{{productService?.nextLink}}">Next</a>
                            </li>
                        </ul>
                        
                    </div>
                    <div class="col-md-3"> </div>
                    <div class="col-md-3"> </div>
                    
                </div>
            </div>
        </div>
          <!-- row -->
          <!-- row closed -->
       </div>
       <!-- Container closed -->
    </div>
    <!-- main-content closed -->
    <!-- Footer opened -->
   
    <!-- Footer closed -->
 </div>




