import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { order } from '../../models/orders/order.models';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  list: order[] | any;
  totalCount: number | any;
  orderInfo: order | any;
  constructor(private http: HttpClient, private cookieService: CookieService) { }


  getOrders(skip: number, limit: number){
    let username = this.cookieService.get("username");
    if(username && username !== 'undefined')
    {
      const ordersListEndPoint =  environment.apiUrl + '/api/Orders/merchants/'+ username +"/list?skip="+skip+"&limit="+limit;
      this.http.get<order[]>(ordersListEndPoint).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((data : order[] |any) => {
        this.list = data;
      });
    }

  }

  getOrderInfo(index: string)
  {
    let username = this.cookieService.get("username");
    if(username && username !== 'undefined')
    {
      const orderDetailsEndPoint =  environment.apiUrl + '/api/orders/'+ index;
      this.http.get<order>(orderDetailsEndPoint).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((data : order |any) => {
        this.orderInfo = data;
      });
    }

    
  }


  getTotalOrders()
  {
    let username = this.cookieService.get("username");
    if(username && username !== 'undefined')
    {
      const totalOrdersEndPoint =  environment.apiUrl + '/api/Orders/merchants/'+ username +"/count";
      this.http.get<number>(totalOrdersEndPoint).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((data : number |any) => {
        this.totalCount = data;
      });
    }
  }
}
