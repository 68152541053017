<div class="page">
  <!-- main-sidebar -->
  <div class="app-sidebar__overlay z0" data-toggle="sidebar"></div>
  <app-sidebar></app-sidebar>
  <!-- main-sidebar -->

  <!-- main-content -->
  <div class="main-content app-content">
    <!-- main-header -->
    <app-header></app-header>
    <!-- /main-header -->

    <!-- container -->
    <div class="container-fluid topabsolute">
      <!-- breadcrumb -->
      <div class="breadcrumb-header justify-content-between">
        <div class="my-auto">
          <div class="d-flex">
            <h4 class="content-title mb-0 my-auto">Home</h4>
            <span class="text-muted mt-1 tx-13 ml-2 mb-0">/ Update Profile</span>
          </div>
        </div>
      </div>
      <!-- breadcrumb -->

      <div class="row">
        <div class="col-md-10">

          <div class="row row-sm">
            <div class="card">
              <div class="card-body">
                <div class="panel panel-primary tabs-style-2">
                  <div class="tab-menu-heading">
                    <div class="tabs-menu1">
                      <!-- Tabs -->
                      <ul class="nav panel-tabs main-nav-line">
                        <li>
                          <a style="background-color: rgba(1, 82, 168, 1); " href="#tab4" class="nav-link active"
                            data-toggle="tab">Basic Profile</a>
                        </li>
                        <li>
                          <a href="#tab3" class="nav-link" data-toggle="tab">About us</a>
                        </li>
                        <li>
                          <a href="#tab5" class="nav-link" data-toggle="tab">Business Hours</a>
                        </li>
                        <li>
                          <a href="#tab6" class="nav-link" data-toggle="tab">Address</a>
                        </li>
                        <li>
                          <a href="#tab7" class="nav-link" data-toggle="tab">Social Media</a>
                        </li>
                        <li>
                          <a href="#tab8" class="nav-link" data-toggle="tab">Background Images</a>
                        </li>
                        <li>
                          <a href="#tab9" class="nav-link" data-toggle="tab">Profile Images</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="panel-body tabs-menu-body main-content-body-right border">
                    <div class="tab-content">
                      <div class="tab-pane active" id="tab4">
                        <div class="card box-shadow-0">
                          <div class="card-header">
                            <h4 class="card-title mb-1">Profile Details</h4>
                          </div>
                          <div class="card-body pt-0">
                            <form class="form-horizontal" style="font-weight: 400" [formGroup]="profileDetailsForm"
                              (ngSubmit)="updateProfile()">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Business Name</label>
                                    <input formControlName="bizname" type="text" class="form-control"
                                      placeholder="Business Name" value="{{ merchantService?.merchant?.name }}" />
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Email address</label>
                                    <input formControlName="email" type="email" class="form-control" placeholder="Email"
                                      readonly value="{{ merchantService?.merchant?.email }}" />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Country Code</label>
                                    <input formControlName="countryCode" type="text" class="form-control"
                                      placeholder="Country code" value="{{
                                        merchantService?.merchant?.countryCode
                                      }}" />
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Mobile Number</label>
                                    <input formControlName="mobileNumber" type="string" class="form-control"
                                      placeholder="Mobile Number" value="{{
                                        merchantService?.merchant?.mobileNumber
                                      }}" />
                                  </div>
                                </div>
                              </div>

                              <br />

                              <div class="text-center mg-b-20" *ngIf="loaderEnabled">
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-4"></div>

                                <div class="col-md-4">
                                  <button style="width: 100%;   background-color: rgba(1, 82, 168, 1) !important;"
                                    type="submit" class="btn btn-primary">
                                    SUBMIT
                                  </button>
                                </div>

                                <div class="col-md-4"></div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                      <div class="tab-pane" id="tab3">
                        <div class="card box-shadow-0">
                          <div class="card-header">
                            <h4 class="card-title mb-1">Profile Description</h4>
                          </div>
                          <div class="card-body pt-0">
                            <form class="form-horizontal" style="font-weight: 400" [formGroup]="profileDescriptionForm"
                              (ngSubmit)="desciptionSubmitted()">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Short Bio</label>
                                    <textarea formControlName="shortBio" class="form-control" placeholder="Textarea"
                                      value="{{this.merchantService?.merchant?.shortBio}}" rows="6"></textarea>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Description</label>
                                    <app-wysiwyg *ngIf="this.merchantService?.merchant?.description"
                                      [configObj]="cofigObj" [uploadUrl]="apiPath" [value]="
                                        this.merchantService?.merchant?.description
                                      " [pressedSubmit]="submit" (getData)="updateDescription($event)"></app-wysiwyg>

                                    <app-wysiwyg *ngIf="!this.merchantService?.merchant?.description"
                                      [configObj]="cofigObj" [uploadUrl]="apiPath" [value]="
                                      this.merchantService?.merchant?.description
                                    " [pressedSubmit]="submit" (getData)="updateDescription($event)"></app-wysiwyg>
                                  </div>
                                </div>
                              </div>

                              <br />

                              <div class="text-center mg-b-20" *ngIf="loaderEnabled">
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-4"></div>

                                <div class="col-md-4">
                                  <button style="width: 100%" type="submit" class="btn btn-primary">
                                    SUBMIT
                                  </button>
                                </div>

                                <div class="col-md-4"></div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane" id="tab5">
                        <div class="card box-shadow-0">
                          <div class="card-header">
                            <h4 class="card-title mb-1">Business Hours</h4>
                          </div>
                          <div class="card-body pt-0">
                            <form class="form-horizontal" style="font-weight: 400" [formGroup]="bizHoursForm"
                              (ngSubmit)="updateBizHours()">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Sunday</label>
                                    <input formControlName="sunday" type="text" class="form-control"
                                      placeholder="Sunday" value="{{ merchantService?.merchant?.bizHours?.sunday }}" />
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Monday</label>
                                    <input formControlName="monday" type="text" class="form-control"
                                      placeholder="Monday" value="{{ merchantService?.merchant?.bizHours?.monday }}" />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Tuesday</label>
                                    <input formControlName="tuesday" type="text" class="form-control"
                                      placeholder="Tuesday" value="{{
                                        merchantService?.merchant?.bizHours?.tuesday
                                      }}" />
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Wednesday</label>
                                    <input formControlName="wednesday" type="string" class="form-control"
                                      placeholder="Wednesday" value="{{
                                        merchantService?.merchant?.bizHours?.wednesday
                                      }}" />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Thursday</label>
                                    <input formControlName="thursday" type="text" class="form-control"
                                      placeholder="Thursday" value="{{
                                        merchantService?.merchant?.bizHours?.thursday
                                      }}" />
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Friday</label>
                                    <input formControlName="friday" type="string" class="form-control"
                                      placeholder="Friday" value="{{
                                        merchantService?.merchant?.bizHours?.friday
                                      }}" />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Saturday</label>
                                    <input formControlName="saturday" type="text" class="form-control"
                                      placeholder="Saturday" value="{{
                                        merchantService?.merchant?.bizHours?.saturday
                                      }}" />
                                  </div>
                                </div>
                              </div>

                              <br />

                              <div class="text-center mg-b-20" *ngIf="loaderEnabled">
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-4"></div>

                                <div class="col-md-4">
                                  <button style="width: 100%" type="submit" class="btn btn-primary">
                                    SUBMIT
                                  </button>
                                </div>

                                <div class="col-md-4"></div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane" id="tab6">
                        <div class="card box-shadow-0">
                          <div class="card-header">
                            <h4 class="card-title mb-1">Business Address</h4>
                          </div>
                          <div class="card-body pt-0">
                            <form class="form-horizontal" style="font-weight: 400" [formGroup]="addressForm"
                              (ngSubmit)="updateAddress()">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Address</label>
                                    <input formControlName="address" type="text" class="form-control"
                                      placeholder="Address" value="{{ merchantService?.merchant?.address }}" />
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Locality</label>
                                    <input formControlName="locality" type="text" class="form-control"
                                      placeholder="Locality" value="{{
                                        merchantService?.merchant?.locality
                                      }}" />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">City</label>
                                    <input formControlName="city" type="text" class="form-control" placeholder="City"
                                      value="{{ merchantService?.merchant?.city }}" />
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Country</label>
                                    <input formControlName="country" type="text" class="form-control"
                                      placeholder="Country" value="{{ merchantService?.merchant?.country }}" />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Latitude</label>
                                    <input formControlName="lat" type="text" class="form-control" placeholder="Latitude"
                                      value="{{ merchantService?.merchant?.lat }}" />
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Longitude</label>
                                    <input formControlName="lng" type="string" class="form-control"
                                      placeholder="Longitude" value="{{ merchantService?.merchant?.lng }}" />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Pin/Zip Code</label>
                                    <input formControlName="pincode" type="text" class="form-control"
                                      placeholder="pincode" value="{{ merchantService?.merchant?.pincode }}" />
                                  </div>
                                </div>
                              </div>

                              <br />

                              <div class="text-center mg-b-20" *ngIf="loaderEnabled">
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-4"></div>

                                <div class="col-md-4">
                                  <button style="width: 100%" type="submit" class="btn btn-primary">
                                    SUBMIT
                                  </button>
                                </div>

                                <div class="col-md-4"></div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane" id="tab7">
                        <div class="card box-shadow-0">
                          <div class="card-header">
                            <h4 class="card-title mb-1">Social Media</h4>
                          </div>
                          <div class="card-body pt-0">
                            <form class="form-horizontal" style="font-weight: 400" [formGroup]="socialForm"
                              (ngSubmit)="updateSocialMedia()">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">FbUrl</label>
                                    <input formControlName="fbUrl" type="text" class="form-control" placeholder="FB Url"
                                      value="{{
                                        merchantService?.merchant?.socialLinks?.fbUrl
                                      }}" />
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">FB Page Url</label>
                                    <input formControlName="fbPageUrl" type="text" class="form-control"
                                      placeholder="FB Page Url" value="{{
                                        merchantService?.merchant?.socialLinks?.fbPageUrl
                                      }}" />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Twitter Url</label>
                                    <input formControlName="twitterUsername" type="text" class="form-control"
                                      placeholder="Twitter" value="{{
                                        merchantService?.merchant?.socialLinks?.twitterUsername
                                      }}" />
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Linked In</label>
                                    <input formControlName="linkedIn" type="text" class="form-control"
                                      placeholder="Linked In" value="{{
                                        merchantService?.merchant?.socialLinks?.linkedIn
                                      }}" />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Instagram</label>
                                    <input formControlName="instagram" type="text" class="form-control"
                                      placeholder="Instagram" value="{{
                                        merchantService?.merchant?.socialLinks?.instagram
                                      }}" />
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Pinterest</label>
                                    <input formControlName="pinterest" type="string" class="form-control"
                                      placeholder="Pinterest" value="{{
                                        merchantService?.merchant?.socialLinks?.pinterest
                                      }}" />
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">G Plus</label>
                                    <input formControlName="gplus" type="text" class="form-control" placeholder="G plus"
                                      value="{{
                                        merchantService?.merchant?.socialLinks?.gplus
                                      }}" />
                                  </div>
                                </div>
                              </div>

                              <br />

                              <div class="text-center mg-b-20" *ngIf="loaderEnabled">
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-4"></div>

                                <div class="col-md-4">
                                  <button style="width: 100%" type="submit" class="btn btn-primary">
                                    SUBMIT
                                  </button>
                                </div>

                                <div class="col-md-4"></div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane" id="tab8">
                        <div class="card box-shadow-0">
                          <div class="card-header">
                            <h4 class="card-title mb-1">Background Images</h4>
                          </div>
                          <div class="card-body pt-0">
                            <form class="form-horizontal" style="font-weight: 400" [formGroup]="socialForm"
                              (ngSubmit)="updateSocialMedia()">
                              <div class="row">
                                <div class="col-md-4" *ngFor="let imageItem of sliderImages">
                                  <div #imageholder *ngIf="
                                      imageItem.image && imageItem.image != '';
                                      else uploader
                                    " class="form-group">
                                    <img alt="Responsive image" class="img-thumbnail wd-100p wd-sm-200"
                                      src="{{ imageItem?.thumbImage }}" />

                                    <div class="text-center pt-3">
                                      <a (click)="archiveBackgroundImage(imageItem)" style="align-items: center;"><i
                                          class="fas fa-trash-alt"></i> Archive</a>
                                    </div>


                                  </div>
                                  <ng-template #uploader>
                                    <input type="file" (change)="backgroundImagechange($event)"
                                      class="dropify height-wrapper" accept=".png, .jpg, .jpeg"
                                      data-allowed-file-extensions="jpg png jpeg" /></ng-template>
                                </div>
                              </div>

                              <br />

                              <div class="text-center mg-b-20" *ngIf="loaderEnabled">
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-4"></div>

                                <div class="col-md-4">
                                  <button [disabled]="!this.loaderEnabled && this.backgroundImageString!==''"
                                    style="width: 100%" type="button" (click)="uploadBackGroundImagesHelper()"
                                    class="btn btn-primary">
                                    SUBMIT
                                  </button>
                                </div>

                                <div class="col-md-4"></div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>

                      <div class="tab-pane" id="tab9">
                        <div class="card box-shadow-0">
                          <div class="card-header">
                            <h4 class="card-title mb-1">Profile Images</h4>
                          </div>
                          <div class="card-body pt-0">
                            <form class="form-horizontal" style="font-weight: 400">
                              <div class="row">
                                <div class="col-md-4">
                                  <div #imageholder class="form-group">
                                    <img *ngIf="merchantService?.merchant?.logoImage?.small" alt="Responsive image"
                                      style="max-height: 170px;"
                                      src="{{  merchantService?.merchant?.logoImage?.small}}" />

                                    <img *ngIf="!merchantService?.merchant?.logoImage?.small" alt="Responsive image"
                                      class="img-thumbnail wd-100p wd-sm-200" style="max-height: 170px;"
                                      src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/5fff3d958d5dbc10304de1bb.png" />




                                  </div>
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Upload Logo</label>
                                    <input type="file" (change)="logoChange($event)" class="dropify2 height-wrapper"
                                      accept=".png, .jpg, .jpeg" data-allowed-file-extensions="jpg png jpeg" />
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div #imageholder class="form-group">
                                    <img *ngIf="merchantService?.merchant?.favImage?.small" alt="Responsive image"
                                      class="img-thumbnail wd-100p wd-sm-200" style="max-height: 170px;"
                                      src="{{  merchantService?.merchant?.favImage?.small}}" />

                                    <img *ngIf="!merchantService?.merchant?.favImage?.small" alt="Responsive image"
                                      style="max-height: 170px;" class="img-thumbnail wd-100p wd-sm-200"
                                      src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/5fff3d958d5dbc10304de1bb.png" />


                                  </div>
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Upload FavIcon</label>
                                    <label for="exampleInputEmail1"></label>
                                    <input type="file" (change)="favIcon($event)" class="dropify3 height-wrapper"
                                      accept=".png, .jpg, .jpeg" data-allowed-file-extensions="jpg png jpeg" />
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div #imageholder class="form-group">
                                    <img *ngIf="merchantService?.merchant?.featuredImage?.small" alt="Responsive image"
                                      class="img-thumbnail wd-100p wd-sm-200" style="max-height: 170px;"
                                      src="{{  merchantService?.merchant?.featuredImage?.small}}" />

                                    <img *ngIf="!merchantService?.merchant?.featuredImage?.small" alt="Responsive image"
                                      style="max-height: 170px;" class="img-thumbnail wd-100p wd-sm-200"
                                      src="https://s3-ap-southeast-1.amazonaws.com/content-ewns/images/productimages/5fff3d958d5dbc10304de1bb.png" />


                                  </div>
                                  <div class="form-group">
                                    <label for="exampleInputEmail1">Featured Image</label>
                                    <label for="exampleInputEmail1"></label>
                                    <input type="file" (change)="featuredImageChange($event)"
                                      class="dropify3 height-wrapper" accept=".png, .jpg, .jpeg"
                                      data-allowed-file-extensions="jpg png jpeg" />
                                  </div>
                                </div>
                              </div>

                              <br />

                              <div class="text-center mg-b-20" *ngIf="loaderEnabled">
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </div>


                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>

      </div>


      <!-- row -->

      <!-- row closed -->
    </div>
    <!-- Container closed -->
  </div>
  <!-- main-content closed -->

</div>