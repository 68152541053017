<div class="page">
    <!-- main-sidebar -->
    <div class="app-sidebar__overlay z0" data-toggle="sidebar"></div>
    <app-sidebar></app-sidebar>
    <!-- main-sidebar -->
    <!-- main-content -->
    <div class="main-content app-content">
      <!-- main-header -->
      <app-header></app-header>
      <!-- /main-header -->
      <!-- container -->
      <div class="container-fluid topabsolute">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
          <div class="my-auto">
            <div class="d-flex">
              <h4 class="content-title mb-0 my-auto">Settings</h4>
              <span class="text-muted mt-1 tx-13 ml-2 mb-0">/ Change Password</span>
            </div>
          </div>
        </div>
        <!-- breadcrumb -->
        <div class="row row-sm">
           
            <div class="col-md-8">
                <div class="card  box-shadow-0 ">
                    <div class="card-header">
                        <h4 class="card-title mb-1">Change Password</h4> </div>
                    <div class="card-body pt-0">
                        <form [formGroup]="passwdChangeForm" (ngSubmit)="changePassword()" (keyup.enter)="changePassword()">
                            <div class="">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Current Password</label>
                                    <input formControlName="oldPassword" type="password" class="form-control" placeholder="Enter Current Password">
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">New Password</label>
                                    <input formControlName="password" type="password" class="form-control" placeholder="New Password">
                                </div>
                                <div class="form-group">
                                    <label for="exampleInputPassword1">Confirm Password</label>
                                    <input formControlName="confirmPassword" type="password" class="form-control" placeholder="Confirm Password">
                                </div>
                            </div>
                            <button [disabled]="!buttonEnabled" type="submit" class="btn btn-primary mt-3 mb-0">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <!-- main-content closed -->
      <!-- Footer opened -->
    
      <!-- Footer closed -->
    </div>
  
    <app-footer></app-footer>
  </div>

