<div id="global-loader" *ngIf="loaderEnabled">
  <img src="https://ewnsassets.s3.ap-south-1.amazonaws.com/cms/assets/img/loader.svg" class="loader-img" alt="Loader">
</div>
<div class="page">
    <!-- main-sidebar -->
    <div class="app-sidebar__overlay" data-toggle="sidebar"></div>
    <app-sidebar></app-sidebar>
    <!-- main-sidebar -->
    <!-- main-content -->
    <div class="main-content app-content">
      <!-- main-header -->
      <app-header></app-header>
      <!-- /main-header -->
      <!-- container -->
      <div class="container-fluid topabsolute">
        <!-- breadcrumb -->
        <div class="breadcrumb-header justify-content-between">
          <div class="my-auto">
            <div class="d-flex">
              <h4 class="content-title mb-0 my-auto">Profile</h4>
              <span class="text-muted mt-1 tx-13 ml-2 mb-0">/ Customization</span>
            </div>
          </div>
        </div>
        <!-- breadcrumb -->
        <div class="row row-sm">
           
            <div class="col-md-8">
                <div class="card  box-shadow-0 ">
                    <div class="card-header">
                        <h4 class="card-title mb-1">Customization</h4> </div>
                    <div class="card-body pt-0">
                      <div class="">
                        <div class="form-group">
                            <label>Primary Color</label>
                            <input  [(ngModel)]="primaryColor" type="text" class="form-control" placeholder="#fff" value="{{this.merchantProperties?.primaryColor}}">
                        </div>
                        <div class="form-group">
                            <label>Secondary color</label>
                            <input [(ngModel)]="secondaryColor"  type="text" class="form-control" placeholder="#ffffff" value="{{merchantProperties?.secondaryColor}}">
                        </div>

                       
                    </div>
                    <button [disabled]="!buttonEnabled"  type="submit" class="btn btn-primary mt-3 mb-0" (click)="changeColor()">Submit</button>

                     
                    </div>

                    

                </div>
            </div>
        </div>
      </div>
      <!-- main-content closed -->
      <!-- Footer opened -->
    
      <!-- Footer closed -->
    </div>
  
  </div>

 

