import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { JWTModel } from '../../models/jwt/jwt.models';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient) { }
  
  login(): Observable<JWTModel> {
    const connectUrl = environment.identityServerUrl + '/connect/token';
    const body = new HttpParams()
      .set('grant_type', 'client_credentials')
      .set('scope', 'api.read')
      .set('client_id', 'ewnsthemes')
      .set('client_secret', 'Laxmidevi@99');
    return this.http.post<JWTModel>(connectUrl, body.toString(), {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    });
  }

  loginSync(): Promise<JWTModel> {
    const connectUrl = environment.identityServerUrl + '/connect/token';
    const body = new HttpParams()
      .set('grant_type', 'client_credentials')
      .set('scope', 'api.read')
      .set('client_id', 'ewnsthemes')
      .set('client_secret', 'Laxmidevi@99');
    return this.http.post<JWTModel>(connectUrl, body.toString(), {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    }).toPromise();
  }
}
