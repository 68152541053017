import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { category } from 'src/app/core/models/category/category.models';
import { product } from 'src/app/core/models/product/product.models';
import { CategoryService } from 'src/app/core/services/categories/category.service';
import { MerchantService } from 'src/app/core/services/merchant/merchant.service';
import { ProductService } from 'src/app/core/services/products/product.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css']
})
export class EditProductComponent implements OnInit {

  public allCategories: category[] = [];
  public categories: category[] = [];
  public description = "";
  public keywords = "";
  public category = "";

  public price: number | any = 0;
  public discount: number | any = 0;
  public quantity: number | any = 0;
  public bookOnline = "";
  public warranty = "";
  public shortBio = "";
  public brand = "";

  public uploadProductImage = environment.apiUrl + "/api/Products/image/";
  public productImageUrl = "";
  public defaultCatId = "";
  serviceItem: product | any;

  public loaderEnabled = false;

  public selectedParentCatData = undefined;
  public selectedChildCatData = undefined;
  public submit = false;
  public apiPath = environment.apiUrl + "/api/Images/custom/";
  public cofigObj = {
    "uploader": {
      "insertImageAsBase64URI": true
    },
    "minHeight": 400,
    "minWidth": 400
  }

  constructor(private titleService: Title,
    private router: Router,
    private merchantService: MerchantService,
    private productService: ProductService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private categoryService: CategoryService,
    private cookieService: CookieService,
    private http: HttpClient) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  async ngOnInit(): Promise<void> {

    const windowObj = window as any;
    windowObj.$('.dropify').dropify({});


    let username = this.cookieService.get("username");
    if (username == "") {
      this.router.navigateByUrl("/");
    }
    if (!this.merchantService.getMerchantData()) {
      this.merchantService.getMechangeDetails();
    }


    const routeParams = this.route.snapshot.paramMap;
    let id = String(routeParams.get('id'));


    this.serviceItem = await this.productService.getProductInfoByIdPromise(id);
    this.titleService.setTitle(this.serviceItem?.name);

    const tempCats = await this.categoryService.getCategoriesObservable();
    this.categories = [...tempCats];
    this.defaultCatId = this.serviceItem.categoryId;

    this.allCategories = await this.categoryService.getBusinessCategories();

  }

  updateDescription(wysiwygdata: string) {

    this.description = wysiwygdata;

    if (this.description == null || this.description == undefined || this.description == "") {
      this.description = this.serviceItem?.description;
    }

    if (this.description == null || this.description == undefined || this.description == "") {
      this.toastr.error("Description cannot be empty");
      this.loaderEnabled = false;
    }
    else {

      let keywordArray: string[] = [];
      if (this.keywords != null) {
        keywordArray = this.keywords.split(",");
      }

      let categoryString = this.serviceItem?.category;
      let categoryId = this.serviceItem?.categoryId;

      if (this.allCategories != null && this.category != "") {
        let catItem = this.allCategories.find(s => s._id == this.category);
        categoryString = catItem?.name;
        categoryId = catItem?._id;
      }


      let serviceReq = this.serviceItem;
      serviceReq.description = this.description;
      serviceReq.category = categoryString;
      serviceReq.categoryId = categoryId;
      serviceReq.warranty = this.warranty;
      serviceReq.price = Number(this.price);
      serviceReq.discount = Number(this.discount);
      serviceReq.shortBio = this.shortBio;
      serviceReq.brand = this.brand;
      serviceReq.buyOnlineLink = this.bookOnline;
      serviceReq.keywords = keywordArray;
      serviceReq.stock = Number(this.quantity);


      this.productService.updateProduct(this.serviceItem?._id, serviceReq).pipe(
        take(1),
        catchError(err => {
          return of({ err: true, message: err.error.error_description })
        })
      ).subscribe((d: any) => {
        if (d == null) {
          this.toastr.success("Successfully updated the Product");
        }
        else if (d.err) {
          this.toastr.error("Unable to update the Product, please try again")
        }

        this.loaderEnabled = false;
      });

    }


  }

  updateProduct() {
    this.loaderEnabled = true;
    this.submit = true;
  }

  updateParentCatData(data: any) {
    this.selectedParentCatData = data;
  }

  updateChildCatData(data: any): void {
    debugger
     this.selectedChildCatData = data;
  }
  archiveImage(item: any) {
    this.loaderEnabled = true;
    this.productService.archiveProductImage(this.serviceItem?._id, item.actual).pipe(
      take(1),
      catchError(err => {
        return of({ err: true, message: err.error.error_description })
      })
    ).subscribe((d: any) => {
      if (d == null) {
        this.router.navigateByUrl("/products/view/1");
      }
      else if (d.err) {
        this.toastr.error("Unable to archive the image, please try again")
      }

      this.loaderEnabled = false;
    });


  }

  archiveProduct() {

    this.loaderEnabled = true;
    this.productService.archiveProduct(this.serviceItem?._id).pipe(
      take(1),
      catchError(err => {
        return of({ err: true, message: err.error.error_description })
      })
    ).subscribe((d: any) => {
      if (d == null) {
        this.router.navigateByUrl("/products/view/1");
      }
      else if (d.err) {
        this.toastr.error("Unable to archive the Product, please try again")
      }

      this.loaderEnabled = false;
    });
  }


  productImageChange(data: any): void {
    let file = data.target.files[0];
    let reader = new FileReader();
    const me = this;
    this.loaderEnabled = true;
    reader.readAsDataURL(file);
    reader.onload = async function () {
      const imagedata = reader.result?.toString().split(',')[1];
      try {
        me.toastr.info("Please wait till image is uploaded");
        let imageUrl = await me.uploadProductImages(imagedata);
        me.toastr.success("Image uploaded");
        me.productImageUrl = imageUrl;
        me.loaderEnabled = false;
      } catch (error) {
        me.toastr.error("something went wrong");
      }
    };
    reader.onerror = function (error) {
      me.toastr.error("something went wrong " + error);
      console.log('Error: ', error);
    };
  }


  uploadProductImages(data: any): Promise<any> {
    const headers = new HttpHeaders().set("Content-Length", data.length.toString())
    let usernameDetails = this.cookieService.get("username");

    return this.http.put(this.uploadProductImage + this.serviceItem?._id,
      { "image": data },
      { headers, responseType: 'text' }).toPromise();

  }

}
