import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { category } from 'src/app/core/models/category/category.models';

@Component({
  selector: 'app-category-helper',
  templateUrl: './category-helper.component.html',
  styleUrls: ['./category-helper.component.css']
})
export class CategoryHelperComponent implements OnInit, OnChanges {

  @Input()
  categories: any;
  @Input()
  defaultSelected: string = '';

  @Input()
  parentName: number | undefined;
  @Input()
  childName: number | undefined;

  @Output()
  selectedParentData = new EventEmitter();

  @Output()
  selectChildCatData = new EventEmitter();

  keyword = 'name';
  dataForCats: category[] = [];
  dataForSubCats: category[] = [];
  selectedCat: any;
  selectedSubCat: any;
  newName = "";
  disabledSubCats = true;
  selectSubCatText = "Please select category first";


  @ViewChild('subcats') subcatselm: any;


  selectChildEvent(item: any) {
    this.selectChildCatData.emit(item);
    this.selectedSubCat = item.name;
  }

  clearChildData(): void { 
    this.selectChildCatData.emit("");
  }
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    // debugger;
    if (changes.categories.currentValue) {
      this.dataForCats = [...changes.categories.currentValue];

    }
    if (changes.defaultSelected.currentValue && this.dataForCats.length > 0) {
      this.defaultSelected = changes.defaultSelected.currentValue;
      this.checkandUpdateDefaults();
    }

  }

  checkandUpdateDefaults(): void {
    for (let index = 0; index < this.dataForCats.length; index++) {
      const element = this.dataForCats[index];
      if (element._id === this.defaultSelected) {
        this.selectedCat = element;
        this.selectedParentData.emit(element)
        break;
      }
      for (let j = 0; j < element.childCategories.length; j++) {
        const childElm = element.childCategories[j];
        if (childElm._id === this.defaultSelected) {
          this.selectedCat = element;
          this.selectedSubCat = childElm;
          this.selectedParentData.emit(element);
          this.selectChildCatData.emit(childElm);
          this.disabledSubCats = false;
          break;
        }

      }

    }
  }
  ngOnInit(): void {
    this.dataForCats = [...this.categories];
  }
  getSubcategories(key: string): Array<category> {
    let item = this.dataForCats.find(s => s._id == key)?.childCategories;
    this.dataForSubCats = item;
    return item;
  }



  onSelectParentCategory(evt: any): void {
    this.selectedParentData.emit(evt);
    this.selectSubCatText = "Please Select SubCategory";
    this.disabledSubCats = false;
    let item = this.dataForCats.find(s => s._id == evt._id)?.childCategories;
    this.dataForSubCats = item;
    this.getSubcategories(evt._id);
  }

  onClearParent(): void {
    this.selectedParentData.emit("");
    this.selectChildCatData.emit("");

    this.subcatselm.close();
    this.subcatselm.clear();
    this.selectSubCatText = "Please select category first";
    this.disabledSubCats = true;
    this.dataForSubCats = new Array();
  }

}
